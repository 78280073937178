import React, { useEffect } from "react";
import styles from './Configurator.module.scss';

import { ReactComponent as CornerLT } from "../../../assets/svgs/corner_lt.svg";
import { ReactComponent as CornerLB } from "../../../assets/svgs/corner_lb.svg";
import { ReactComponent as CornerRT } from "../../../assets/svgs/corner_rt.svg";
import { ReactComponent as CornerRB } from "../../../assets/svgs/corner_rb.svg";

import classNames from "classnames/bind";
import { useDispatch, useSelector } from "react-redux";
import Step from "./Step/Step";
import { fetchConfigurator } from "../../../util/helpers";
import { configuratorActions } from "../../../stores/reducers/configurator";
import { filterActions } from "../../../stores/reducers/filter";
const classes = classNames.bind( styles );

const Configurator = ( props ) => {
  const dispatch = useDispatch();
  const isFinished = useSelector( state => state.configurator.finished );
  const steps = useSelector( state => state.configurator.steps );
  const index = useSelector( state => state.configurator.index );

  useEffect( () => {
    dispatch( configuratorActions.reset() );
    dispatch( filterActions.reset() );

    if(steps.length < 2) {
      fetchConfigurator().then( data => {
        if( typeof data.steps !== 'undefined' ) {
          dispatch( configuratorActions.addSteps( data.steps ) );
        }
      } ).catch( error => console.log( error ) );
    }
  }, [ dispatch, steps.length ] );

  const configurator = useSelector( state => state.configurator );
  const current = configurator.currentStep;
  // debugger;
  const onClickBackButtonHandler = () => {
    dispatch( configuratorActions.moveBack() );
  };

  return (
    <section className={classes( {
      'configurator': true,
      'finished': isFinished
    } )}>
      <React.Fragment>
        <CornerLT className={`${styles.corner} ${styles.cornerLT}`} />
        <CornerLB className={`${styles.corner} ${styles.cornerLB}`} />
        <CornerRT className={`${styles.corner} ${styles.cornerRT}`} />
        <CornerRB className={`${styles.corner} ${styles.cornerRB}`} />

        <span style={{
          display: index > 0 ? 'inline' : 'none'
        }} onClick={onClickBackButtonHandler} className={styles.back}>zurück</span>

        <div>
          <Step id={current.id} number={configurator.index} />
        </div>
      </React.Fragment>
    </section>
  );
};
export default Configurator;