import React from "react";
import classNames from "classnames/bind";
import Button from "../../../UI/Button";

import styles from './ResultItem.module.scss';
import { useSelector } from "react-redux";

const classes = classNames.bind( styles );

const ResultItem = ( { id, title, link, attributes } ) => {
  const allAttributes = useSelector( state => state.programmes.attributes );
  const programs = useSelector(state => state.programmes.entries);
  const program = programs.filter( _program => { return _program.id === id } )[0];

  const onCLickHandler = () => {
    window.location = link;
  };

  return (
    <div className={styles.item}>
      <div className={styles.title} dangerouslySetInnerHTML={{__html: title}}></div>
      <div className={classes({
        'university': true,
        'multiple': program.partner && program.partner.length > 1
      })}>
        {
          !program.partner ? '' : program.partner.map((partner, index) => {
            return <div key={index}>{partner.post_title}</div>;
          })
        }
      </div>
      <div className={classes({
        'location': true,
        'multiple': program.partner && program.partner.length > 1
      })}>
        {
          !program.partner ? '' : program.partner.map((partner, index) => {
            return <div key={index}>{partner.city[0] ? partner.city[0]['name'] : ''}</div>;
          })
        }
      </div>
      <div className={classes({
        'degree': true,
        'multiple': program.partner && program.partner.length > 1
      })}>
        {
          !program.partner ? '' : program.partner.map((partner, index) => {
            return <div key={index}>{partner.degree[0] ? partner.degree[0]['name'] : ''}</div>;
          })
        }
      </div>
      <div className={styles.buttonContainer}>
        <Button className={styles.button} onClick={onCLickHandler} align={'right'} text="Studium Info" />
      </div>
    </div>
  );
};
export default ResultItem;