import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../Answers/Buttons";
import classNames from "classnames/bind";

import styles from './Step.module.scss';
import { configuratorActions } from "../../../../stores/reducers/configurator";
import Select from "../Answers/Select";
import Search from "../Answers/Search";
import {filterActions} from "../../../../stores/reducers/filter";
import {programmesActions} from "../../../../stores/reducers/programmes";
const classes = classNames.bind( styles );

const Step = ( props ) => {
  const [ isValid, setValid ] = useState( false ); // Used to check if an answer has been selected
  const [ isLoading, setIsLoading ] = useState( false );
  const [ nextStep, setNextStep ] = useState( false );
  const [answersList, setAnswersList] = useState([]);
  const steps = useSelector( state => state.configurator.steps );
  const dispatch = useDispatch();
  const step = steps.filter( el => el.id === props.id )[0];
  const allAnswers = useSelector( state => state.configurator.answers );
  const options = useSelector( state => state.theme.options );
  const attributes = useSelector( state => state.filter.attributes );
  const programmes = useSelector( state => state.programmes.entries );
  const allSelection  = useSelector( state => state.filter.selection );

  useEffect( () => {
    let newAnswers = [];
    let selection = {...allSelection};

    // Ignore selection previously set
    if( step.taxonomyName !== 'PLAIN' && selection[step.taxonomyName] ) {
      let foundTaxonomy = false;
      Object.keys( selection ).forEach( key => {
        if( key === step.taxonomyName ) {
          foundTaxonomy = true;
        }

        if( foundTaxonomy ) {
          delete selection[key];
        }
      } );
    }

    if( typeof selection !== 'undefined' && ( Object.keys( selection ).length > 0 ) && ( step.taxonomyName !== 'PLAIN' && typeof selection[step.taxonomyName] === 'undefined' ) ) { // && selection[step.taxonomyName]

      let filteredProgrammes = programmes.filter( program => {
        return Object.entries( selection ).every( ( [ tax, terms ] ) => {

          return terms.includes( false ) || typeof program.attributes[tax] !== 'undefined' && terms.some( term => program.attributes[tax].includes( term ) );
        } );
      } );
      filteredProgrammes.forEach( program => {
        step.answers.forEach( ans => {
          Object.entries( program.attributes ).forEach( ( [taxonomy, termIDs] ) => {
            if( taxonomy === step.taxonomyName && termIDs.includes( ans.id ) && !newAnswers.includes( ans ) ) {
              newAnswers.push( ans );
            }
          } );
          if( ans.id === `plain_${step.id}` && !newAnswers.includes( ans ) ) {
            newAnswers.push( ans );
          }
        } );
      } );
    } else {
      newAnswers = step.answers;
    }
    setAnswersList(newAnswers);
  }, [step, allSelection] );

  const setValidHandler = ( valid ) => {
    setValid( valid );
  };

  const setSelectionHandler = ( selection ) => {
    dispatch( configuratorActions.addAnswer( selection ) );

    setIsLoading( true );

    setTimeout( () => {
      setIsLoading( false );

      if( step.taxonomyName !== 'PLAIN' ) {
        dispatch( filterActions.addSelection( {
          taxonomy: step.taxonomyName,
          term: selection.selection[step.taxonomyName],
          source: 'configurator'
        } ) );

        if( typeof allSelection !== 'undefined' && ( Object.keys( allSelection ).length > 0 ) ) {
          let filteredProgrammes = programmes.filter( program => {
            return Object.entries( allSelection ).every( ( [ tax, terms ] ) => {
              return typeof program.attributes[tax] !== 'undefined' && terms.some( term => program.attributes[tax].includes( term ) );
            } );
          } );
          dispatch( programmesActions.filter( filteredProgrammes ) );
        }
      } else if( selection.type === "search" ) {
        let search = selection.selection[0].trim();
        dispatch(filterActions.filterBySearch( search ));
      }

      document.activeElement.blur();
      nextStepHandler( selection.next );
    }, 1000 );
  }

  const nextStepHandler = ( next ) => {
    // debugger;
    if( isValid || !isValid ) {
      dispatch( configuratorActions.nextStep() );
      setValid( false );

      const isLast = !step.defaultNext && (!next || next === 'false');

      if(isLast) {
        let search = '';
        let selection = allAnswers.map( answer => {

          if(answer.type === "search") {
            search = answer.selection[0].trim();
          }

          let entry = {};
          entry[Object.keys( answer.selection )[0]] = Object.values( answer.selection )
          return entry;
        } );

        let newSelection = {};

        selection.forEach( ( entry, index ) => {
          const entryData = Object.entries( entry );
          if(attributes[entryData[0][0]]) {
            newSelection[entryData[0][0]] = entryData[0][1];
          }
        } );

        dispatch( programmesActions.filterBySearch( search ) );

        setTimeout(() => {
          window.location = options.filter_page;
        }, 500)
      }
    }
  };


  let answers = '';

  if( typeof step !== 'undefined' ) {
    const selectedAnswer = allAnswers.filter( ans => ans.id === step.id )[0];

    if( step.search ) {
      answers = <Search
        id={step.id}
        setValid={setValidHandler}
        setSelectionHandler={setSelectionHandler}
        defaultNext={step.defaultNext}
        value={typeof selectedAnswer !== 'undefined' ? selectedAnswer.selection[0] : false} />
    } else if( step.input === 'button' ) {
      answers = <Buttons
        id={step.id}
        options={ answersList }
        setValid={setValidHandler}
        setSelectionHandler={setSelectionHandler}
        defaultNext={step.defaultNext}
        taxonomy={ step.taxonomyName }
        value={typeof selectedAnswer !== 'undefined' ? ( selectedAnswer.selection[step.taxonomyName] === false ? `plain_${props.id}` : selectedAnswer.selection[step.taxonomyName] ) : false} />;
    } else if( step.input === 'select' ) {
      answers = <Select
        id={step.id}
        options={ answersList }
        setValid={setValidHandler}
        setSelectionHandler={setSelectionHandler}
        defaultNext={step.defaultNext}
        taxonomy={ step.taxonomyName }
        value={typeof selectedAnswer !== 'undefined' ? ( selectedAnswer.selection[step.taxonomyName] === false ? `plain_${props.id}` : selectedAnswer.selection[step.taxonomyName] ) : false} />
    }
  }

  return (
    <React.Fragment>
      { ( typeof step !== 'undefined' ) ? (
        <div className={classes({
          'blur': true,
          'active': isLoading
        })}>
          <div className={styles.title}>
            <strong>{`${props.number + 1}. `}</strong>
            {step.title}
          </div>

          <div className={styles.answers}>
            { answers }
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};
export default Step;