import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

// Import CSS/SCSS
import './sass/settings/_base.css';
import './sass/base/_normalize.css'
import './sass/base/_elements.css'
import './sass/typography/_typography.css'
import './sass/components/_components.css'

// Import Redux
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./stores/store";

let persistor = persistStore( store );
// persistor.purge();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
