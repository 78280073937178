import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide} from "swiper/react";
import { Autoplay } from "swiper";
import { useSelector } from "react-redux";

import 'swiper/css';
import './Partners.scss';

const Partners = ( props ) => {
  const firstSlide = useRef();
  const [mainPartnerWidth, setMainPartnerWidth] = useState( 0 );
  const partners = useSelector( state => state.theme.options.footer.partners );
  const partnerMain = useSelector( state => state.theme.options.footer.main_partner );

  useEffect( () => {
    const observer = new ResizeObserver( entries => {
      setMainPartnerWidth( entries[0].contentRect.width );
    } );
    if( typeof firstSlide !== 'undefined' ) {
      observer.observe( firstSlide.current );
    }
    return () => firstSlide.current && observer.unobserve( firstSlide.current );
  }, [firstSlide] );

  return (
    <div className="partners__container">
      { typeof partnerMain.logo !== 'undefined' && partnerMain.logo[0] ? (
        <a style={{
          width: '100px'
        }} className="partners__main" href={partnerMain.link ? partnerMain.link : '#'}>
          <img alt="Main Partner Logo" src={partnerMain.logo[0]} />
          <div className="partners__text">in Kooperation mit:</div>
        </a>
      ) : ('') }
      <Swiper
        className="partners"
        slidesPerView={9}
        spaceBetween={40}
        autoplay={ {
          delay: 3000
        } }
        modules={[Autoplay]}
        breakpoints={ {
          1024: {
            slidesPerView: 9,
            spaceBetween: 40
          },
          767: {
            slidesPerView: 5,
            spaceBetween: 20
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 40
          }
        } }>
        { partners.map( (partner, index) => {
          return (
            <SwiperSlide className="partner__slide" key={index}>
              <div className="partner__inner" ref={index === 0 ? firstSlide : null}>
                {
                  partner.link !== '' ? (
                    <a href={partner.link} target={'_blank'} rel={'noreferrer'}>
                      <img src={partner.logo[0]} alt={`partner_${index}`} />
                    </a>
                  ) : (
                    <img src={partner.logo[0]} alt={`partner_${index}`} />
                  )
                }
              </div>
            </SwiperSlide>
          )
        } ) }
      </Swiper>
    </div>
  );
};
export default Partners;