import React from "react";
import classNames from "classnames/bind";

import styles from './Button.module.scss';
const classes = classNames.bind( styles );

const Button = ( { text, onClick, align = 'center', icon = false, className = false } ) => {
  return (
    <button className={ `${classes( {
      'button': true,
      [`button--${align}`]: true,
      'hasIcon': icon
    } )} ${className}`} onClick={onClick}>
      {text}
      { icon ? <div className={styles.icon}>{icon}</div> : '' }
    </button>
  );
};
export default Button;