import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from './Buttons.module.scss';

const classes = classNames.bind( styles );

const Buttons = ( { id, options, setValid, setSelectionHandler, defaultNext, taxonomy, value } ) => {

  useEffect( () => {
    if( value ) {
      setValid( true );
    }
  }, [value, setValid] );

  const onChangeHandler = ( e ) => {
    // debugger;
    setValid( true );

    let selection = {};
    if( isNaN( e.target.value ) ) {
      selection[taxonomy] = e.target.value;
    } else {
      selection[taxonomy] = parseInt( e.target.value );
    }

    if( e.target.value.includes( 'plain_' ) ) {
      selection[taxonomy] = false;
    }

    setSelectionHandler( {
      id: id,
      type: 'button',
      selection: selection,
      next: e.target.dataset.nextStep,
    } );
  };

  return (
    <div className={ classes( {
      'buttons': true,
      'justify': options.length > 1
    } ) }>
      { options.map( ( option, index ) => {

        // TODO: filter the options by the given answers and do the same for other answer types
        // const count = programs.filter( program => {
        //   // const inAnswer = answers.some( answer => {
        //   //   return answer.selection[taxonomy] && answer.selection[taxonomy] === option.id ;
        //   // });
        //   return program.attributes[taxonomy] && program.attributes[taxonomy].includes( option.id );
        // } ).length;

        return (
          <div key={`${id}_${index}`} className={styles.button}>
            <input
              onChange={onChangeHandler}
              onClick={onChangeHandler}
              // onFocus={(e) => e.target.value = -1}
              className={styles.input}
              checked={value && value === option.id}
              type="radio"
              id={`step_${id}_${option.id}`}
              value={option.id}
              data-next-step={option.nextStep ? option.nextStep : defaultNext}
              name={`step_${id}`} />
            <label className={styles.label} htmlFor={`step_${id}_${option.id}`}>{option.title}</label>
          </div>
        );
      } ) }
    </div>
  );
};
export default Buttons;