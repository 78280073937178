import React from "react";
import classNames from "classnames/bind";
import { useSelector } from "react-redux";

import styles from './Term.module.scss';
import { isMobile, scrollToElement } from "../../../../util/helpers";
const classes = classNames.bind( styles );

const Term = ( { id, name, taxonomy, onRemoveTermHandler, onSelectTermHandler } ) => {
  const filter = useSelector( state => state.filter.selection );
  const programmes = useSelector( state => state.programmes.entries );
  let filteredProgrammes = programmes;
  const search = useSelector( state => state.filter.search );

  const isSelected = Object.entries( filter ).some( ( [ index, selection ] ) => {
    return index === taxonomy && selection.includes( id );
  } );

  if( typeof filter !== 'undefined' && Object.keys( filter ).length > 0 ) {
    filteredProgrammes = [];

    filteredProgrammes = programmes.filter( program => {
      return Object.entries( filter ).every( ( [ tax, terms ] ) => {
        return typeof program.attributes[tax] !== 'undefined' && terms.some( term => program.attributes[tax].includes( term ) );
      } );
    } );
  }

  const onChangeHandler = ( e ) => {
    if( isSelected ) {
      onRemoveTermHandler( taxonomy, id );
    } else {
      onSelectTermHandler( taxonomy, id );
    }

    if( isMobile() ) {
      const results = document.querySelector( '#filter-results' );

      if( typeof results === 'undefined' || results === null ) return;

      scrollToElement( results, -60 );
    }
  };

  const count = filteredProgrammes.filter( program => {
    return program.attributes[taxonomy] && program.attributes[taxonomy].includes( id ) && ( program.title.toLowerCase().includes( search.trim().toLowerCase() ) || program.tags.toLowerCase().includes( search.trim().toLowerCase() ) );
  } ).length;

  return (
    <span style={{
      display: count <= 0 ? 'none' : 'inline'
    }}>
      <input onChange={onChangeHandler} className={styles.input} type="checkbox" name={`${taxonomy}_${id}`} id={`${taxonomy}_${id}`} />
      <label htmlFor={`${taxonomy}_${id}`} className={classes( {
        'term': true,
        'selected': isSelected
      } )} data-taxonomy={taxonomy} data-term-id={id} dangerouslySetInnerHTML={{__html: (name + ' (' + count + ')')}}>
      </label>
    </span>
  );
};
export default Term;