import React, { useState } from "react";
import classNames from "classnames/bind";
import { ReactComponent as Banner } from '../../../../assets/svgs/banner.svg';
import { useSelector } from "react-redux";
import ResultItem from "./ResultItem";

import styles from './Results.module.scss';
const classes = classNames.bind( styles );

const Results = ( props ) => {
  const [currentPage, setCurrentPage] = useState(1);
  const programmes = useSelector( state => state.programmes.filteredEntries );
  const ppp = 6;
  const numberOfPages = Math.ceil( programmes.length / ppp );
  const startIndex = ( ( currentPage - 1 ) * ppp );
  const endIndex = currentPage * ppp;
  const shownProgrammes = programmes.slice( startIndex, endIndex );
  let firstDotIsSet = false;
  let lastDotIsSet = false;

  const pagination = Array.from( { length: numberOfPages } ).map( ( index, page ) => {
      let element = <li key={page} onClick={ () => setCurrentPage( page + 1 ) } className={classes( {
        'pageLink': true,
        'active': currentPage === ( page + 1 )
      } )}>{ page + 1 }</li>;

      // Display the current page
      if( page + 1 === currentPage ) {
        return element;
      } else if( ( page ) === 0 ) {
        return element;
      } else if( ( page + 1 ) === numberOfPages ) {
        return element;
      } else if( ( page + 1 ) < currentPage + 3 && ( page + 1 ) > currentPage - 3 ) {
        return element;
      } else {
        if( page < currentPage && !firstDotIsSet ) {
          firstDotIsSet = true;
          return <li key={page} onClick={ () => setCurrentPage( Math.floor( 1 + ( ( currentPage ) / 2 ) ) ) }  className={styles.pageLink}>...</li>;
        }

        if( page > currentPage && !lastDotIsSet ) {
          lastDotIsSet = true;
          return <li key={page} onClick={ () => setCurrentPage( Math.floor( numberOfPages - ( ( numberOfPages - currentPage ) / 2 ) ) ) }  className={styles.pageLink}>...</li>;
        }

        return false;
      }
    } );

  return (
    <React.Fragment>
      <div id="filter-results" className={styles.container}>
        <ul className={ styles.navigation }>
          { currentPage > 1 && <li onClick={ () => setCurrentPage( currentPage - 1 ) } className={styles.prevButton}></li> }
          { pagination }
          { currentPage < numberOfPages && <li onClick={ () => setCurrentPage( currentPage + 1 ) } className={styles.nextButton}></li> }
        </ul>
        <div className={styles.results}>
          <div className={styles.resultsTitle}>
            <Banner className={styles.banner} />
            <span className={styles.resultsTitleText}>Deine Möglichkeiten</span>
          </div>

          <div className={styles.inner}>
            {
              shownProgrammes.length > 0 ? (
                  shownProgrammes.map( ( program, index ) => {
                    return <ResultItem
                      key={index}
                      id={program.id}
                      title={program.title}
                      link={program.url}
                      attributes={program.attributes}/>
                  } )
              ) : <div className={styles.noResults}>Deine Suche ergab leider keine Treffer.</div>
            }
          </div>
        </div>

        <ul className={ `${styles.navigation} ${styles.navigationBottom}` }>
          { currentPage > 1 && <li onClick={ () => setCurrentPage( currentPage - 1 ) } className={styles.prevButton}></li> }
          { pagination }
          { currentPage < numberOfPages && <li onClick={ () => setCurrentPage( currentPage + 1 ) } className={styles.nextButton}></li> }
        </ul>
      </div>
    </React.Fragment>
  );
};
export default Results;