import React, {useId} from "react";
import classNames from "classnames/bind";

import styles from './Column.module.scss';
const classes = classNames.bind(styles);

const Column = ( props ) => {
  const id = useId();

  const width = props.width ? props.width : '12';
  const breakpoints = props.breakpoints ? props.breakpoints : false;

  const breakpointClasses = {};

  Object.entries(breakpoints).forEach(([key, value]) => {
    breakpointClasses[`column--${key}-${value}`] = true;
  });

  return <div key={id} className={classes( props.className, Object.assign({
    'column': true,
    [`column--${width}`]: true,
  }, breakpointClasses ))}>{props.children}</div>;
};
export default Column;
