import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pages: {},
};

const pagesSlice = createSlice( {
  name: 'pages',
  initialState: initialState,
  reducers: {
    addPage(state, action) {
      state.pages[action.payload.slug] = action.payload.page;
    },
    removePage(state, action) {
      delete state.pages[action.payload.slug];
    },
    clearPages(state, action) {
      state.pages = {}
    },
  }
} );

export const pagesActions = pagesSlice.actions;
export default pagesSlice.reducer;
