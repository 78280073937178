import React, {useEffect, useState} from "react";
import debounce from 'lodash.debounce';

import styles from './Filter.module.scss';
import { useDispatch, useSelector } from "react-redux";
import Term from "./Term";
import { filterActions } from "../../../../stores/reducers/filter";
import { programmesActions } from "../../../../stores/reducers/programmes";
import Button from "../../../UI/Button";
import Results from "./Results";
import { ReactComponent as SearchIcon } from '../../../../assets/svgs/search.svg';
import classNames from "classnames/bind";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { isMobile, scrollToElement } from "../../../../util/helpers";
const classes = classNames.bind(styles);

const Filter = ( props ) => {
  const dispatch = useDispatch();
  const order = useSelector( state => state.theme.options.filter_order );
  const attributes = useSelector( state => state.filter.attributes );
  const selection  = useSelector( state => state.filter.selection );
  const programmes = useSelector( state => state.programmes.entries );
  const search = useSelector( state => state.filter.search );
  const [selectedTaxonomy, setSelectedTaxonomy] = useState( undefined );

  const applyFilterToList = () => {
    if( typeof selection !== 'undefined' && ( Object.keys( selection ).length > 0 ) ) {
      let filteredProgrammes = programmes.filter( program => {
        return Object.entries( selection ).every( ( [ tax, terms ] ) => {
          return typeof program.attributes[tax] !== 'undefined' && terms.some( term => program.attributes[tax].includes( term ) );
        } );
      } );
      dispatch( programmesActions.filter( filteredProgrammes ) );
    } else {
      dispatch( programmesActions.resetFilter() );
    }

    if( typeof search !== 'undefined' && search.length > 0 ) {
      dispatch( programmesActions.filterBySearch( search.trim() ) );
    }
  };

  useEffect( () => {
    applyFilterToList();
  }, [programmes, selection] );

  useEffect( () => {
    if( typeof search !== 'undefined' && search.length > 0 ) {
      const results = document.querySelector( '#filter-results' );

      if( typeof results === 'undefined' || results === null ) return;

      scrollToElement( results, isMobile() ? -60 : 0 );
    }
  }, [] );

  const onSelectTermHandler = ( taxonomy, termID ) => {
    dispatch( filterActions.addSelection( {
      taxonomy: taxonomy,
      term: termID,
      source: 'filter'
    } ) );
  };

  const onRemoveTermHandler = ( taxonomy, termID ) => {
    dispatch( filterActions.removeSelection( {
      taxonomy: taxonomy,
      term: termID
    } ) );
  };

  const resetFilter = () => {
    dispatch( programmesActions.resetFilter() );
    dispatch( filterActions.reset() );

    if( isMobile() ) {
      const results = document.querySelector( '#filter-results' );

      if( typeof results === 'undefined' || results === null ) return;

      scrollToElement( results, -60 );
    }
  };

  const onSearchHandler = ( e ) => {
    let search = e.target.value;
    dispatch( filterActions.filterBySearch( search ) );
    dispatch( programmesActions.filterBySearch( search ) );
  };

  const onSearchKeyUpHandler = ( e ) => {
    if( e.key === 'Enter' || e.keyCode === 13 ) {
      const results = document.querySelector( '#filter-results' );

      if( typeof results === 'undefined' || results === null ) return;

      scrollToElement( results, isMobile() ? -60 : 0 );
    }
  };

  const toggleTerms = ( termID ) => {
    const terms = document.getElementById('terms_' + termID);

    if(selectedTaxonomy === termID) {
      setSelectedTaxonomy( '' );
      enableBodyScroll(terms);
    }
    else {
      setSelectedTaxonomy(termID);
      disableBodyScroll(terms);
    }
  };

  return (
    <React.Fragment>
      <table className={styles.table}>
        <thead className={styles.thead}>
        <tr className={styles.tableRow}>
          <th className={`${styles.tableData} ${styles.attributeTitle}`}>
            <label htmlFor="filter-search">Suche</label>
          </th>
          <th className={styles.tableData}>
            <div className={styles.searchContainer}>
              <input onChange={ onSearchHandler }
                     onKeyUp={ onSearchKeyUpHandler }
                     className={styles.search}
                     name="filter-search"
                     id="filter-search"
                     type="text"
                     placeholder="Was willst du studieren?"
                     value={search}
              />
              <SearchIcon className={styles.searchIcon} />
            </div>
          </th>
        </tr>
        </thead>
        <tbody className={styles.tbody}>
        { Object.entries( order ).map( ( [ index, attributeName ] ) => {
          const name = attributeName;
          if( typeof attributes[attributeName] === 'undefined' ) return;
          const attribute = attributes[attributeName];
          const count = selection[name] ? selection[name].length : 0;

          return (
            <tr key={name} className={styles.tableRow}>
              <td className={`${styles.tableData} ${styles.attributeTitle}`} onClick={() => toggleTerms(name)}>
                {attribute.title}
                {
                  count === 0 ? '' : (
                    <span className={styles.termCount}>{count}</span>
                  )
                }
              </td>
              <td className={`${styles.tableData}`}>
                <div className={classes({
                  'terms': true,
                  'isVisible': name === selectedTaxonomy
                })} id={'terms_' + name}>
                  <div className={styles.termTitle}>
                    {attribute.title}
                    <div className={styles.termClose} onClick={() => toggleTerms(name)}></div>
                  </div>

                  <div className={styles.termsHolder}>
                    { attribute.terms.map( ( term, termKey ) => {
                      return (
                        <Term
                          key={termKey}
                          taxonomy={name}
                          id={term.term_id}
                          name={term.name}
                          onSelectTermHandler={onSelectTermHandler}
                          onRemoveTermHandler={onRemoveTermHandler}/>
                      );
                    } ) }
                  </div>

                  <div className={styles.termSubmit} >
                    <Button text={"Anwenden"} onClick={() => toggleTerms(name)}/>
                  </div>
                </div>
              </td>
            </tr>
          );
        } ) }
        </tbody>
      </table>
      <Button className={styles.reset} text={'Filter zurücksetzen'} onClick={resetFilter} />

      <Results />
    </React.Fragment>
  );
};
export default Filter;