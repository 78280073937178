import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menus: []
};

const menusSlice = createSlice( {
  name: 'menus',
  initialState: initialState,
  reducers: {
    fill( state, action ) {
      state.menus = action.payload;
    }
  }
} );

export const menusActions = menusSlice.actions;
export default menusSlice.reducer;