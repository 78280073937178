import React from "react";
import Row from "../Layout/Row";
import Column from "../Layout/Column";

import styles from './Footer.module.scss';
import Container from "../Layout/Container";
import { ReactComponent as InstagramLogo } from "../../assets/svgs/instagram.svg";
import { ReactComponent as FacebookLogo } from "../../assets/svgs/facebook.svg";
import { ReactComponent as BKALogo } from "../../assets/images/BKA_Logo_srgb.svg";
import { useSelector } from "react-redux";
import Partners from "./Partners";

const Footer = ( props ) => {
  const socialOptions = useSelector( state => state.theme.options.social );
  const themeOptions = useSelector( state => state.theme.options );
  const contactOptions = useSelector( state => state.theme.options.contact );
  const menusOption = useSelector( state => state.menus.menus );

  return (
    <footer className={styles.footer}>
      <Container>
        <Partners />
        <a target={"_blank"} href="https://www.bundeskanzleramt.gv.at/" className={styles.support}>
          <span>
            Dieses Projekt wurde mit Mitteln des Bundeskanzleramts zur Förderung des Empowerments von Mädchen und Frauen in Bildung, Beruf und Gesellschaft mit Fokus auf Mathematik, Informatik, Naturwissenschaft und Technik sowie Finanzkompetenz realisiert.
          </span>
          <BKALogo className={styles.bka} />
        </a>
        <Row align="bottom" className={styles.bottom}>
          <Column className={styles.socialColumn} width={3} breakpoints={{m: 12}}>
            { typeof socialOptions.instagram !== 'undefined' && socialOptions.instagram.length > 0 ? (
              <a className={styles.socialIcons} href={socialOptions.instagram} target="_blank" rel="noreferrer">
                <span className="hidden">Instagram</span>
                <i aria-hidden="true"><InstagramLogo /></i>
              </a>
            ) : '' }

            { typeof socialOptions.facebook !== 'undefined' && socialOptions.facebook.length > 0 ? (
              <a className={styles.socialIcons} href={socialOptions.facebook} target="_blank" rel="noreferrer">
                <span className="hidden">Facebook</span>
                <i aria-hidden="true"><FacebookLogo aria-hidden="true" /></i>
              </a>
            ) : '' }
            <div className={styles.hashtag}>#{themeOptions.hashtag}</div>
          </Column>
          <Column className={styles.addressColumn} width={ typeof menusOption.footer === 'undefined' ? 9 : 7} breakpoints={{m: 12}}>
            <address dangerouslySetInnerHTML={{__html: contactOptions.contact}} />

            <address>
              <a href={`mailto:${socialOptions.mail}`}>E-Mail: {socialOptions.mail}</a>
              <a href={`tel:${socialOptions.phone}`}>Tel.: {socialOptions.phone}</a>
            </address>
          </Column>
          { typeof menusOption.footer !== 'undefined' ? (
            <Column className={styles.menuColumn} width={2} breakpoints={{m: 12}}>
              <nav aria-label={'footer nav'}>
                <ul className={styles.navUl}>
                  { Object.entries( menusOption.footer.items).map( ( [key, value] ) => {
                    return <li key={key}>
                      <a className={`${styles.menuItem}`} href={value.url} target={value.target}>{value.title}</a>
                    </li>;
                  } ) }
                </ul>
              </nav>
            </Column>
          ) : null }
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
