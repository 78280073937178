import React from "react";
import styles from './MediaText.module.scss'
import Row from "../Layout/Row";
import Column from "../Layout/Column";
import Slider from "../Slider/Slider";

const MediaText = ( props ) => {

  const type     = props.attrs.data['block_media-text_type'];
  const layout   = props.attrs.data['block_media-text_layout'];
  const position = props.attrs.data['block_media-text_position'];
  const image    = props.attrs.data['block_media-text_image'];
  const video    = props.attrs.data['block_media-text_video'];
  const nrimages = props.attrs.data['block_media-text_images'];
  let text       = props.attrs.data['block_media-text_text'];

  let images = [];
  for(let i = 0; i < nrimages; i++) {
    images.push(props.attrs.data['block_media-text_images_' + i + '_block_media-text_img']);
  }

  let media;
  switch( type ) {
    case 'image':
      media = <div className={styles.imageHolder}>
        <img src={image['sizes']['full']} alt={image['alt']} className={styles.image} />
      </div>
      break;
    case 'video':
      media = <div className={styles.videoHolder}>
        <video className={styles.video} controls>
          <source src={video.url} type="video/mp4"/>
        </video>
      </div>
      break;
    case 'slider':
      media = <div className={styles.slider}>
        <Slider images={images}></Slider>
      </div>
      break;
    default:
      media = 'default'
  }

  const textContent = <div className={styles.textHolder} dangerouslySetInnerHTML={{__html: text}}></div>
  const contentLeft = position === 'left' ? media : textContent;
  const contentRight = position === 'left' ? textContent : media;

  let widthLeft;
  let widthRight;
  switch( layout ) {
    case 'quarter_left':
      widthLeft  = 3;
      widthRight = 9;
      break;
    case 'quarter_right':
      widthLeft  = 9;
      widthRight = 3;
      break;
    case 'third_left':
      widthLeft  = 4;
      widthRight = 8;
      break;
    case 'third_right':
      widthLeft  = 8;
      widthRight = 4;
      break;
    default:
      widthLeft  = 6;
      widthRight = 6;
  }

  let contentLayout = <Row>
    <Column width={widthLeft}  breakpoints={{ m: 12 }} >{contentLeft}</Column>
    <Column width={widthRight} breakpoints={{ m: 12 }} >{contentRight}</Column>
  </Row>;



  return <div>
    {contentLayout}
  </div>

};
export default MediaText;
