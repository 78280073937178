import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  steps: [],
  currentStep: false,
  index: false,
  answers: [],
  finished: false
};

const configuratorSlice = createSlice( {
  name: 'configurator',
  initialState: initialState,
  reducers: {
    nextStep( state, action ) {
      try {
        const nextStep = state.answers.filter( a => a.id === state.currentStep.id )[ 0 ].next;

        // eslint-disable-next-line
        if( nextStep == 'false' ) {
          state.finished = true;
        } else {
          // eslint-disable-next-line
          state.currentStep = state.steps.filter( step => step.id == nextStep )[ 0 ];
          state.index = state.index + 1;
        }
      }catch( e ) {

      }
    },
    addSteps( state, action ) {
      action.payload.forEach( step => {
        const exists = state.steps.some( s => s.id === step.id );
        if( !exists ) {
          state.steps.push( step );
        }
      } );
    },
    addFirstStep( state, action ) {
      state.steps = [];
      state.steps.push( action.payload );
      state.currentStep = action.payload;
      state.index = 0;
    },
    addAnswer( state, action ) {
      const answerIndex = state.answers.findIndex( answer => answer.id === action.payload.id );

      if( answerIndex === -1 ) {
        state.answers.push( action.payload );
      } else {
        const answerIndex = state.answers.findIndex( answer => answer.id === action.payload.id );
        let foundIndex = false;
        state.answers.forEach( (answer, index) => {
          if( foundIndex ) {
            // state.answers.splice( index, 1 );
          }

          if( index === answerIndex ) {
            state.answers[index] = action.payload;
            foundIndex = true;
          }
        } );

        state.answers.splice( answerIndex + 1, state.answers.length - ( answerIndex + 1 ) );
        // state.answers[ answerIndex ] = action.payload;
      }
    },
    reset( state, action ) {
      state.currentStep = state.steps[ 0 ];
      state.index = 0;
      state.finished = false;
      state.answers = [];
    },
    moveBack( state, action ) {
      const lastStepID = state.steps.filter( step => {
        return step.id === state.answers[state.index - 1].id;
      } )[0].id;

      state.currentStep = state.steps.filter( step => step.id === lastStepID )[0];
      state.index = state.index - 1;

    }
  }
} );

export const configuratorActions = configuratorSlice.actions;
export default configuratorSlice.reducer;
