import React from "react";
import {useField} from "formik";

import styles from './Textarea.module.scss';

const TextArea = ( { label, ...props } ) => {
  const [field, meta] = useField( props );
  return (
    <div className={styles.inputContainer}>
      <label className={styles.label} htmlFor={props.id}>{label}</label>
      <textarea id={props.id} className={styles.input} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
    </div>
  );
};
export default TextArea;
