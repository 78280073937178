import React, {useEffect } from 'react';
import TagManager from 'react-gtm-module'
import {useCookieConsent} from "@porscheofficial/cookie-consent-banner-react";
import { useSelector } from "react-redux";


const GoogleTagManager = ( props ) => {

  const acceptedCategories = useCookieConsent();
  const cookieIDs = useSelector( state => state.theme.cookieIDs );

  useEffect(() => {
    const necessaryCookies = [ 'gtm' ];
    if( necessaryCookies.every(cookie => acceptedCategories.includes(cookie)) ) {
      if( typeof cookieIDs.gtm !== 'undefined' ) {
        TagManager.initialize({
          gtmId: cookieIDs.gtm
        })
      }
    }
  }, [acceptedCategories, cookieIDs])
};
export default GoogleTagManager;