import React from "react";
import classNames from "classnames/bind";

import styles from './LoadingIndicator.module.scss';
import { ReactComponent as FITLogo } from '../../assets/svgs/fit_logo_blau.svg';

const classes = classNames.bind( styles );

const LoadingIndicator = ( props ) => {
  return (
    <div className={classes({
      'loadingIndicator': true
    })}>
      <FITLogo className={classes({
        'logo': true
      })} />
    </div>
  );
};
export default LoadingIndicator;