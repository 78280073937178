import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  options: {},
  cookies: {},
  cookieIDs: {},
};

const themeSlice = createSlice( {
  name: 'theme',
  initialState: initialState,
  reducers: {
    fillOptions( state, action ) {
      state.options = action.payload;
    },
    fillCookies( state, action ) {
      state.cookies = action.payload;
    },
    fillCookieIDs( state, action ) {
      state.cookieIDs = action.payload;
    }
  }
} );

export const themeActions = themeSlice.actions;
export default themeSlice.reducer;
