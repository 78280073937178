import React from "react";
import {useSelector} from "react-redux";
import Content from "../Content/Content";
import PageUpdater from "../PageUpdater/PageUpdater";
import Container from "../Layout/Container";
import classNames from "classnames/bind";

import styles from './Page.module.scss';
const classes = classNames.bind( styles );

const Page = ( props ) => {
  const page = useSelector( state => state.page.currentPage );
  let output;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams( queryString );
  const searchValue = urlParams.get( 's' ) ?? false;

  if( searchValue ) {
    output = <h1>Search</h1>
  } else if( typeof page !== 'undefined' && page !== false ) {
    output = <Content page={page}></Content>;
  } else if( typeof page !== 'undefined' && page === false ) {
    output = (
        <main>
          <Container>
            <div className={styles.page404}>
              <div className={styles.title404}>404</div>
              <div className={styles.text404}>Die gesuchte Seite wurde leider nicht gefunden.</div>
            </div>
          </Container>
        </main>
    );
  } else {
    output = 'loading';
  }

  return (
    <main className={`page-${page !== null ? page.id : ''} ${classes( {
      'mainContent': true,
      [`font-color--${page !== null ? page.font_color : 'blue-dark'}`]: true
    } )}`}>
      <PageUpdater />
      { output }
    </main>
  );
};
export default Page;
