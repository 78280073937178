import React, { useState, useEffect, useRef, useCallback } from "react";
import classNames from "classnames/bind";
import styles from './Program.module.scss';
import './related-programs-slider.css';
import Row from "../Layout/Row";
import Column from "../Layout/Column";
import Container from "../Layout/Container";
import {useDispatch, useSelector} from "react-redux";
import Button from "../UI/Button";

import { ReactComponent as WhatsApp } from '../../assets/svgs/whatsapp.svg';
import { ReactComponent as Letter } from '../../assets/svgs/mail.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toLocalStorage } from "../../util/helpers";

const classes = classNames.bind( styles );

const Program = ( props ) => {
  const dispatch = useDispatch();
  const [ program, setProgram ] = useState( undefined );
  const [ isExpanded, setExpanded ] = useState( false );
  const [ linksShown, setLinksShown ] = useState( false );
  const programs = useSelector(state => state.programmes.entries);
  const allAttributes = useSelector( state => state.programmes.attributes );
  const ambassadors = useSelector( state => state.ambassadors.entries );
  const options = useSelector( state => state.theme.options );
  const filteredPrograms = useSelector( state => state.programmes.filteredEntries );
  const sliderRef = useRef( null );
  let attributes = [];
  let navigation = '';
  const { pathname } = useLocation();

  const handlePrev = useCallback( () => {
    if( !sliderRef.current ) return;
    sliderRef.current.swiper.slidePrev();
  }, [] );

  const handleNext = useCallback( () => {
    if( !sliderRef.current ) return;
    sliderRef.current.swiper.slideNext();
  }, [] );

  useEffect( () => {
    setProgram(programs.filter( _program => { return _program.id === props.id } )[0]);
  }, [setProgram, programs, dispatch, props.id]);

  if(typeof program === 'undefined') return 'loading';


  const currentAmbassador = program.ambassador ? ambassadors.filter( ambassador => ambassador.id === program.ambassador.ID)[0] : false;
  const ambassadorImage = currentAmbassador ? <img src={currentAmbassador.image.sizes['full']} alt={currentAmbassador.image.alt} className={styles.ambassadorImage} /> : '';

  attributes = Object.entries( program.attributes ).map( (attribute, idx) => {
    if(attribute[1].length > 0) {
      let terms = attribute[1].map( (_term, index) => {
        return <div key={index}>{allAttributes[attribute[0]]['terms'][_term]}</div>;
      } );

      return (
        <div key={idx} className={styles.fact} data-title={allAttributes[attribute[0]]['title']}>
          <div className={styles.attributeTitle}>{allAttributes[attribute[0]]['title']}</div>
          <div className={styles.attributeTerms}>{terms}</div>
        </div>
      );
    }
    return false;
  } );


  let institutes = program.partner.map( ( partner, idx ) => {
    return (
      <div key={idx}>{partner.post_title}</div>
    );
  } );

  attributes.push( (
    <div key={15} className={styles.fact} data-title="Partner">
      <div className={styles.attributeTitle}>Institution</div>
      <div className={styles.attributeTerms}>{institutes}</div>
    </div>
  ) );


  if(filteredPrograms.length > 1) {
    const currentIndex = filteredPrograms.findIndex((_program) => _program.id === program.id);
    const maxIndex = filteredPrograms.length;
    const prevIndex = currentIndex === 0 ? (maxIndex - 1) : currentIndex - 1;
    const nextIndex = currentIndex === (maxIndex - 1) ? 0 : currentIndex + 1;
    const prev = typeof filteredPrograms[prevIndex] !== 'undefined' ? filteredPrograms[prevIndex].url : false;
    const next = typeof filteredPrograms[nextIndex] !== 'undefined' ? filteredPrograms[nextIndex].url : false;

    navigation = prev && next ? (
      <div className={styles.navigation}>
        <a href={prev} className={styles.navPrev}>Zurück</a>
        <a href={next} className={styles.navNext}>Weiter</a>
      </div>
    ) : '';
  }

  const onExpandInfosHandler = () => {
    setExpanded( !isExpanded );
  };

  const showLinks = () => {
    setLinksShown( !linksShown );
  };

  const onNavigateToForm = ( e ) => {
    e.preventDefault();
    toLocalStorage( 'form-previous-program', { path: pathname, id: program.id, title: program.title } );
    window.location.href = options.contact_form_page;
  };


  return (
    <div className={classes( {
      'program': true,
    } )}>
      <Container>
        {navigation}
        <Row className={styles.row}>
          <Column width={3} breakpoints={({ 'xxl': 4, 'l': 5, 'm': 12 })}>
            <div className={classes( {
              'infos': true,
              'is-expanded': isExpanded
            } )}>
              <div className={styles.infoTitleHolder}>
                <div className={styles.infoTitle}>{"Fakten"}</div>
              </div>

              {attributes}

              <span onClick={onExpandInfosHandler} className={styles.expandTrigger}></span>
            </div>
          </Column>
          <Column className={styles.content} width={9} breakpoints={({ 'xxl': 8, 'l': 7, 'm': 12 })}>
            <h1 className={styles.title}>
              {program.title}
            </h1>
            <div className={styles.description} dangerouslySetInnerHTML={{__html:program.info_text}}></div>

            <Row className={styles.bottomRow}>
              <Column width={5} breakpoints={({ 'xxl': 7, 'l': 12 })}>
                <div className={classes({
                  'buttonHolder': true,
                })}>
                  {
                    program.links ? (
                      !program.links[0] ? '' : (
                        <div>
                          <div onClick={showLinks} className={styles.showMoreButton}>
                            <Button text={'zum Studium'} className={classes({
                              'dropdownBtn': true,
                              'isOpen': linksShown
                            })}></Button>
                          </div>
                          <div className={classes({
                            'programLinks': true,
                            'isVisible': linksShown,
                          }, 'hasChildren'+program.links.length
                          )}>
                            {
                              program.links.map((link) => {
                                return (
                                  <a href={link['program_link']} target={'_blank'} rel={'noreferrer'}>
                                    <Button text={link['program_partner']['post_title']}></Button>
                                  </a>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    ) : (
                      !program.link ? '' : (
                        <a href={program.link} target={'_blank'} rel={'noreferrer'}>
                          <Button text={'zum Studium'}></Button>
                        </a>
                      )
                    )
                  }
                  {
                    !options.social.whatsapp ? '' : (
                      <a href={'https://wa.me/' + options.social.whatsapp} target={'_blank'} rel={'noreferrer'}>
                        <Button text={'Kontakt Whatsapp'} icon={<WhatsApp/>}></Button>
                      </a>
                    )
                  }
                  {
                    !options.contact_form_page ? '' : (
                      <a href={options.contact_form_page} onClick={ onNavigateToForm }>
                        <Button text={'Kontaktformular'} icon={<Letter/>}></Button>
                      </a>
                    )
                  }
                </div>
              </Column>
              { program.ambassador_enabled ? (
                <Column width={7} breakpoints={({ 'xxl': 12 })}>
                  <div className={styles.ambassador}>
                    <div className={styles.ambassadorText} dangerouslySetInnerHTML={{__html: program.ambassador_text}}></div>
                    {ambassadorImage}
                  </div>
                </Column>
              ) : '' }
            </Row>
          </Column>
        </Row>

        <Row className={styles.mobileButtonRow}>
          <Column width={5} breakpoints={({ 'xxl': 7, 'l': 12 })}>
            <div className={classes({
              'buttonHolder': true,
            })}>
              {
                program.links ? (
                  !program.links[0] ? '' : (
                    <div>
                      <div onClick={showLinks} className={styles.showMoreButton}>
                        <Button text={'zum Studium'} className={classes({
                          'dropdownBtn': true,
                          'isOpen': linksShown
                        })}></Button>
                      </div>
                      <div className={classes({
                        'programLinks': true,
                        'isVisible': linksShown,
                      }, 'hasChildren'+program.links.length
                      )}>
                        {
                          program.links.map((link) => {
                            return (
                              <a href={link['program_link']} target={'_blank'} rel={'noreferrer'}>
                                <Button text={link['program_partner']['post_title']}></Button>
                              </a>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                ) : (
                  !program.link ? '' : (
                    <a href={program.link} target={'_blank'} rel={'noreferrer'}>
                      <Button text={'zum Studium'}></Button>
                    </a>
                  )
                )
              }
              {
                !options.social.whatsapp ? '' : (
                  <a href={'https://wa.me/' + options.social.whatsapp} target={'_blank'} rel={'noreferrer'}>
                    <Button text={'Kontakt Whatsapp'} icon={<WhatsApp/>}></Button>
                  </a>
                )
              }
              {
                !options.contact_form_page ? '' : (
                  <a href={ options.contact_form_page } onClick={ onNavigateToForm }>
                    <Button text={ 'Kontaktformular' } icon={ <Letter/> }></Button>
                  </a>
                )
              }
            </div>
          </Column>
          { program.ambassador_enabled ? (
            <Column width={7} breakpoints={({ 'xxl': 12 })}>
              <div className={styles.ambassador}>
                <div className={styles.ambassadorText} dangerouslySetInnerHTML={{__html: program.ambassador_text}}></div>
                {ambassadorImage}
              </div>
            </Column>
          ) : '' }
        </Row>

        { program && program.related && program.related.length > 0 ? (
          <Row>
            <Column width={12}>
              <div className="relatedContainer">
                <div className="related__header">
                  <h2>Das könnte dich auch interessieren:</h2>
                  <div className="related__header-controls">
                    <div className="related__prev" onClick={handlePrev}></div>
                    <div className="related__next" onClick={handleNext}></div>
                  </div>
                </div>

                <div className="related__outer">
                  <Swiper
                    ref={sliderRef}
                    className="relatedSlider"
                    slidesPerView={2}
                    spaceBetween={30}
                    breakpoints={ {
                      950: {
                        slidesPerView: 2
                      },
                      320: {
                        slidesPerView: 1
                      }
                    } }>
                    { program.related.map( (relatedProgram, index) => {
                      return (
                        <SwiperSlide className="related__slide" key={index}>
                          <div className="related__inner">
                            <div className="related__title">{relatedProgram.post_title}</div>
                            <Button onClick={ () => window.location = relatedProgram.permalink } className="related__button" text="Studium Info" />
                          </div>
                        </SwiperSlide>
                      );
                    } ) }
                  </Swiper>
                  <div className="related__prev" onClick={handlePrev}></div>
                  <div className="related__next" onClick={handleNext}></div>
                </div>
              </div>
            </Column>
          </Row>
        ) : '' }
      </Container>
    </div>
  );

};
export default Program;
