import React from "react";
import Text from "../Blocks/Text/Text";
import Container from "../Layout/Container";
import Configurator from "../Blocks/Configurator/Configurator";
import MediaText from "../MediaText/MediaText";
import Slider from "../Slider/Slider";
import ContactForm from "../Form/Form";
import Filter from "../Blocks/Configurator/Filter/Filter";
import Program from "../Program/Program";

const Content = ( props ) => {
    let blocks = props.page ? props.page.blocks : [];
    let content = [];

    for( const [ key, block ] of Object.entries( blocks ) ) {
        let blockContent;
        const blockName = block['blockName'].replace( 'acf/', '' );
        const isFullWidth = block['attrs']['data'][`block_layout_${blockName}_container`] === 'full';

        const paddingTop = 'padding-top-' + block['attrs']['data'][`block_layout_${blockName}_padding_top`];
        const paddingBottom = 'padding-bottom-' + block['attrs']['data'][`block_layout_${blockName}_padding_bottom`];
        const paddingTopMobile = 'padding-top-m-' + block['attrs']['data'][`block_layout_${blockName}_padding_top_mobile`];
        const paddingBottomMobile = 'padding-bottom-m-' + block['attrs']['data'][`block_layout_${blockName}_padding_bottom_mobile`];
        const anchor = block['attrs']['data'][`block_layout_${blockName}_anchor`];
        const bg = block['attrs']['data'][`block_layout_${blockName}_bg_type`];
        const bg_color = block['attrs']['data'][`block_layout_${blockName}_bg_color`];

        switch ( blockName ) {
            case 'text':
                blockContent = <Text attrs={ block['attrs'] }></Text>;
                break;
            case 'configurator':
                blockContent = <Configurator />
                break;
            case 'filter':
                blockContent = <Filter />
                break;
            case 'media-text':
                blockContent = <MediaText attrs={block['attrs']}></MediaText>;
                break;
            case 'slider':
                blockContent = <Slider attrs={block['attrs']}></Slider>;
                break;
            case 'form':
                blockContent = <ContactForm attrs={block['attrs']}></ContactForm>;
                break;
            default: <p key={key}>unrecognized block "{blockName}"</p>;
        }

        content.push(
            <Container  key={key}
                        paddingTop={paddingTop}
                        paddingBottom={paddingBottom}
                        paddingTopMobile={paddingTopMobile}
                        paddingBottomMobile={paddingBottomMobile}
                        anchor={anchor}
                        bg={bg}
                        bg_color={bg_color}
                        fullWidth={isFullWidth}>{blockContent}</Container>
        );
    }

    if(props.page && props.page.post_type === 'program') {
        return <Program id={props.page.id}>{content}</Program>
    }
    else if( props.page && props.page.post_type === 'post' ) {

    } else {
        return <div>{content}</div>;
    }
};
export default Content;