import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  entries: [],
  filteredEntries: [],
  filteredByAttributes: [],
  attributes: []
};

const programmesSlice = createSlice( {
  name: 'programmes',
  initialState: initialState,
  reducers: {
    fill( state, action ) {
      const allEntries = action.payload;
      allEntries.sort( ( first, second ) => {
        return ( first.title > second.title ) ? 1 : ( ( second.title > first.title ) ? -1 : 0 );
      } );

      state.entries = allEntries;
      state.filteredEntries = allEntries;
    },
    filter( state, action ) {
      state.filteredEntries = action.payload;
      state.filteredByAttributes = action.payload;
    },
    filterBySearch( state, action ) {
      if( action.payload.trim().length > 0 ) {
        state.filteredEntries = state.filteredByAttributes.filter( programm => {
          return programm.title.toLowerCase().includes( action.payload.trim().toLowerCase() ) || programm.tags.toLowerCase().includes( action.payload.trim().toLowerCase() );
        } );
      } else {
        state.filteredEntries = state.filteredByAttributes;
      }
    },
    fillAttributes( state, action ) {
      state.attributes = action.payload;
    },
    resetFilter( state, action ) {
      state.filteredEntries = state.entries;
      state.filteredByAttributes = state.entries;
    }
  }
} );

export const programmesActions = programmesSlice.actions;
export default programmesSlice.reducer;
