import React from "react";
import {useField} from "formik";

import styles from './TextInput.module.scss';

const TextInput = ( { label, ...props } ) => {
  const [field, meta] = useField( props );
  return (
    <React.Fragment>
      <div className={styles.inputContainer}>
        <label className={styles.label} htmlFor={props.id}>{label}</label>
        <input id={props.id} className={styles.input} {...field} {...props} />
      </div>
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
    </React.Fragment>
  );
};
export default TextInput;
