import React, { useState } from "react";

import styles from './Header.module.scss';
import Container from "../Layout/Container";

// Importing SVGs
import { ReactComponent as FITLogo } from '../../assets/svgs/fit_logo_blau.svg';
import Burger from "../UI/Burger";
import Bubble from "../UI/Bubble";
import Navigation from "./Navigation";
import { useSelector } from "react-redux";
import { ConditionalWrapper } from "react-cookie-consent";

const Header = ( props ) => {
    const [navigationIsOpen, setNavigationOpen] = useState( false );
    const bubble = useSelector( state => state.theme.options.header.bubble );
    const currentPage = useSelector( state => state.page.currentPage );

    let icon = '';

    if( typeof currentPage !== 'undefined' && currentPage !== null && typeof currentPage.header !== 'undefined' && typeof currentPage.header.type !== 'undefined' && currentPage.header.type !== null ) {
        if( currentPage.header.type === 'bubble' ) {
            icon = (
              <ConditionalWrapper condition={ currentPage.header.bubble_link !== false } wrapper={ children => <a style={{ textDecoration: 'none' }} href={currentPage.header.bubble_link}>{children}</a> }>
                  <Bubble
                    title={currentPage.header.bubble_title}
                    text={[currentPage.header.bubble_text_1, currentPage.header.bubble_text_2]}
                    color={currentPage.header.bubble_color}
                    rotation={15} />
              </ConditionalWrapper>
            );
        } else if( currentPage.header.type === 'image' ) {
            icon = (
              <img src={currentPage.header.image.url} />
            );
        } else if( currentPage.header.type === 'inherit' ) {
            icon = (
              <ConditionalWrapper condition={bubble.link !== false} wrapper={children => <a style={{ textDecoration: 'none' }} href={bubble.link}>{children}</a>}>
                  <Bubble
                    title={bubble.title}
                    text={[bubble.text_1, bubble.text_2]}
                    color={bubble.color}
                    rotation={15} />
              </ConditionalWrapper>
            );
        }
    }

    const onClickBackHandler = (e) => {
        if( currentPage !== null && currentPage.back_link ) {
            window.location.href = currentPage.back_link;
        } else {
            window.location.href = '/';
        }
    };

    return (
        <header>
            <Container className={styles.inner}>
                <div className={styles.logo}>
                    <a href={"/"} title={'home link'} ><FITLogo /></a>
                    {/*{ currentPage !== null && !currentPage.back_button ? (*/}
                    {/*  <Button align="left" text="Zurück" className={styles.backButton} onClick={onClickBackHandler} />*/}
                    {/*) : '' }*/}
                </div>

                <div className={styles.circle}>
                    { icon }
                </div>
                <Burger onOpenNavigationHandler={ setNavigationOpen } />
                <Navigation isOpen={navigationIsOpen} />
            </Container>
        </header>
    );
};
export default Header;