import React from "react";

const Bubble = ( { title, text, color, rotation } ) => {
  let textOutput = '';

  if( Array.isArray( text ) ) {
    textOutput = text.map( ( line, index ) => {
      return (
        <text key={index} style={{
          textAnchor: 'middle',
          dominantBaseline: 'middle',
          fontSize: '16px',
          textTransform: 'uppercase',
          fill: color,
          fontWeight: '900',
        }} x={'58%'} y={`${35 + (10 * index)}%`}>{line}</text>
      );
    } );
  } else {
    textOutput = <text style={{
      textAnchor: 'middle',
      dominantBaseline: 'middle',
      fontSize: '16px',
      textTransform: 'uppercase',
      fill: color,
      fontWeight: '900',
    }} x={'58%'} y={'35%'}>{text}</text>
  }

  return (
    <svg x="0px" y="0px"
         viewBox="0 0 310 230" style={{
           enableBackground: 'new 0 0 310 230'
    }}>
      <style>
        {
          `
            .st0 {
              fill: ${color};
            }
          `
        }
      </style>
      <g>
        <g>

          <g style={{
            transform: `rotate(${rotation}deg`
          }}>
            <text style={ {
              textAnchor: 'middle',
              dominantBaseline: 'middle',
              fontSize: '40px',
              textTransform: 'uppercase',
              fontWeight: '900',
              fill: color,
            } } x={ '58%' } y={ '20%' }>{ title }</text>

            { textOutput }
          </g>

          <g>
            <path className="st0" d="M94,190.2L94,190.2c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.2-0.1-0.3-0.2c0.2-0.1,0.3-0.3,0.1-0.4
				c-0.3-0.2-0.5,0.2-0.6,0.3c-0.2,0.2-0.5,0.5-0.1,0.5c0,0.1,0.2,0.3,0.6,0.3c0.4,0.7,1.4,0.1,0.8-0.4c0.3,0.1,0.9,0.1,0.6-0.5
				C94.5,189.5,93.8,190,94,190.2"/>
            <path className="st0" d="M89.3,188.5c0-0.8-2-3-1.3-0.9c0.1,0.4,0.3,0.7,0.5,0.9c-0.3,0-0.5,0.1-0.5,0.2c-0.5,1-0.2,2.8,0.7,1.5
				c0.2-0.4,0.7-0.6,0.9-1S89.6,188.6,89.3,188.5"/>
            <path className="st0" d="M89.3,184.4c0,0.8-0.1,1.7-0.1,2.5c0,0.1,0.4,0.5,0.4,0.3c0-0.9,0.1-1.8,0.1-2.7
				C89.7,184.3,89.3,184.1,89.3,184.4"/>
            <path className="st0"
                  d="M83.3,185.1c-0.1,0.3,1.7,1.1,1.8,1c0.1-0.1,0.6-0.6,0.5-0.7C85.5,185.3,83.4,184.9,83.3,185.1"/>
            <path className="st0" d="M84.7,188.4c0-0.1-0.1-0.2-0.1-0.3C84.5,188.1,84.6,188.2,84.7,188.4"/>
            <path className="st0" d="M7.1,127.6c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.2-0.2-0.3c0-0.1,0.2-0.2,0.4-0.1c0.2,0.1,0.3,0.1,0.4,0
				c0-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.3-0.4-0.4c0.1-0.4-0.4-0.4-0.9,0c-0.3,0.3-0.7,0.4-1,0.3c-0.5-0.1-0.8,0.2-0.5,0.5
				c0.2,0.2,0.9,0.2,0.9,0c0.1-0.4,0.4-0.3,0.9,0.1C6.4,127.6,7,127.9,7.1,127.6"/>
            <path className="st0" d="M309.2,134.4c-0.4,0.3-1.5,0-2-0.5c-0.2-0.2-0.4-0.3-0.4-0.2s0.1,0.2,0.2,0.3s0.2,0.3,0.2,0.4
				c-0.1,0.3,0.7,0.6,1.3,0.4c0.2,0,0.4,0,0.6,0.2c0.3,0.3,0.9,0.1,1-0.3C310.1,134.4,309.6,134.1,309.2,134.4"/>
            <path className="st0" d="M285.3,132.5c-0.6,0.1-0.7,0.2-0.3,0.5c0.2,0.2,0.5,0.3,0.6,0.3c0.2,0,0.2,0.2-0.1,1.5
				c-0.1,0.5-0.1,0.9,0,0.9s0.1,0.2,0,0.4s-0.1,0.5,0,0.6c0.4,0.3,0.2,0.4-0.4,0.4c-0.3,0-0.6,0-0.6,0.1s0.1,0.1,0.3,0.1
				c0.5,0,1,0.4,0.8,0.8c-0.1,0.2-0.1,0.5,0,0.7s0.1,0.4,0,0.5c-0.1,0-0.3,0.3-0.4,0.5s-0.2,0.6-0.3,0.8c-0.1,0.2-0.2,0.3-0.3,0.2
				c-0.1-0.1-0.2,0-0.3,0c-0.1,0.1,0,0.2,0.2,0.2s0.3,0.2,0.3,0.3s-0.2,0.2-0.4,0.2c-0.5-0.1-0.6,0.5-0.1,0.8
				c0.4,0.3,0.2,2.1-0.2,2.2c-0.1,0-0.2,0.2-0.2,0.4s-0.1,0.4-0.5,0.4c-0.3,0-0.5,0.2-0.4,0.3s0.2,0.2,0.4,0.1
				c0.5-0.2,0.6-0.1,0.5,0.3c-0.1,0.5-0.1,1.6,0,1.9c0.1,0.2-0.5,0.6-0.8,0.4c-0.3-0.2-0.3,0.2,0.1,0.5c0.2,0.2,0.2,0.5,0.1,0.6
				c-0.1,0.2-0.1,0.4,0,0.6c0.2,0.2,0.1,0.4-0.2,0.8c-0.3,0.3-0.3,0.5-0.2,0.7c0.3,0.3,0.2,0.5-0.1,0.3c-0.1-0.1-0.2,0-0.3,0.2
				c0,0.2-0.2,0.4-0.3,0.4c-0.1,0-0.2,0-0.2-0.1s-0.2-0.2-0.5-0.2c-0.4,0-0.5,0.1-0.6,0.5s-0.1,0.5,0.3,0.4c0.3-0.1,0.4-0.1,0.6,0.1
				c0.1,0.2,0.2,0.2,0.2-0.1c0.1-0.3,0.1-0.3,0.4-0.1s0.2,0.3-0.1,0.7c-0.3,0.3-0.4,0.4-0.2,0.7c0.2,0.2,0.2,0.2-0.2,0.1
				c-0.6-0.3-0.6,0.2,0,0.7c0.3,0.3,0.3,0.3,0.1,0.2c-0.4-0.2-0.5-0.2-0.3,0.3c0.1,0.3,0.1,0.3-0.4,0.1c-0.3-0.1-0.5-0.2-0.4-0.3
				c0.2-0.1-0.2-0.5-0.5-0.4c-0.1,0-0.1,0.2,0.1,0.4c0.1,0.2,0.1,0.4,0,0.5s-0.1,0.5,0,0.7c0.3,0.5,0,0.9-0.7,0.9
				c-0.4,0-1.4,0.7-1.5,1c-0.1,0.2,0.8,0.4,1.2,0.3c0.3-0.1,0.5-0.1,0.6-0.1c0.2,0.2-0.1,1.1-0.4,1.5s-0.5,0.4-1.3,0.1
				c-0.7-0.2-0.9-0.2-1,0c0,0.1,0.1,0.3,0.3,0.3c0.2,0,0.4,0.2,0.5,0.3s0,0.2-0.1,0.1c-0.3-0.2-1.3,0.1-1.4,0.4
				c0,0.2,0.1,0.4,0.5,0.5c0.7,0.3,0.7,0.6,0,0.4c-0.3-0.1-0.5,0-0.5,0.2s0.1,0.4,0.4,0.4c0.3,0.1,0.6,0.3,0.8,0.5
				c0.3,0.3,0.3,0.4-0.1,0.8c-0.2,0.2-0.4,0.5-0.5,0.6c0,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.5-0.5,0.8c-0.1,0.4-0.3,0.7-0.6,0.8
				c-0.2,0-0.4,0.2-0.4,0.3s-0.2,0.2-0.3,0.2c-0.4-0.1-0.5,1-0.2,1.4c0.2,0.2,0.2,0.3,0,0.4c-0.4,0.2-0.5,0.7-0.1,0.8
				c0.2,0.1,0.2,0.1,0,0.1s-0.3,0.1-0.3,0.2c0,0.1-0.4,0.2-1,0.1c-0.5-0.1-1-0.1-1,0c0,0.2,0.7,0.9,1.3,1.3l0.6,0.4l-0.7-0.2
				c-0.6-0.2-0.7-0.1-0.9,0.2c-0.1,0.2,0,0.4,0.2,0.4c0.5,0.1,0,0.8-0.9,1c-0.4,0.1-0.7,0.3-0.6,0.4c0.1,0.1,0,0.2-0.1,0.1
				c-0.1,0-0.3,0.1-0.3,0.2c0,0.2,0.1,0.3,0.4,0.2c0.4,0,0.4,0-0.1,0.2c-0.3,0.1-0.5,0.3-0.5,0.4c0,0.1,0,0.2-0.1,0.3
				c0,0.1-0.1,0.2-0.2,0.2c-0.3,0.4-0.7,0.7-1.1,0.8s-0.4,0.2-0.3,0.4s0.1,0.3,0,0.2c-0.1,0-0.4,0.2-0.7,0.5c-0.6,0.8-0.7,0.8-1,0.5
				c-0.2-0.3-0.2-0.2-0.3,0.5c-0.1,0.5-0.1,0.9,0,1s-0.3,0.2-0.8,0.3c-0.8,0.1-1.3,0.5-0.7,0.6c0.4,0.1-0.2,0.7-0.8,0.9
				c-0.5,0.2-0.6,0.3-0.7,0.9c-0.1,0.7-0.1,0.7-0.8,0.8c-0.5,0-0.8,0.1-0.8,0.3s-0.3,0.3-0.5,0.3c-0.5,0-1.1,1-0.7,1.2
				c0.1,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.3,0.2-0.1,0.5c0.2,0.3,0.2,0.3-0.1,0.2c-0.5-0.1-1.4,0.6-1.1,0.8c0.4,0.3-0.3,0.8-0.8,0.6
				c-0.3-0.2-0.9,0.2-0.8,0.5s-1.2,0.6-1.4,0.4c-0.1-0.1-0.2,0-0.2,0.2s-0.1,0.5-0.2,0.6s-0.2,0.3-0.2,0.4c0,0.1-0.1,0.1-0.2,0.1
				s-0.3,0.1-0.3,0.2c0,0.1-0.3,0.3-0.6,0.4c-0.4,0.1-0.6,0.3-0.7,0.6c-0.1,0.3-0.2,0.5-0.3,0.4c-0.2-0.1-0.3,0.1-0.3,0.6
				c0,0.2,0,0.2-0.2,0c-0.2-0.3-0.7-0.3-0.8,0.1c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.2,0,0.1,0.1c-0.1,0.1-0.3,0.2-0.4,0.1
				c-0.1-0.1-0.3,0-0.3,0.1s-0.2,0.2-0.4,0.2s-0.3,0-0.3,0.1c0.1,0.1,0,0.2-0.2,0.1c-0.1,0-0.2,0-0.1,0.2c0.1,0.1,0,0.2-0.1,0.1
				c-0.2-0.2-0.6,0-0.8,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3,0.2-0.3,0.4c-0.1,0.3-0.1,0.3-0.2,0.1c-0.1-0.2-0.3-0.3-0.6-0.3
				c-0.2,0-0.4,0.1-0.3,0.2c0.1,0.1-0.2,0.4-0.6,0.6c-0.4,0.2-0.7,0.6-0.7,0.7s0,0.3-0.1,0.2c-0.1,0-0.2,0-0.3,0.1
				c0,0.1,0.1,0.2,0.2,0.1c0.6-0.1,0.1,0.3-0.6,0.5c-0.4,0.1-0.9,0.3-1,0.5c-0.1,0.1-0.3,0.2-0.4,0.1c-0.1-0.1-0.2-0.1-0.1,0.1
				c0.1,0.2-0.1,0.3-0.5,0.4c-0.3,0-0.7,0.2-0.8,0.3s-0.7,0.6-1.3,0.9c-2,1.2-2.1,1.3-2.1,1.4c0,0.1-0.1,0.1-0.3,0s-0.3-0.1-0.3,0.1
				c0,0.1,0,0.2,0,0.1c-0.3-0.5-1.5,0.3-1.6,1c-0.1,0.4-0.2,0.5-0.5,0.5c-0.2-0.1-0.6,0-0.7,0.2c-0.2,0.2-0.2,0.2-0.4-0.1
				c-0.1-0.4-0.1-0.4-0.2,0c-0.1,0.2-0.3,0.4-0.6,0.4c-0.6,0-1.5,0.4-1.4,0.5s0,0.1-0.2,0c-0.2,0-0.5,0-0.6,0.1s-0.5,0.2-0.9,0.1
				c-0.5-0.1-0.7-0.1-0.8,0.1c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.4,0.2c0.1,0.1-0.1,0.1-0.5,0.1c-0.5-0.1-0.6,0-0.6,0.3
				c0,0.2,0,0.5,0.1,0.6s0,0.2-0.1,0.2s-0.5,0.1-1,0.2c-0.4,0.1-0.9,0.3-1,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.4,0-0.5,0
				s-0.3,0.1-0.3,0.2c0,0.1-0.4,0.2-0.8,0.3c-0.6,0.1-0.7,0.1-0.5,0.3c0.2,0.1,0.1,0.2-0.3,0.1c-0.6-0.1-0.7-0.1-1,0.4
				c-0.3,0.4-2.8,1.1-2.9,0.9c-0.1-0.1-0.1,0-0.1,0.2s-0.2,0.3-0.5,0.4c-0.7,0.1-2,0.7-2.1,0.9c0,0.1-0.3,0.2-0.7,0.3
				s-0.8,0.3-0.9,0.5c-0.2,0.2-0.6,0.3-1,0.3s-0.8,0-0.9,0.1c-0.2,0.1-0.3,0.1-0.4-0.1c-0.2-0.3-1.3-0.3-1.2,0
				c0.1,0.1,0,0.2-0.1,0.2c-0.8-0.1-1.4-0.1-1.6,0.1c-0.1,0.1-0.4,0.2-0.5,0.1c-0.2,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.7,0.3-0.8,0.2
				c-0.4-0.1-0.7-0.1-0.8,0.1c-0.1,0.2-1.9,0.9-2.5,0.9c-0.2,0-0.4,0.1-0.5,0.2c0,0.1-0.2,0.1-0.5,0.1c-0.3-0.1-0.6,0-0.7,0.1
				c-0.1,0.1-0.3,0.2-0.5,0.1c-0.3-0.1-2,0.5-2,0.8c0,0.1-0.1,0.1-0.2,0.1c-0.1-0.1-0.5,0-1.1,0.2c-0.5,0.2-1,0.2-1.1,0.2
				c-0.1-0.1-0.5-0.2-0.9-0.4c-0.5-0.2-0.8-0.2-1,0c-0.4,0.3-0.4,0.3-0.7-0.2c-0.4-0.5-0.9-0.5-1,0c-0.1,0.3-0.1,0.3-0.4,0.1
				c-0.6-0.5-1.2-0.2-1.3,0.6c0,0.3-0.3,0.3-0.7-0.1c-0.2-0.2-0.3-0.2-0.5,0.1s-0.3,0.3-0.6,0.1c-0.2-0.2-0.3-0.2-0.2-0.1
				c0.3,0.4-0.4,0.7-1,0.5c-0.5-0.2-0.5-0.1-0.5,0.1c0.1,0.3,0.1,0.3-0.3-0.1c-0.2-0.2-0.5-0.4-0.6-0.4c-0.3,0-0.3,0.1-0.2,0.6
				c0.1,0.2,0,0.3-0.2,0.3c-0.1,0-0.2-0.2-0.2-0.3c0-0.2,0-0.2-0.3-0.1c-0.2,0.1-0.5,0.1-0.5,0c-0.2-0.2-1.6,0.2-1.7,0.5
				c0,0.2-0.1,0.2-0.3-0.2c-0.3-0.5-0.7-0.7-0.8-0.3c0,0.1,0.1,0.3,0.2,0.3c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.2,0.1-0.4,0
				s-0.4-0.1-0.5,0s-0.1,0.1,0.1,0.1c0.2,0,0.3,0,0.1,0.1c-0.3,0.2-0.7,0.1-0.6-0.1c0-0.1-0.1-0.4-0.2-0.6c-0.2-0.3-0.3-0.3-0.8-0.2
				c-0.3,0.1-0.7,0.3-0.8,0.5c-0.3,0.6-0.5,0.6-1.5,0.4c-0.7-0.2-0.9-0.1-0.8,0.1c0.2,0.2-0.1,0.2-1,0c-0.7-0.1-1.3-0.4-1.5-0.5
				s-0.4-0.1-0.6,0c-0.2,0.1-0.5,0.2-0.8,0s-0.4-0.1-0.4,0.3c0,0.5-0.3,0.8-0.5,0.4c-0.1-0.1,0-0.2,0.2-0.1c0.2,0,0.2,0,0.2-0.1
				c-0.1-0.1-0.6-0.1-1.1,0s-1.4,0-2-0.1c-0.5-0.1-1-0.1-1-0.1c0,0.1-0.2,0-0.3-0.2c-0.2-0.4-0.4-0.3-0.4,0c0,0.1-0.2,0.1-0.5,0
				c-0.5-0.1-1.1-0.2-2-0.2c-0.3,0-0.6-0.1-0.7-0.3c-0.4-0.4-1-0.2-1.1,0.2c-0.1,0.3-0.2,0.4-0.4,0.3c-0.2,0-0.7-0.1-1,0
				c-0.4,0-0.9,0-1.1-0.1c-0.3-0.1-0.5,0-0.6,0.2c-0.2,0.3-0.6,0.4-0.6,0c0-0.1-0.2-0.1-0.4,0.1c-0.3,0.2-0.5,0.2-0.7,0
				s-0.3-0.2-0.4-0.1c-0.1,0.1-0.9,0.1-1.9,0c-1.4-0.1-1.7-0.2-1.5-0.3c0.3-0.2,0.2-0.7-0.1-0.8c-0.1,0-0.3,0.1-0.3,0.4
				c-0.1,0.3-0.2,0.4-0.4,0.3c-0.2-0.1-0.3-0.1-0.2,0c0.1,0.2-1.7,0.1-2.4-0.1c-0.2-0.1-0.4,0-0.3,0c0.1,0.1-0.5,0-1.3-0.1
				c-1.2-0.3-1.3-0.3-1.1-0.5c0.3-0.3,0.3-1.2,0-1.2c-0.1,0-0.2,0.1-0.3,0.3c0,0.2-0.2,0.3-0.3,0.3s-0.3,0.1-0.3,0.3
				c-0.1,0.4-0.5,0.5-0.7,0.2c-0.1-0.1-0.3-0.2-0.5-0.2c-0.3,0-0.4,0-0.2-0.2c0.1-0.2,0.1-0.2-0.2-0.2s-0.3-0.1-0.3-0.4
				c0.1-0.5-0.2-0.7-1.1-0.7c-0.4,0-0.6-0.1-0.7-0.2c-0.1-0.1-0.2-0.2-0.2-0.1c0,0.1-0.2,0-0.3-0.1c-0.2-0.2-0.4-0.2-0.7-0.1
				c-0.4,0.2-0.4,0.1-0.5-0.3c0-0.2-0.2-0.5-0.4-0.5c-0.3-0.1-0.3,0-0.1,0.2c0.3,0.4-0.2,0.5-0.6,0.2c-0.2-0.2-0.3-0.2-0.5-0.1
				c-0.2,0.1-0.5,0.2-0.7,0.1c-0.5-0.1-1.8-0.6-1.8-0.7c0-0.1,0.1-0.3,0.1-0.7c0.1-0.5,0-0.6-0.6-0.6c-0.5-0.1-0.7,0-0.6,0.3
				c0,0.2,0.1,0.4,0.2,0.5c0.3,0.2-0.9,0.3-1.3,0.1c-0.2-0.1-0.4,0-0.5,0.2s-0.3,0.3-0.4,0.2c-0.1-0.1-0.5-0.1-0.9,0
				c-1.6,0.4-3.1-0.1-2.4-0.8c0.4-0.3,0.2-0.5-0.4-0.6c-0.6-0.1-0.8,0.1-0.5,0.5c0.3,0.3,0.2,0.3-0.2,0.5c-0.6,0.2-0.9,0.1-0.8-0.4
				c0-0.1-0.1-0.3-0.3-0.4c-0.2-0.1-0.3,0-0.3,0.3c0.1,0.5-0.4,0.7-0.5,0.3c-0.1-0.2-0.1-0.2-0.1,0c-0.1,0.3-0.9,0.1-1-0.2
				c0-0.2-0.2-0.2-0.6-0.1c-0.3,0-0.7,0-1,0c-0.4-0.1-0.4-0.1,0.1-0.1s0.5,0,0-0.2c-0.3-0.1-0.8-0.1-1.1,0l0,0
				c-0.9,0.1-3.7-0.4-3.8-0.8c0-0.1-0.3-0.3-0.6-0.4c-0.4-0.1-0.5-0.1-0.2-0.2c0.5-0.2,0.4-0.4-0.2-0.3c-0.3,0-0.6,0.1-0.5,0.2
				c0,0.1-0.2,0.1-0.5,0c-0.3,0-0.6-0.2-0.5-0.3c0-0.1-0.1-0.2-0.4-0.2c-0.2,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.4-0.2-0.1-0.4
				c0.2-0.1,0.2-0.2,0.1-0.3c-0.3-0.2-0.7-0.1-0.8,0.2c0,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0-0.3
				s-0.2,0-0.3,0.1c-0.1,0.4-1.8,0-3.5-0.7c-0.8-0.3-1.6-0.6-1.7-0.6s-0.4-0.2-0.5-0.4c-0.2-0.3-0.3-0.4-0.5-0.3
				c-0.3,0.2-0.7,0.2-1.4-0.2c-0.3-0.2-0.8-0.4-1.1-0.4c-0.4-0.1-0.7-0.3-0.9-0.5c-0.2-0.2-0.3-0.3-0.3-0.2c0.1,0.1,0,0.3-0.2,0.3
				c-0.4,0.1-1.8-0.2-2-0.4c-0.1-0.1-0.4-0.1-0.7-0.2c-0.4,0-0.6-0.1-0.5-0.3c0-0.2,0-0.3-0.2-0.1s-1.5-0.2-1.4-0.4
				c0.2-0.4,0-0.5-1.1-0.7c-0.6-0.1-1.2-0.3-1.4-0.5c-0.2-0.1-0.4-0.3-0.4-0.3c-0.1,0-0.1-0.1,0-0.2c0.2-0.3-0.4-0.6-0.9-0.4
				c-0.4,0.2-0.5,0.1-0.6-0.1c-0.1-0.2-0.1-0.3,0.1-0.3c0.1,0,0.3-0.2,0.3-0.5c0-0.6-0.9-0.9-1.3-0.4c-0.2,0.2-0.5,0.4-0.6,0.3
				c-0.2,0-0.5-0.1-0.8-0.1c-0.6-0.1-1.8-0.9-1.8-1.2c0-0.1-0.2-0.2-0.6-0.2c-0.3,0-0.7-0.1-0.7-0.2c-0.1-0.1-0.4-0.2-0.7-0.3
				c-0.5-0.1-0.6-0.2-0.1-0.1c0.6,0,0.9-0.4,0.5-0.7c-0.1-0.1-0.2,0-0.3,0.2c-0.1,0.3-0.2,0.3-1.1,0.1s-1-0.3-1-0.5s0.3-0.3,0.6-0.4
				c0.7-0.1,0.7-0.3,0.1-0.7c-0.4-0.2-0.5-0.3-0.9-0.1s-0.4,0.2-0.1,0.4c0.2,0.1,0.2,0.3,0.1,0.3s-0.4,0-0.6-0.2
				c-0.3-0.2-0.5-0.3-0.7,0c-0.2,0.2-0.2,0.3,0,0.3s0.2,0.1,0.2,0.1c-0.3,0.1-1.1-0.4-1.1-0.6c0-0.1-0.1-0.3-0.2-0.3
				s-0.2-0.2-0.1-0.4s0.1-0.3-0.2-0.4c-0.2-0.1-0.3,0-0.3,0.3c0,0.2,0,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.2-0.3c0.2-0.2-0.3-0.5-0.8-0.5
				c-0.2,0-0.6-0.2-0.9-0.6c-0.3-0.3-0.5-0.5-0.5-0.5c0,0.1-0.4-0.1-0.8-0.4c-0.4-0.3-0.9-0.5-1.1-0.5s-0.4-0.1-0.3-0.2
				c0.1-0.3-0.4-0.6-1.1-0.7c-0.2-0.1-0.6-0.2-0.7-0.4c-0.2-0.2-0.6-0.4-1-0.5c-0.9-0.3-1.2-0.5-1.1-0.9c0-0.2,0-0.3-0.2-0.4
				c-0.1,0-0.3,0-0.3,0.1s-0.1,0.1-0.2,0s-0.1-0.2,0-0.4c0.1-0.1,0-0.2-0.3-0.3s-0.5,0-0.5,0.1s-0.1,0-0.2-0.1
				c-0.1-0.2-0.3-0.3-0.4-0.3c-0.3,0.1-1.2-0.5-1.8-1.1c-0.2-0.2-0.5-0.4-0.6-0.4s-0.3-0.2-0.4-0.4s-0.5-0.4-0.8-0.5
				s-0.6-0.3-0.6-0.4c0-0.1-0.2-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.4-0.3s-0.2-0.2-0.4-0.2c-0.2,0-0.6-0.2-0.8-0.3
				c-0.2-0.2-0.6-0.4-0.8-0.4c-0.2,0-0.4-0.2-0.5-0.4c0-0.2-0.2-0.3-0.3-0.2c-0.1,0-0.2,0-0.2-0.1s-0.3-0.3-0.8-0.6
				c-0.7-0.4-0.8-0.5-0.5-0.9c0.3-0.5,0.2-0.7-0.7-1c-0.4-0.1-0.7-0.1-0.8,0c-0.3,0.3-0.6,0.2-0.7-0.3c0-0.2-0.2-0.3-0.3-0.3
				c-0.1,0-0.3-0.1-0.3-0.2c0-0.2-0.2-0.3-0.4-0.3c-0.6-0.1-0.8-0.2-1.2-0.6c-0.2-0.2-0.6-0.4-0.9-0.5s-0.6-0.2-0.6-0.4
				c0-0.1,0.1-0.1,0.3,0c0.3,0.1,0.4,0.1,0.4-0.1c0-0.2-0.2-0.4-0.4-0.4c-0.2-0.1-0.4-0.1-0.3,0c0,0.1-0.2,0.1-0.5,0
				c-0.4-0.1-0.6-0.3-0.6-0.6c0-0.2-0.2-0.6-0.4-0.8s-0.3-0.5-0.3-0.7s-0.1-0.3-0.7-0.3c-0.4,0-0.8-0.2-0.8-0.2s-0.3-0.2-0.5-0.3
				c-0.2,0-0.5-0.3-0.6-0.6s-0.3-0.5-0.6-0.4c-0.2,0-0.4,0-0.3-0.1c0-0.1,0.1-0.2,0.1-0.4s0-0.2-0.3-0.1c-0.2,0.2-0.3,0.1-0.1-0.2
				c0.1-0.2,0.1-0.3,0-0.3s-0.3-0.2-0.3-0.4c-0.1-0.4-0.4-0.6-1-0.7c-0.1,0-0.1-0.3,0-0.6c0.1-0.4,0.1-0.6-0.1-0.6
				c-0.2,0-0.4,0-0.5,0.1s-0.2,0.1-0.5-0.2s-0.4-0.3-0.2,0c0.1,0.3,0.1,0.2-0.2,0c-0.2-0.2-0.3-0.5-0.3-0.7s0-0.4-0.1-0.4
				s-0.2,0-0.3,0.1c0,0.1-0.2,0.1-0.5,0s-0.4-0.2-0.4-0.6c0.1-0.3,0.2-0.4,0.3-0.3c0.2,0.2,1.2,0,1.3-0.2c0-0.2-1.1-0.5-1.4-0.3
				c-0.4,0.2-1,0.1-0.9-0.1c0-0.1,0-0.2-0.1-0.2c-0.5-0.1-0.8-0.2-0.7-0.5c0-0.2-0.2-0.4-0.4-0.6c-0.3-0.2-0.4-0.4-0.4-0.5
				c0.1-0.2,0-0.2-0.3-0.1c-0.4,0.1-0.4,0.1-0.2-0.1c0.1-0.1,0.1-0.3,0-0.3s-0.2-0.1-0.2-0.2c0-0.1,0.2-0.1,0.4,0s0.3,0.1,0.3-0.2
				c-0.1-0.5-0.6-0.6-0.8-0.2c-0.1,0.2-0.2,0.1-0.2-0.2s-0.2-0.5-0.4-0.6c-0.2,0-0.3-0.1-0.3-0.2c0-0.1-0.1-0.2-0.2-0.1
				c-0.1,0-0.2,0-0.2-0.1s-0.2-0.5-0.6-0.8c-0.4-0.4-0.5-0.6-0.3-0.7c0.4-0.1,0.4-0.4,0-0.4c-0.2,0-0.3-0.1-0.3-0.2
				c0-0.1-0.1-0.2-0.3-0.2c-0.3-0.1-0.3-0.2-0.1-0.4s0.2-0.3,0-0.2c-0.4,0.3-1-0.1-0.9-0.6c0-0.2,0-0.5-0.1-0.6
				c-0.4-0.3-0.2-0.5,0.3-0.2c0.3,0.2,0.4,0.2,0.6-0.2c0.3-0.6-0.1-0.9-1.8-1.3c-0.4-0.1-0.6-0.2-0.6-0.3s-0.1-0.2-0.3-0.2
				s-0.3-0.2-0.3-0.3s-0.1-0.3-0.3-0.3s-0.3-0.2-0.3-0.5c0.1-0.5,0-0.7-0.8-1.4c-0.4-0.3-0.8-0.5-1.1-0.5s-0.4-0.1-0.4-0.2
				c0-0.1-0.1-0.2-0.2-0.1c-0.1,0-0.2,0-0.2-0.1s-0.2-0.3-0.4-0.4c-0.3-0.2-0.4-0.3-0.2-0.6s0.2-0.4-0.4-0.5
				c-0.5-0.1-0.6-0.2-0.3-0.3c0.4-0.2,0.5-0.4,0.1-0.5s-0.8-0.8-0.6-1c0.1-0.1,0-0.1-0.3-0.2c-0.2,0-0.4-0.2-0.3-0.3
				c0.1-0.1-0.1-0.2-0.2-0.3c-0.2,0-0.3-0.2-0.3-0.4s-0.1-0.5-0.2-0.7c-0.4-0.6-0.3-0.9,0.3-0.6c0.4,0.2,0.4,0.2,0.2,0
				s-0.2-0.4,0-0.7c0.2-0.4,0.2-0.5-0.2-0.6c-0.6-0.1-0.4-0.5,0.3-0.4c0.3,0,0.5,0,0.6-0.1c0-0.2-1.8-0.8-2-0.7
				c-0.1,0-0.3,0-0.4-0.2c-0.2-0.2-0.2-0.3,0.1-0.7c0.2-0.2,0.3-0.5,0.2-0.6s-0.2,0-0.3,0.2c-0.3,0.5-0.9,0.3-0.8-0.2
				c0.1-0.2,0-0.4-0.1-0.5c-0.1,0-0.2-0.2-0.2-0.3c0.1-0.5-0.3-1.2-0.6-1.1c-0.2,0-0.3-0.1-0.1-0.2c0.1-0.2,0.1-0.3-0.2-0.3
				c-0.2,0-0.4-0.4-0.5-0.8c-0.1-0.4-0.3-0.7-0.4-0.7c-0.1,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.3-0.4,0.1-0.7c0.5-0.4,0.4-0.8-0.1-0.8
				c-0.2,0-0.4-0.1-0.4-0.3s-0.2-0.3-0.3-0.3c-0.2,0-0.1-0.1,0.1-0.2c0.2-0.1,0.3-0.2,0.1-0.2s-0.3,0-0.3-0.2c0-0.1,0-0.3-0.2-0.3
				c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.3-0.2-0.9-0.4-1c-0.2,0-0.2-0.2-0.1-0.3c0.1-0.1,0-0.4-0.2-0.5c-0.2-0.2-0.4-0.4-0.4-0.6
				c0-0.2-0.1-0.3-0.3-0.4c-0.2,0-0.2-0.3-0.1-0.6c0.1-0.4,0-0.6-0.2-0.5c-0.2,0-0.2,0,0-0.3c0.2-0.2,0.1-0.5-0.3-0.9
				c-0.3-0.3-0.5-0.5-0.3-0.5s0.2-0.1,0-0.5c-0.1-0.3-0.2-0.7-0.2-0.8c0-0.2,0-0.3-0.2-0.3c-0.1,0-0.2-0.3-0.2-0.7
				c0-0.4-0.2-1.2-0.5-1.8c-0.3-0.6-0.4-1.1-0.3-1.2s0,0-0.3,0c-0.4,0.1-0.4,0.1-0.3-0.1c0.2-0.3,0-0.6-0.6-0.9
				c-0.3-0.2-0.5-0.4-0.4-0.6c0-0.2,0-0.4-0.2-0.5c-0.2-0.2-0.1-0.3,0.1-0.4c0.3-0.2,0.3-0.2,0-0.3c-0.2,0-0.4-0.2-0.3-0.4
				c0-0.2,0-0.3-0.2-0.3c-0.1,0-0.1-0.1,0.1-0.3c0.2-0.2,0.5-0.3,0.7-0.3c0.3,0,0.3,0,0-0.1s-0.3-0.3-0.2-0.7
				c0.1-0.3,0.3-0.7,0.4-0.8c0.1-0.1,0.1-0.3-0.1-0.4c-0.2-0.2-0.2-0.4-0.1-0.5c0.1-0.2,0-0.3-0.2-0.3s-0.4-0.2-0.3-0.3
				c0-0.1-0.1-0.5-0.2-0.7c-0.1-0.3-0.1-0.7,0-0.9c0.2-0.4-0.4-1.3-1-1.5c-0.3-0.1-0.3-0.1,0-0.2s0.3-0.1,0-0.5
				c-0.2-0.3-0.3-0.4-0.1-0.3s0.3,0.1,0.4,0s0.1-0.2-0.1-0.2s-0.2-0.1,0-0.2s0.2-0.2-0.1-0.4c-0.3-0.1-0.4-0.1-0.4,0.1
				c0,0.1,0,0.2-0.1,0.2c-0.3-0.1-0.2-0.7,0.1-0.8c0.4-0.1,0.3-0.3-0.1-0.5c-0.5-0.2-0.3-1.5,0.2-1.8c0.2-0.1,0.2-0.2,0.1-0.3
				c-0.2-0.2-0.2-0.5-0.1-1.1c0.4-1.4,0.5-2.3,0.4-3.4c0-0.2,0.1-0.4,0.2-0.3c0.2,0,0.2,0,0-0.1c-0.1-0.1-0.3-0.4-0.3-0.7
				c0-0.3-0.2-0.6-0.3-0.6c-0.2,0-0.1-0.1,0.1-0.1c0.3-0.1,0.3-0.1,0.1-0.4c-0.1-0.2-0.2-0.3-0.1-0.3s0.1-0.1,0-0.2
				c-0.1-0.2-0.1-0.3,0.1-0.4s0.2-0.2,0-0.4c-0.2-0.2-0.2-0.4-0.1-0.6s0-0.3-0.1-0.3c-0.2,0-0.2-0.2-0.1-0.3
				c0.1-0.1,0.1-0.3-0.1-0.3c-0.1-0.1-0.2-0.3-0.2-0.6c0-0.4,0.1-0.4,0.5-0.4c0.6,0.1,0.6-0.2-0.1-0.6c-0.3-0.2-0.5-0.4-0.3-0.3
				c0.3,0.1,0.5-0.9,0.2-1c-0.1,0-0.1-0.1,0.1-0.3c0.2-0.1,0.3-0.6,0.4-1s0.2-0.8,0.3-0.8c0.1,0,0.2-0.1,0.3-0.2
				c0-0.1,0.2-0.2,0.4-0.2c0.2,0,0.4,0,0.4-0.1S28,94,27.8,94c-0.4-0.1-0.4-0.5,0-0.5c0.1,0,0.1-0.2-0.1-0.5
				c-0.3-0.5-0.2-1.3,0.3-1.6c0.2-0.1,0.2-0.2,0.1-0.2s0-0.1,0.1-0.2c0.2-0.1,0.2-0.2,0-0.5c-0.2-0.2-0.2-0.3,0-0.5
				c0.2-0.1,0.3-0.3,0.2-0.4c0-0.2,0-0.3,0.2-0.3s0.2-0.2,0-0.3c-0.6,0-0.5,0-0.3,0.2c0.2,0.1,0.4,0.1,0.5,0s0-0.2-0.3-0.2
				c-0.3-0.1-0.5-0.2-0.4-0.3c0-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2-0.2c0-0.1-0.2-0.2-0.4-0.2c-0.3,0-0.3,0,0-0.2
				c0.2-0.1,0.3-0.3,0.2-0.7c-0.1-0.3-0.1-0.6,0-0.6s0.1-0.2-0.1-0.4c-0.2-0.2-0.1-0.3,0.1-0.4c0.3-0.1,0.3-0.1-0.1-0.2
				s-0.4-0.1-0.1-0.2c0.2,0,0.3-0.1,0.1-0.2c-0.3-0.2,0.2-1.1,0.6-1.2c0.2,0,0.4-0.2,0.4-0.3c0-0.1-0.1-0.2-0.2-0.2
				c-0.5,0.1-0.5-0.4,0.1-0.6c0.5-0.2,0.5-0.2,0.4-0.7s-0.1-0.6,0.4-0.8c0.5-0.3,0.6-0.4,1-1.2c0.1-0.2,0.4-0.5,0.8-0.6
				c0.6-0.3,0.8-0.7,0.4-0.9c-0.3-0.1-0.1-1.3,0.4-2.1c0.3-0.5,0.6-1.4,0.8-2.1c0.1-0.4,0.2-0.5,0.4-0.4s0.5,0.1,0.7,0
				c0.3-0.2,0.3-0.2,0-0.3c-0.2,0-0.4-0.2-0.3-0.3c0-0.1,0.2-0.2,0.4-0.1s0.3,0.1,0.4,0c0-0.1-0.1-0.2-0.3-0.3
				c-0.4-0.1-0.4-0.4,0-0.4c0.2,0,0.2-0.2,0.2-0.3c0-0.1,0.1-0.4,0.3-0.5c0.3-0.2,0.3-0.3,0.1-0.5s0-0.4,0.5-0.7
				c0.4-0.2,0.8-0.6,0.9-0.8c0.3-0.9,0.8-1.8,1-1.9s0.2-0.3,0.1-0.5c-0.1-0.3-0.1-0.4,0.1-0.2c0.2,0.1,0.4,0,0.5-0.2
				s0.1-0.3-0.2-0.4c-0.2-0.1-0.3-0.1-0.1-0.1s0.3-0.2,0.4-0.4c0.1-0.3,0.1-0.3,0.4-0.1c0.3,0.3,0.8,0.3,0.9-0.1
				c0-0.1-0.1-0.2-0.3-0.2c-0.3,0-0.4-0.1-0.3-0.3c0-0.2,0.2-0.3,0.3-0.2c0.3,0.2,0.8-0.3,0.8-0.8c0-0.3,0.1-0.4,0.3-0.3
				c0.2,0,0.5,0,0.7-0.1c0.3-0.2,0.3-0.2,0-0.2c-0.2,0-0.1,0,0.2-0.1c0.5-0.1,0.6-0.3,0.5-0.6c0-0.2,0-0.4,0.2-0.3
				c0.1,0,0.3-0.2,0.3-0.6c0-0.4,0.2-0.8,0.5-0.9c0.2-0.1,0.3-0.2,0.1-0.2c-0.4-0.1-0.1-0.6,0.5-0.9c0.4-0.2,0.6-0.4,0.6-0.7
				s0.1-0.4,0.5-0.3c0.4,0.1,0.5,0.1,0.3-0.2c-0.2-0.2-0.2-0.3,0-0.3c0.1,0,0.2-0.1,0.2-0.2c0-0.2,0.1-0.3,0.3-0.3s0.4-0.1,0.3-0.2
				c-0.1-0.1,0.3-0.3,0.7-0.5s0.8-0.4,0.7-0.5c0-0.1,0.1-0.3,0.4-0.4c0.2-0.1,0.4-0.3,0.5-0.4c0-0.1,0.3-0.4,0.9-0.8
				c0,0,0.2,0.1,0.4,0.3c0.3,0.3,0.3,0.3,0.3,0c0-0.2,0-0.3,0.1-0.3s0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.3-0.2c-0.3,0-0.3-0.1-0.3-0.4
				c0-0.2,0.2-0.4,0.3-0.4s0.3-0.2,0.4-0.5c0.1-0.3,0.2-0.4,0.2-0.2s0.1,0.3,0.3,0.4c0.1,0,0.2,0,0.2-0.1c0-0.2,0.8-1.2,1.1-1.2
				c0.1,0,0.2-0.1,0.3-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.4,0.1,0.7,0.1c0.4,0,0.5-0.1,0.5-0.3c-0.1-0.4,0.3-0.8,0.5-0.7
				c0.1,0.1,0.2,0.1,0.2,0s-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2,0-0.4c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.2,0,0.1-0.1
				c0-0.2,0-0.3,0.1-0.3s0.3-0.2,0.5-0.5c0.2-0.4,0.3-0.4,0.5-0.2S56,50,56,49.7s0.1-0.4,0.5-0.5c0.3-0.1,0.5-0.2,0.4-0.3
				c-0.1-0.2,0.8-0.6,1.3-0.5c0.4,0.1,0.8-0.4,0.7-0.9c0-0.2,0.1-0.4,0.3-0.4s0.5-0.2,0.7-0.4c0.2-0.3,0.3-0.3,0.3-0.1
				c-0.1,0.3,0.4,0.5,0.5,0.2c0-0.1,0.4-0.4,0.7-0.6c0.4-0.2,0.6-0.4,0.6-0.5c-0.1-0.1,0-0.1,0.2-0.1c0.1,0,0.2,0,0.2-0.1
				c-0.1-0.1,0-0.2,0.2-0.1c0.3,0.1,1.4-0.6,1.6-1c0.1-0.1,0.2-0.2,0.4-0.3c0.2,0,0.3-0.1,0.2-0.3s0.1-0.3,0.3-0.3
				c0.2-0.1,0.4-0.1,0.4,0s0.2,0.1,0.4,0c0.3-0.1,0.3-0.2,0.2-0.3C65.5,43,65.6,43,65.9,43s0.5-0.1,0.6-0.2c0-0.1,0.2-0.1,0.4-0.1
				c0.2,0,0.3,0,0.3-0.2s0-0.3,0.2-0.2c0.1,0,0.4-0.1,0.6-0.3s0.6-0.3,0.9-0.3s0.6-0.1,0.7-0.3s0.3-0.3,0.5-0.3s0.3,0,0.3-0.2
				c0-0.1,0.3-0.4,0.9-0.6c0.5-0.2,0.9-0.5,0.8-0.5c-0.1-0.1,0-0.1,0.1-0.1s0.3,0,0.3-0.2c0-0.1,0.5-0.4,1-0.6h0.1
				c0.2,0.1,0.4,0.1,0.6,0.2c0.5-0.1,1-0.2,1.4,0c0.3,0.1,0.6,0.2,0.9,0.4c0.8-0.4,0.9-0.2,1.6-0.1c-0.1,0.1,0,0.3,0.1,0.6
				c0.7,1.1,1.3,0.2,0.9-0.1c-0.3-0.3-0.6-0.4-0.8-0.5c0.6-0.1,0.5-0.7,0.8-1.1c0.2,0.1,0.4,0.1,0.4,0.1c0.1-0.1,0.4-0.6,0.2-0.7
				c0.5-0.4,0.3-0.1,0.9-0.5c0.1,0.2,0.9,0.9,1.1,1.1c-0.3,0.4-0.1,1.1,0.4,0.8c0.6-0.2,0.3-0.9-0.1-1c-0.2-0.3-0.5-0.6-0.9-0.8
				c0,0,0.1,0,0.2-0.1c0.3-0.2,1-0.2,0.8-0.5s-0.8,0.1-1.1,0.2c-0.1,0-0.2,0.1-0.2,0.2h-0.1c1-0.8,1.1-1.5,1.5-2.4
				c0.1,0.1,0.1,0.2,0.2,0.4c-0.2,0.1-0.1,0.4,0.3,0.6c0,0.1,0,0.2,0,0.3c-0.1,0.2,0.3,0.4,1.1,0.1c2.5-1,1.3-0.2,2.8-0.1
				c0.4,0,0.3-0.3,0-0.3c-0.7,0-0.9-0.3-0.6-0.7c0.2-0.4-0.6-0.6-0.6-0.2c-0.1,0.7-0.9,0.7-1.5,0.6v-0.1c0.2,0.1,0.4,0,0.3,0
				C84,35.1,83.8,35,83.7,35c-0.1-0.1-0.2-0.2-0.4-0.2c0.1-0.5-0.4-1-0.8-1c0.2-0.4,0.4-0.6,0.7-0.7c0.2,0.5,0.7,0.6,1,0.4
				c0.7,0.6,1.2,0.8,1.2-0.2c0.9-0.2,0.3-1-0.1-0.6v-0.1c0.3-0.1,0.2-0.2,0-0.4c0.3-0.1,0.5-0.1,0.5-0.2c0-0.3-1.2-0.6-0.7-1
				c0.2-0.1,0.5,0.1,0.8,0.3c0.1,0.3,0.5,0.4,0.5,0.2c0.2-0.1,0-0.4-0.1-0.5c-0.9-1.4-1.1-0.6-1.3-2.1c0.1,0,0.1-0.1,0.2-0.1
				c0.4-0.1,0.4-0.3,0.2-0.4c0-0.2-0.4-0.3-0.7-0.3c-0.2-0.4-0.2-0.3-0.3-0.4c0.7-0.1,0.6-0.4,0-0.6c1.3-0.7,3.5,0.4,1-0.7
				c0.5,0,0.4-0.3,0.1-0.3c-0.2,0-0.3,0-0.5,0c-0.6-0.4-1-1.1-1-1.5c0.4,0.3,0.9,0.2,0.8-0.1c0.1-0.1,0.2-0.2,0.2-0.4
				c0.1,0,0.1,0,0.2,0c0.4,0.5-0.3,1.7,0.6,1.4c0,0.5,0.6,0.2,0.5-0.3c0.2,0.1,0.6,0.3,0.8,0.2c0.1,0.4,0.1,0.8,0.2,1.2
				c0,0.1,0.4,0.4,0.4,0.2c-0.1-0.5-0.2-1-0.3-1.5c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.2,0.7-0.2,0.6-0.4c-0.2-0.3-0.8-0.2-0.7,0.2
				c0,0-0.1,0-0.3,0c0-0.1-0.1-0.4-0.1-0.5c0.7,0.1,0.6-0.3,0-0.3c0.3-0.1,0.8-0.2,1.3-0.2c1.2,0.1,1.4,0.3,0.8,0.9
				c-0.2-0.1-0.4,0-0.2,0.2c-0.2,0.2-0.3,0.2-0.4,0.4c-0.3-0.1-0.7,0.4-0.3,0.7c0,0.5,0.4,0.8,1.1,0.8c0,0.4,0.1,0.8-0.2,1
				c-0.2,0.1-0.1,0.8,0.3,1.1c0.3,0.2,2.2-0.1,1.1-0.3c-0.2,0-0.6,0-0.6-0.2c-0.2-0.4,1.6-0.8,2-0.5c0.1,0.1,0,0.4-0.1,0.7
				c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.6,0.2,0.7,0.5,0.1c0.1,0,0.2-0.1,0.2-0.3c0.1-0.1,0.3-0.1,0.7,0.1c0.2,0.1,0.4,0,0.2-0.2
				c-0.4-0.3-1.2-0.8-0.9-1.1c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0.5,0.6,0.6,1.2,0.4c0.8,0.4,1.6,0.9,2.6,0.9c0.9,0.3,1.5,0.2,2.4,0.4
				c-0.3,0.2-0.2,0.6,0.1,0.7c0.3,0.1,0.4-0.2,0.5-0.3c0.1,0.1,0.1,0.2,0.2,0.3c-0.5-0.1-0.4,0.2,0,0.5c-0.2,0-0.5,0-0.8,0
				c0,0,0,0,0.1-0.1c0-0.1-0.1-0.2-0.2-0.2s-0.3,0.1-0.3,0.2c0,0,0,0.1,0.1,0.1c-0.3,0.1-0.5,0.1-0.5,0.2c0,0.3,1.7,0.6,1.7,0.5
				s0.1-0.2,0.1-0.4c0,5,2.8,4.2,4.2,3c0.1,0.5,0.8-0.2,0.6-0.6c0.2-0.3,0.4-0.6,0.6-0.9c0.4-0.2,0.8-0.3,0.3-0.6
				c0.1-0.3,0.3-0.6,0.4-0.9h0.1c3,0.1,1.3,0.3,2.8,0.9c0.4,0.1,0.4-0.1,0.2-0.2c-0.6-0.3-0.7-0.5-0.2-0.8c0.4-0.2-0.2-0.6-0.5-0.4
				c-0.6,0.6-1.8,0.1-2.1-0.2c0.3-0.6,0.7-1.1,1-1.4c0,0.1,0.2,0.2,0.4,0.3c-0.6,0.1-0.6,0.4,0.3,0.6c1.2,0.3,0.8-0.4,0.1-0.6
				c0.2,0,0.3-0.1,0.3-0.2c0-0.2-0.6-0.3-0.5-0.4c0-0.2,0.4-0.2,0.6-0.2c0,0,0.7,0.1,1.3,0.2c0.8,0.3,1.3,0.1,1.8-0.1l0.1,0.1
				c0.1,0,0.1-0.1,0.1-0.2c0.6-0.2,1.2-0.4,2.2-0.2c-0.2,0.2-0.5,0.6-0.4,0.8c0.1,0.3,0.8,0.3,0.8,0.1c0-0.3,0-0.6-0.1-0.8
				c1,0.3,1.6,0.6,1.4-0.3c-0.1-0.2-0.1-0.3,0-0.4c-0.4-0.1-0.8-0.2-1.2-0.4c0.4,0,0.7-0.1,0.7-0.2s0.2-0.2,0.5-0.3
				c0.5-0.1,1.3,0.1,1.2,0.4c0,0.1,0.1,0.1,0.2,0.1s0.2-0.1,0.1-0.3c-0.1-0.1,0-0.1,0.3,0c0.2,0.1,0.7,0.1,1,0.1
				c0.3-0.1,0.8-0.1,0.9,0c0.2,0.1,0.3,0.1,0.4-0.1c0.1-0.3,1.2-0.4,1.4-0.1c0.1,0.1,0.2,0.1,0.4,0c0.2-0.1,0.9-0.2,1.5-0.2
				c0.7,0,1.3-0.1,1.3-0.2c0.1-0.1,0.4-0.1,0.6,0s0.5,0,0.6-0.2s0.2-0.2,0.6,0.2c0.5,0.5,0.5,0.5,0.3-0.1c-0.1-0.3,0-0.4,0.1-0.4
				c0.2,0,0.6,0.1,0.9,0.1c0.5,0,0.7,0.1,0.6,0.2c0,0.2,0,0.2,0.2,0c0.1-0.1,0.2-0.3,0.2-0.3c0-0.1,0.5-0.1,1.1,0
				c0.7,0.1,1.2,0.1,1.2,0s0.1-0.1,0.3,0c0.1,0.1,0.5,0.1,0.8,0c0.8-0.2,2.6,0,2.6,0.2c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.2,0,0.1-0.1
				c-0.2-0.2,0.7-0.4,1.7-0.3c0.5,0,0.9,0,0.9-0.1c0.1-0.2,0.7,0,0.8,0.3c0.1,0.4,0.6,0.5,0.5,0c-0.1-0.3,0.1-0.4,0.8-0.4
				c1-0.1,1.5,0,1.2,0.2s0.7,0.3,1.1,0.1c0.2-0.1,0.5-0.1,0.6,0c0.2,0.1,0.4,0,0.5-0.2c0.2-0.3,0.2-0.3,0.4,0c0.1,0.2,0.1,0.4,0,0.4
				c-0.1,0.1,0.1,0.1,0.4-0.1c0.3-0.1,0.7-0.1,0.9,0s0.5,0.1,0.6,0c0.2-0.1,0.3-0.1,0.2,0c-0.2,0.4-0.1,0.8,0.2,0.8
				c0.2,0,0.5,0.2,0.7,0.4c0.5,0.4,1.2,0.3,1.3-0.1c0.1-0.3,0-0.3-0.4-0.3c-0.7,0.1-0.8,0.1-0.7-0.3c0.1-0.3,1.1-0.4,1.3-0.1
				c0.1,0.1,0.3,0.1,0.5,0s0.6-0.1,0.8,0c0.3,0.1,0.6,0.1,0.8,0.1s0.3,0,0.3,0c0,0.1,0.3,0,0.8,0c0.4-0.1,0.8-0.1,0.9,0
				c0.2,0.2,1.1,0,0.9-0.2c-0.1-0.1,0-0.1,0.2,0c0.5,0.4,1.2,0.5,1.3,0.2c0.1-0.2,0.4-0.2,1.1,0c0.6,0.2,0.9,0.2,1,0.1
				c0-0.1,0.1-0.1,0.2,0.1c0.1,0.2,0.2,0.3,0.6,0.1c0.4-0.1,0.4-0.1,0.2,0.1c-0.1,0.1-0.1,0.3,0,0.3s0.3-0.1,0.3-0.3
				c0-0.2,0.2-0.3,0.5-0.3c0.2,0,0.3,0.1,0.3,0.2c-0.2,0.5-0.1,0.6,0.3,0.5c0.3-0.1,0.6-0.1,1,0c0.3,0.1,0.9,0.2,1.3,0.2
				c0.5,0,0.9,0.1,1,0.2c0.3,0.3,0.7,0.3,1.4,0.2c0.4-0.1,0.6,0,0.9,0.3c0.2,0.2,0.3,0.3,0.3,0.2c-0.1-0.1,0.3-0.1,0.9,0
				c0.6,0.1,1,0.3,0.9,0.3c-0.1,0.1,0.1,0.1,0.4,0c0.3-0.1,0.8-0.1,1.1,0s0.9,0.3,1.4,0.4c0.5,0.2,1.1,0.4,1.4,0.5
				c0.3,0.1,0.7,0.1,0.9,0c0.4-0.1,0.5-0.1,0.9,0.2c0.3,0.3,0.7,0.4,1.1,0.3c0.3,0,0.7,0,0.8,0.1s0.2,0.1,0.2,0s0.3-0.1,0.6,0
				s0.6,0.1,0.7,0c0.1-0.1,0.3-0.1,0.4,0.1c0.2,0.2,0.4,0.2,0.8,0.2c0.5,0,0.6,0.1,0.9,0.4c0.2,0.4,0.3,0.4,0.7,0.3
				c0.3-0.1,0.5-0.1,1.1,0.3c0.5,0.3,0.7,0.4,0.8,0.2c0.1-0.1,0.4-0.1,0.8,0c0.6,0.2,2.7,0.6,4,0.9c0.3,0.1,0.7,0.2,0.9,0.3
				c0.2,0.2,0.3,0.2,0.4,0.1c0.1-0.1,0.3-0.1,0.5,0s0.2,0.2,0.1,0.3c-0.1,0.1,0,0.1,0.2,0c0.5-0.2,0.8-0.1,0.6,0.1
				c-0.1,0.1-0.1,0.1,0.2,0.1c0.2,0,0.5,0.1,0.6,0.2c0.2,0.2,0.2,0.2,0.3,0c0-0.1,0.2-0.1,0.5,0.1c0.3,0.1,0.9,0.3,1.3,0.4
				c0.5,0.1,0.9,0.2,1,0.3c0.3,0.2,2,0.5,2.5,0.4c0.3-0.1,0.3,0,0,0.1c-0.3,0.2-0.3,0.2,0,0.4c0.2,0.1,0.5,0.1,0.7,0.1
				c0.3-0.1,0.4,0,0.6,0.2c0.2,0.2,0.2,0.2,0.2-0.1c-0.1-0.2,0-0.3,0.3-0.2c0.2,0,0.3,0.1,0.2,0.2c-0.1,0.1,0,0.1,0.5,0.1
				c0.6,0,0.6,0,0.3,0.2c-0.4,0.3-0.3,0.3,0.5,0.3c0.5,0,0.6,0,0.7,0.2c0,0.1,0.2,0.3,0.4,0.3s0.2,0,0-0.2c-0.2-0.1-0.2-0.2-0.1-0.2
				c0.1,0,0.4,0.2,0.5,0.4s0.4,0.4,0.7,0.4c0.7,0.1,1.1,0.4,1.1,0.7c0,0.2,0.1,0.3,0.4,0.3c0.8,0,0.9,0,1.4,0.2
				c0.4,0.1,0.5,0.2,0.3,0.4c-0.1,0.2-0.1,0.3,0.2,0.4c0.2,0,0.5,0.2,0.7,0.3c0.3,0.1,0.4,0.1,0.3-0.1c0-0.1-0.2-0.3-0.3-0.3
				c-0.3,0-0.3-0.2,0-0.4c0.2-0.1,0.3-0.1,0.6,0.2c0.2,0.3,0.4,0.3,0.8,0.3c0.2,0,0.6,0,0.9,0.2c0.3,0.1,0.6,0.2,0.8,0.2
				s0.5,0,0.7,0.1c0.3,0.1,0.4,0.3,0.2,0.8s-0.2,0.6,0.1,0.7c0.5,0.1,0.8-0.2,0.5-0.7c-0.2-0.3-0.1-0.4,0.1-0.2
				c0.2,0.1,0.3,0.2,0.2,0.3s0.1,0.3,0.4,0.3c0.3,0.1,0.5,0.3,0.5,0.4c0,0.2,0,0.2,0.2,0.1c0.3-0.2,1.8,0.3,2.1,0.7
				c0.2,0.2,0.5,0.4,0.8,0.5c0.4,0.1,0.5,0.2,0.5,0.5c-0.1,0.3,0,0.4,0.5,0.5s0.5,0.2,0.4,0.6c-0.1,0.3,0,0.5,0.2,0.5
				c0.2,0.1,0.4-0.1,0.5-0.4c0.1-0.4,0.2-0.5,0.5-0.3c0.2,0.1,0.5,0.3,0.7,0.5s0.4,0.3,0.5,0.3s0.5,0.1,0.7,0.3
				c0.3,0.2,0.6,0.3,0.8,0.3c0.6-0.1,1.1,0.4,1,0.9c-0.1,0.4,0,0.5,0.4,0.6c0.3,0.1,0.5,0,0.4-0.2c0-0.1,0-0.3,0.2-0.3
				c0.1,0,0.2,0,0.2,0.1s0.1,0.2,0.3,0.2c0.4,0,1.3,0.5,1.2,0.7c0,0.1-0.2,0.1-0.4,0s-0.3-0.1-0.3,0.1c0,0.1,0.1,0.5,0.1,0.7
				c0,0.3,0.2,0.5,0.3,0.5s0.2-0.1,0.2-0.2c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2,0.1-0.2,0.5-0.1c0.6,0.1,0.7,0.3,0.5,1
				c-0.1,0.2,0,0.3,0.2,0.2c0.4-0.1,0.7,0.2,0.5,0.5c-0.1,0.1-0.4,0.2-0.6,0.1c-0.2-0.1-0.5,0-0.5,0.1c-0.1,0.4,0.4,0.6,0.8,0.4
				c0.2-0.1,0.4-0.2,0.5-0.2c0.5,0.1,0.6,0.1,0.7-0.1c0-0.1,0.2-0.2,0.4-0.2c0.3,0.1,0.3,0.1,0,0.3c-0.2,0.1-0.3,0.3-0.2,0.4
				c0.2,0.3,0.8,0.4,1,0.1c0.1-0.2,0.4-0.3,0.9-0.2c0.6,0.1,0.6,0.2,0.4,0.4c-0.5,0.4,0,0.8,1.7,1.1c1.3,0.2,1.9,0.5,1.8,0.8
				c-0.1,0.4,0.1,0.5,1.4,0.9c0.6,0.2,1.2,0.4,1.4,0.6c0.1,0.1,0.4,0.2,0.5,0.2s0.1,0.1,0,0.2c-0.1,0.2-0.1,0.3,0.1,0.3
				c0.2,0,0.4,0.1,0.4,0.2c0.1,0.2,0.2,0.2,0.4,0.1c0.2-0.1,0.2,0,0.2,0.3c-0.1,0.4,0,0.5,0.5,0.5c0.3,0,0.8,0.2,1.1,0.3
				c0.5,0.3,0.6,0.4,0.9,1c0.1,0.2,0.2,0.3,0.6,0.1c0.4-0.1,0.4-0.1,0.3,0.1c-0.1,0.1-0.1,0.4,0,0.6c0.2,0.3,0.3,0.4,0.9,0.2
				c0.4-0.1,0.7-0.1,0.8,0s0,0.2-0.1,0.1s-0.3,0-0.3,0.1s0.1,0.2,0.3,0.1c0.2,0,0.4,0.1,0.3,0.3c0,0.2,0,0.2,0.1,0.2
				c0.4-0.3,1,0,1.1,0.6c0.1,0.3,0.3,0.6,0.4,0.6c0.5,0.1,0.7,0.2,1.5,0.6l0.8,0.4l-0.6,0.2c-0.5,0.2-0.5,0.2-0.2,0.4
				c0.2,0.1,0.4,0.2,0.6,0.1s0.3,0.1,0.4,0.3c0.1,0.2,0.4,0.3,0.6,0.3c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0.1,0.3,0.4,0.4
				c0.5,0.1,1,0.6,0.7,0.7c-0.1,0.1-0.1,0.1,0.1,0.1c0.1,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.2,0,0.2s-0.3,0-0.2,0.1s0,0.2-0.2,0.1
				c-0.5-0.2-0.6,0.2-0.1,0.7c0.2,0.3,0.4,0.4,0.3,0.3c0-0.1,0.1-0.3,0.3-0.4c0.3-0.2,0.4-0.2,0.6,0.1c0.1,0.2,0.4,0.3,0.7,0.3
				c0.2,0,0.6,0.1,0.8,0.3c0.4,0.3,0.4,0.3-0.1,0.1c-0.6-0.2-1-0.1-0.9,0.5c0.1,0.4,0.8,0.6,1.2,0.3c0.3-0.2,0.3-0.2,0.5,0.1
				c0.1,0.2,0.4,0.4,0.6,0.4c0.3,0.1,0.8,0.5,1.3,1s0.9,0.9,1,0.9s0.1,0.1,0,0.2s0.1,0.3,0.5,0.5s0.7,0.3,0.7,0.2
				c0-0.1,0.2-0.1,0.3,0.1c0.2,0.2,0.3,0.4,0.2,0.6c0,0.2,0.2,0.4,0.5,0.6s0.5,0.4,0.5,0.6s0,0.2,0.2,0.1c0.2-0.1,0.3-0.1,0.5,0.1
				c0.1,0.2,0.2,0.3,0.1,0.3s0,0.1,0.2,0.3c0.3,0.3,0.3,0.3-0.3,0.7c-1.1,0.5-1,1.2,0.2,1.5c0.3,0.1,0.5,0.2,0.5,0.4
				c0,0.2,0,0.2,0.1,0.2s0.1-0.5,0-1.1c-0.2-1,0-1.6,0.3-0.6c0.1,0.3,0.4,0.7,0.8,1s0.6,0.6,0.6,0.8c-0.1,0.3,0.1,0.4,0.6,0.5
				c0.6,0.1,0.7,0.2,0.6,0.5c0,0.2,0,0.3,0.1,0.3s0.4,0.3,0.8,0.7c0.3,0.4,0.8,0.8,1,0.8c0.2,0.1-0.1,0.1-0.6,0
				c-0.5,0-1.1-0.2-1.3-0.3c-0.6-0.4-1.1-0.4-1.2-0.1c0,0.1,0.1,0.3,0.2,0.3c0.2,0,0.5,0.4,0.8,0.7l0.5,0.7l0.7-0.2
				c0.4-0.1,0.7-0.1,0.6-0.1c-0.2,0.1,0,1.1,0.3,1.2s0.4-0.5,0.1-0.8c-0.1-0.1-0.1-0.3,0-0.3s0.3,0.1,0.4,0.2
				c0.1,0.2,0.3,0.4,0.6,0.4c0.3,0.1,0.3,0.1,0.1,0.2c-0.4,0.2-0.5,0.4,0,0.4c0.2,0,0.4-0.1,0.4-0.3c0.1-0.4,1,0.4,1.2,1.1
				c0.2,0.5,0.1,0.6-0.5,0.8c-1.1,0.3-0.9,1.2,0.2,1.1c0.4,0,0.9-0.5,0.8-0.8c0-0.1,0.2,0,0.5,0.3c0.3,0.2,0.5,0.5,0.5,0.6
				s0.1,0.2,0.2,0.2s0.3,0.1,0.4,0.3c0,0.2,0.3,0.6,0.6,0.8c0.3,0.3,0.4,0.5,0.3,0.5s0,0.2,0.3,0.4c0.4,0.4,0.4,0.4,0.1,0.4
				c-0.3-0.1-0.3,0,0,0.3c0.4,0.5,0.5,0.7,0.1,0.8c-0.4,0.1-0.3,0.4,0.2,0.7c0.3,0.2,0.5,0.3,0.4,0.4c-0.2,0.1,0.4,0.5,0.9,0.6
				c0.2,0.1,0.3,0.2,0.2,0.4c-0.1,0.2,0,0.4,0.2,0.4s0.4,0,0.4-0.1s0.1-0.1,0.2,0.1s0.1,0.4,0,0.5s-0.1,0.2,0.1,0.2
				c0.3,0,1.1,0.7,1,0.9c-0.1,0.3,0.5,1.2,0.7,1.3c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.2,0.1,0.1,0.2s-0.1,0.2,0.1,0.2
				c0.3,0.2,0.3,1.1-0.1,1.2c-0.2,0-0.2,0.1,0,0.1s0.2,0.1,0.1,0.2s-0.3,0.1-0.4-0.1c-0.1-0.1-0.4-0.3-0.6-0.4
				c-0.5-0.2-0.6,0.5-0.1,1.1c0.3,0.3,0.3,0.5,0,0.7s-0.3,0.2,0.3,0.2c0.3,0,0.6-0.2,0.7-0.3c0-0.1,0.2-0.1,0.6,0
				c0.3,0.1,0.6,0.2,0.6,0c0-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0.2,0.3,0.4c0,0.2,0,0.4,0.1,0.5s-0.1,0.2-0.4,0.4
				c-0.6,0.2-0.8,0.7-0.3,0.8c0.1,0,0.2-0.1,0.1-0.2c-0.1-0.2,0.1-0.2,0.3-0.2c0.2,0,0.4,0,0.5-0.1s0.1-0.1,0.1,0.1
				s-0.2,0.3-0.3,0.3c-0.1,0-0.1,0.2,0.1,0.5c0.2,0.2,0.3,0.5,0.2,0.6c-0.1,0.1,0,0.1,0.2,0.1s0.5,0.2,0.8,0.5l0.4,0.5l-0.6,0.2
				c-0.3,0.1-0.5,0.2-0.3,0.2c0.1,0,0.2,0.2,0.1,0.3c-0.1,0.1-0.1,0.2,0.2,0.3c0.2,0,0.4,0.2,0.3,0.4c0,0.2,0,0.2,0.1,0.1
				c0.3-0.3,0.6-0.1,0.4,0.3c-0.2,0.3-0.2,0.4,0.2,0.4c0.3,0,0.4,0.2,0.4,0.4c0,0.2,0.1,0.4,0.3,0.4c0.1,0,0.2,0.1,0.1,0.1
				s0,0.3,0.1,0.7c0.2,0.3,0.3,0.7,0.2,0.8c0,0.1,0.1,0.3,0.3,0.4c0.2,0.1,0.2,0.2,0.1,0.2s0,0.3,0.3,0.7c0.4,0.7,0.4,0.8,0,0.9
				s-0.4,0.1,0.1,0.2c0.4,0.1,0.5,0.2,0.3,0.5c-0.1,0.2-0.1,0.4,0,0.5s0.2,0.2,0.1,0.3c-0.1,0.1,0.2,0.1,0.5,0.2
				c0.5,0.1,0.6,0.2,0.5,0.3c-0.1,0.1-0.1,0.2,0.1,0.3s0.2,0.2,0,0.3c-0.1,0.2-0.1,0.2,0.1,0.2s0.3,0,0.2,0.2c0,0.1-0.2,0.3-0.4,0.3
				c-0.2,0-0.4,0.1-0.4,0.2s0.1,0.1,0.3,0.1c0.2-0.1,0.4,0,0.7,0.4c0.2,0.3,0.3,0.6,0.3,0.8c0,0.2,0.1,0.4,0.3,0.5
				c0.2,0.1,0.4,0.7,0.5,1.2c0.1,0.8,0.1,0.9-0.2,1c-0.4,0-0.4,0.7,0,0.8c0.1,0,0.2,0.2,0.2,0.3c0,0.1,0.1,0.3,0.2,0.3
				c0.5,0.1,0.8,0.7,0.7,1.1c-0.1,0.3-0.1,0.4,0.1,0.5c0.2,0,0.3,0.1,0.2,0.3c0,0.2-0.2,0.2-0.4,0.1c-0.5-0.3-0.4-0.1,0.1,0.5
				c0.5,0.5,1,2.4,0.6,2.3s-0.3,0.3,0.2,0.8c0.3,0.3,0.4,0.5,0.3,0.6c-0.1,0-0.1,0.1,0.1,0.1s0.2,0.2,0.2,0.3c0,0.2-0.3,0.3-0.8,0.1
				c-0.4-0.1-0.1,0.1,0.6,0.5c0.5,0.3,0.7,0.6,0.7,0.8c0,0.2,0,0.5,0.1,0.5c0.1,0.1,0.1,0.2,0,0.4c-0.1,0.1-0.3,0.2-0.4,0.1
				s-0.2-0.1-0.3,0c-0.1,0.1,0.1,0.3,0.4,0.5s0.5,0.3,0.6,0.2c0-0.1,0.1-0.1,0.3,0c0.1,0.1,0.2,0.3,0.1,0.5
				c-0.1,0.3-0.1,0.3-0.2,0.1c-0.1-0.2-0.3-0.2-0.4-0.2c-0.3,0.1-0.6,1.8-0.4,2.1c0.1,0.1,0.1,0.4,0,0.6s0,0.4,0.2,0.6
				c0.2,0.2,0.3,0.5,0.3,0.7s0,0.5,0.2,0.5s0,0.1-0.3,0.2c-0.5,0.1-0.5,0.1,0,0.3c0.3,0.1,0.4,0.3,0.3,0.4c-0.1,0.1-0.1,0.4,0.1,0.6
				c0.2,0.3,0.2,0.5,0.2,0.5c-0.1,0-0.1,0.1,0,0.3C285.9,132.3,285.8,132.4,285.3,132.5 M279.6,161.5c-0.2,0.2-0.4,0.2-0.8-0.1
				s-0.3-0.5,0.2-0.5C279.6,160.9,279.9,161.2,279.6,161.5 M191,214.3c-0.2,0-0.5,0-0.7,0c-0.2-0.1-0.2-0.1,0.1-0.1
				C191.1,214.1,191.5,214.2,191,214.3 M137.8,226.5c-0.2,0-0.4,0-0.3-0.1c0-0.1,0.2-0.2,0.4-0.2s0.4,0,0.3,0.1
				C138.2,226.4,138.1,226.4,137.8,226.5 M38,145.4c0.2-0.2,0.6-0.2,0.6,0c0,0.1-0.2,0.2-0.4,0.2C37.9,145.6,37.8,145.5,38,145.4
				 M51.1,18c0.2,0,0.5,0.1,0.6,0.2c-0.1,0.1-0.1,0.1,0,0.3C51.1,18.8,50.5,18.1,51.1,18 M66.5,12.6c0.3-0.1,0.7,0.5,0.9,1v0.1
				c-0.2,0.2-0.1,0.4-0.3,0.5C67,14.1,66.7,14,66.5,14c0.2-0.1,0.8,0,0.8-0.1c0.1-0.2-0.4-0.7-0.9-0.7C66.4,13,66.3,12.6,66.5,12.6
				 M74.7,15.5c0.4,0.1,0.6,0.1,0.6-0.2c0.2,0,0.4,0,0.5,0c0.1,0.5,0.1,0.9,0.4,1.4c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3-0.1
				c-0.6-0.3,0.3-0.8-0.8-0.7C74.7,15.9,74.5,15.7,74.7,15.5 M74,14.7c0.2-0.2,0.5-0.1,0.6,0.2C74.4,14.8,74.2,14.7,74,14.7
				 M77.7,16.2c0.1,0,0.4,0.1,0.6,0.3c0,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.1,0,0.2c-0.3,0-0.5,0-0.8-0.1c-0.2-0.3-0.5-0.6-0.7-0.8
				C77.2,16.3,77.5,16.2,77.7,16.2 M79.1,18.3c0.3,0.3,0,0.6-0.3,0.5s-0.5-0.3-0.4-0.4c0-0.1,0.1-0.2,0.2-0.2
				C78.3,18.7,78.7,19.1,79.1,18.3 M80.9,19.1c-0.2-0.4-0.5-0.5-0.8,0C79.6,18.4,81.3,18.5,80.9,19.1 M84.1,29.7
				c0.5-0.1,0.3,0.5,0.2,0.9C84,30.2,83.9,29.9,84.1,29.7 M82.8,21c-0.1-0.6-0.3-0.6-0.4-1.2c0.1,0,0.2,0.1,0.4,0.1
				c-0.4,1.1,1.7,2.7,1.1,0.2c1.1,0.4,2.1,0.4,2.6,1.5c-0.2,0.1-0.2,0.2,0,0.3c-0.1,0-0.2,0.1-0.2,0.1c-0.2-0.2-0.5-0.9-0.9-0.9
				c-0.2-0.8-1.1-0.4-0.4,0.5c-0.3,0.3-0.9,0.4-1.5,0.5c-0.9,0.1-1.3,0-0.9-0.2c0.2-0.1,0.3-0.4,0.3-0.7
				C82.8,21.1,82.8,21.1,82.8,21 M82.6,18.9c0.2,0,0.3-0.1,0.2-0.3c0.3-0.4,0.7-0.6,1.1-0.5c-0.7,0.5,0.4,0.6,0.3,0.1
				c0.1,0.1,0.2,0.1,0.2,0.2c-0.3,0.1-0.3,0.3,0.1,0.3c0,0.2-0.4,0.5-0.9,0.6c0-0.1,0-0.3,0-0.4c0.2,0.1,0.5,0.1,0.6,0
				c0.3-0.4-1.3-0.5-0.7-0.1c-0.1,0-0.2,0.2-0.3,0.3c-0.2,0.1-0.6,0.2-0.8,0.3C82.4,19.2,82.5,19,82.6,18.9 M87,24.2L87,24.2
				c0,0.1,0.1,0.4,0.1,0.5c-0.1,0-0.2,0-0.3,0C86.9,24.4,86.8,24.4,87,24.2 M90.1,25.3c0,0.2,0.2,0.6,0.4,0.6
				C90,26.2,89.4,25.4,90.1,25.3 M91,4.6C90.9,4.9,91,5,91.3,5c0,0,0,0,0,0.1c-0.1,0.4-0.9,0.2-0.8-0.2C90.5,4.6,90.7,4.5,91,4.6
				 M90.8,27.5c-0.3,0-0.4-0.6-0.5-0.8c0.5-0.1,0.7-0.3,1.2-0.5c-0.2,0.5,0,0.8-0.4,1.2C90.8,27.2,90.7,27.3,90.8,27.5 M98,3
				c-0.3-0.1-1-0.4-1.3-0.3C96.1,1.6,98.2,2.1,98,3 M101.3,3.1c-0.3,0.7-1,0.6-1.5-0.3C100.2,3,101,3.3,101.3,3.1 M107.1,2.3
				c-0.2,0.1-0.4,0.3-0.1,0.7c-0.1,0-0.2,0.1-0.3,0.1c0-0.3-0.1-0.8-0.3-1.1C106.7,2.1,106.9,2.2,107.1,2.3 M109.5,3.7
				c-0.3,0-0.6,0-0.8,0C108.8,3.3,109.4,3.4,109.5,3.7 M113.9,3.2c0.1,0.1,0.1,0.1,0.2,0.2c0,0.1,0.1,0.1,0.1,0.2
				c-0.1,0.4-1,0.2-0.9-0.2C113.3,3.2,113.6,3.1,113.9,3.2 M97.8,3.3c-0.2,0.3-0.5,0.2-0.8-0.1C97.3,3.3,97.6,3.3,97.8,3.3
				C97.8,3.2,97.8,3.2,97.8,3.3 M95.8,27.9c-1-0.2-0.8-0.4-0.8-0.6C95.9,27.2,95.8,27.4,95.8,27.9 M262.8,78.7
				c0.3-0.1,0.4-0.1,0.6,0.2c0.1,0.2,0.2,0.4,0.1,0.5l0,0c-0.1,0.1-0.3,0.1-0.5,0c-0.2,0-0.3-0.1-0.2-0.2c0.1-0.1,0-0.2-0.1-0.3
				C262.5,78.9,262.5,78.8,262.8,78.7 M307.2,142.6c0.1,0.2,0,0.2-0.2,0.1c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2-0.1-0.3-0.6-0.3
				c-0.4,0-0.6-0.1-0.7-0.3c0-0.2-0.2-0.3-0.3-0.2c-0.1,0-0.1-0.1,0-0.3s0.3-0.3,0.4-0.4c0.4-0.1,0.3-0.5-0.1-0.6s-0.4-0.1-0.1-0.4
				c0.2-0.1,0.3-0.4,0.2-0.6c-0.2-0.6-0.2-0.8,0.1-1.1c0.2-0.2,0.2-0.3-0.2-0.4c-0.5-0.1-0.5-0.1-0.1-0.2c0.4,0,0.4,0,0.1-0.2
				c-0.5-0.2-0.3-1.4,0.2-1.4c0.2,0,0.3-0.2,0.3-0.4c-0.1-0.4-0.3-0.5-0.6-0.2c-0.1,0.1-0.1,0,0-0.2c0.2-0.3,0.1-0.5-0.2-0.8
				c-0.3-0.2-0.3-0.4-0.2-0.4c0.3-0.1,0.5-1.5,0.3-2.1c-0.2-0.4,0.1-1,0.5-1c0.4,0.1,0.3-0.3-0.2-0.5c-0.4-0.2-0.4-0.2-0.1-0.3
				c0.2,0,0.3-0.1,0.1-0.1c-0.1,0-0.2-0.3-0.1-0.6c0.1-0.4,0.2-0.5,0.4-0.4c0.2,0.1,0.3,0.2,0.3,0.3c-0.1,0.3,0.6,0.3,1.1,0.1
				c0.3-0.1,0.6-0.2,0.7-0.1c0.1,0.1,0.3-0.1,0.3-0.2c0-0.2-0.1-0.4-0.3-0.5c-0.3-0.1-0.4,0-0.5,0.2c-0.1,0.3-0.2,0.3-0.8,0.2
				c-0.6-0.1-0.7-0.2-0.5-0.4c0.1-0.2,0-0.5-0.4-0.9c-0.5-0.5-0.5-0.6-0.2-0.6c0.3,0.1,0.3,0,0.2-0.2c-0.1-0.2-0.1-0.5,0-0.7
				s0.1-0.4,0-0.4c-0.2,0-0.4-1.4-0.2-1.7c0-0.1,0-0.2-0.2-0.3c-0.2-0.1-0.2-0.2,0-0.3c0.4-0.1,0.6-1,0.4-1.3
				c-0.1-0.1-0.4-0.3-0.6-0.2c-0.2,0-0.5-0.1-0.7-0.2c-0.3-0.1-0.3-0.2,0-0.5c0.2-0.2,0.2-0.3,0.1-0.2c-0.1,0-0.3,0-0.4-0.1
				s0-0.2,0.1-0.1c0.1,0,0.3-0.1,0.3-0.4c0.1-0.4,0.4-0.5,1,0c0.4,0.2,0.4,0.2,0.5-0.2c0.1-0.6-0.4-0.8-0.9-0.4
				c-0.2,0.2-0.3,0.1-0.3-0.3c0-0.3-0.1-0.6-0.3-0.6c-0.1,0-0.2-0.2-0.2-0.3c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.2-0.1-0.5s0.1-0.7,0.1-1
				c0-0.2,0.1-0.5,0.3-0.7c0.2-0.2,0.2-0.3-0.1-0.6c-0.2-0.2-0.5-0.3-0.6-0.3s-0.2-0.1-0.1-0.2c0.1-0.3-0.3-1.1-0.7-1.5
				c-0.2-0.2-0.3-0.4-0.2-0.5c0.1-0.1,0.1-0.3,0-0.4c-0.4-0.3-0.8-1.4-0.7-1.9c0.1-0.3,0-0.5-0.1-0.6c-0.2,0-0.5-1.1-0.5-1.7
				c0-0.3-0.4-1.3-0.6-1.3c-0.1,0-0.2-0.2-0.1-0.4c0-0.2,0-0.4-0.1-0.5s-0.1-0.2,0-0.3s0-0.2-0.2-0.2s-0.3-0.1-0.3-0.2
				c0.1-0.2-0.3-1.1-0.7-1.4c-0.3-0.2-0.3-0.5-0.2-0.6c0.1-0.1,0.1-0.3-0.1-0.5s-0.2-0.2,0-0.2c0.5,0.1,0.5-0.4,0-1
				c-0.3-0.3-0.3-0.5-0.1-0.4c0.2,0.1,0.3,0,0.1-0.2c-0.1-0.2-0.3-0.5-0.3-0.8c-0.1-0.4,0-0.5,0.2-0.5s0.4,0,0.4-0.2
				c0.1-0.3-0.5-0.5-0.7-0.3c-0.1,0.1-0.1,0,0-0.1c0.1-0.2-0.3-1-0.5-1c-0.1,0-0.2-0.2-0.2-0.5c0-0.4,0-0.5-0.2-0.3
				s-0.3,0.1-0.2-0.1c0-0.2,0.2-0.3,0.3-0.2c0.2,0,0.2-0.1,0.1-0.3c-0.1-0.2-0.3-0.4-0.5-0.4s-0.4-0.1-0.4-0.5
				c0-0.3-0.1-0.6-0.2-0.7s-0.1-0.3,0-0.4s0.1-0.1,0-0.1s-0.4-0.1-0.6-0.3s-0.3-0.3-0.2-0.3c0.1,0,0.1-0.1,0-0.3
				c-0.1-0.2-0.1-0.3,0-0.3s0.1-0.1-0.1-0.2s-0.2-0.5-0.1-0.9c0.2-0.7-0.1-1-0.8-1c-0.3,0-0.6-0.8-0.4-1.1c0.1-0.1,0-0.1-0.1-0.1
				s-0.3-0.1-0.4-0.3c-0.7-1.2-0.9-1.6-1.1-1.6c-0.3-0.1-1.5-1.5-1.4-1.6c0.1,0,0-0.1-0.1-0.1c-0.2,0-0.2-0.1-0.1-0.2s0-0.3-0.2-0.5
				s-0.4-0.5-0.3-0.6c0-0.1-0.1-0.4-0.3-0.5c-0.4-0.2-0.4-0.3,0.1-0.4c0.3-0.1,0.5-0.2,0.5-0.2c0-0.1-0.9-0.5-1.2-0.6
				c-0.1,0-0.2-0.1-0.1-0.2c0-0.2-0.1-0.4-0.8-1.2c-0.2-0.2-0.3-0.5-0.2-0.6c0.1-0.1,0-0.3-0.3-0.5c-0.3-0.1-0.8-0.6-1.2-1
				c-0.3-0.4-0.8-0.8-1-0.9s-0.3-0.2-0.3-0.3c0.1-0.1,0-0.1-0.2-0.1c-0.4,0-0.5-0.2-0.3-0.6c0-0.1-0.1-0.1-0.3-0.1
				c-0.2,0-0.4-0.1-0.3-0.1c0-0.1,0.3-0.1,0.5-0.1c0.3,0.1,0.4,0,0.4-0.1c-0.1-0.1-0.3-0.2-0.5-0.2c-0.3,0-0.5-0.2-0.8-0.9
				c-0.2-0.5-0.5-1-0.7-1.2c-0.1-0.2-0.2-0.4-0.1-0.5c0.1-0.2-0.2-0.7-1.2-1.8c-0.4-0.4-0.7-0.8-0.7-0.9s-0.3-0.5-0.7-1
				c-0.4-0.5-0.7-1-0.6-1c0.1-0.1,0-0.1-0.2-0.1s-0.4-0.1-0.4-0.3c0-0.2,0.1-0.3,0.5-0.3c0.7,0,0.4-0.2-0.7-0.5s-1.3-0.5-0.9-0.8
				c0.2-0.1,0.4-0.3,0.4-0.4s-0.1,0-0.3,0.1c-0.2,0.1-0.4,0.2-0.5,0.1s-0.2-0.1-0.1,0.1c0.1,0.1,0,0.2-0.1,0.2s-0.3-0.2-0.3-0.4
				c-0.1-0.5-0.7-1.2-1-1.1c-0.1,0-0.1,0,0-0.1c0.2-0.2-1-1.1-1.5-1.1c-0.2,0-0.2-0.1-0.1-0.2c0.2-0.2-0.1-0.4-0.8-0.5
				c-0.1,0-0.1-0.2,0-0.3c0.2-0.3-0.2-0.5-0.5-0.2c-0.2,0.3-0.6-0.3-0.5-0.8c0.1-0.3,0.2-0.3,0.4-0.2c0.2,0.1,0.4,0.1,0.4-0.2
				c0.1-0.4,0.1-0.4-0.5-0.2c-0.3,0.1-0.7,0.2-0.7,0.3c-0.2,0.1-0.7-0.3-0.7-0.4c0.1-0.3,0.1-0.4-0.1-0.5c-0.1,0-0.2,0-0.1,0.2
				c0.1,0.1,0,0.2-0.1,0.1c-0.1-0.1-0.3-0.1-0.4,0s-0.2,0.1-0.1-0.1c0-0.1,0.2-0.3,0.4-0.4c0.3-0.1,0.3-0.2,0-0.4
				c-0.2-0.2-0.4-0.2-0.5,0s-0.2,0.1-0.3-0.3c-0.1-0.3-0.1-0.6,0-0.6c0.2-0.1-0.1-0.5-0.3-0.4c-0.1,0-0.2,0.2-0.1,0.4
				c0.1,0.3,0.1,0.4-0.1,0.3c-0.2-0.1-0.3-0.4-0.4-0.7c-0.2-0.7-0.6-1-1-0.7c-0.2,0.1-0.2,0.1-0.2-0.1c0-0.2-0.1-0.4-0.4-0.6
				c-0.3-0.2-0.4-0.2-0.3-0.1c0.1,0.1,0,0.2-0.2,0.1c-0.2,0-0.4-0.1-0.4-0.2c0-0.1,0-0.4,0-0.6s0.1-0.6,0.3-0.7
				c0.2-0.1,0.2-0.2,0-0.3c-0.1-0.1-0.3,0-0.4,0.1c-0.2,0.3-0.3,0.3-0.6,0c-0.2-0.2-0.6-0.5-0.9-0.8c-0.7-0.7-1.1-1.1-1.1-1
				c0,0-0.2-0.1-0.4-0.3s-0.4-0.5-0.4-0.6s-0.1-0.2-0.3-0.3c-0.2,0-0.6-0.3-0.9-0.5c-0.3-0.3-0.7-0.4-0.9-0.4c-0.3,0-0.4,0-0.1-0.2
				s0.2-0.3-0.3-0.6c-0.4-0.3-0.9-0.7-1.2-1c-0.3-0.3-0.7-0.6-1-0.7c-0.3-0.1-0.4-0.3-0.4-0.4c0.1-0.1,0.1-0.1,0-0.1
				c-0.2,0.2-0.5,0.1-1-0.4c-0.3-0.3-0.7-0.5-0.9-0.5c-0.2,0-0.6-0.2-0.7-0.4c-0.2-0.2-0.6-0.5-0.9-0.6c-0.3-0.1-0.5-0.3-0.5-0.5
				c0-0.1-0.4-0.5-1.1-0.8c-1.1-0.5-2-1.2-1.7-1.4c0.1,0,0-0.2-0.2-0.4c-0.2-0.3-0.3-0.3-0.4-0.1c0,0.1-0.1,0.1-0.3-0.1
				c-0.2-0.3-0.3-0.3-0.6-0.2s-0.4,0-0.7-0.3c-0.2-0.2-0.5-0.5-0.7-0.5c-0.2-0.1-0.4-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
				c-0.1,0-0.2-0.2-0.1-0.3c0.1-0.3-0.3-0.3-0.5-0.1c-0.3,0.3-0.6,0-0.6-0.4c0.1-0.3,0-0.3-0.6-0.4c-0.6,0-0.6-0.1-0.3-0.2
				c0.5-0.2,0.5-0.4,0-0.5c-0.2,0-0.4,0-0.4,0.2c0,0.1-0.2,0.2-0.3,0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0.2-0.2-0.2-1.1-0.5-1
				c-0.1,0-0.1,0.1,0,0.2c0.1,0.1,0.2,0.3,0,0.3c-0.2,0.1-0.3,0.1-0.6-0.2c-0.2-0.2-0.4-0.3-0.5-0.3s-0.3,0-0.3-0.2
				c-0.1-0.1-0.4-0.3-0.7-0.4c-0.4-0.1-0.5-0.2-0.5-0.5c0.1-0.4-0.3-0.8-0.8-0.8c-0.2,0-0.4-0.1-0.5-0.3s-0.4-0.3-0.6-0.3
				c-0.2,0-0.4-0.1-0.4-0.3s-0.3-0.3-0.6-0.4c-0.3-0.1-0.6-0.3-0.7-0.5c-0.1-0.3-0.4-0.4-1-0.6c-0.5-0.1-0.8-0.2-0.7-0.3
				s0-0.3-0.2-0.4c-0.3-0.2-0.4-0.3-0.5-0.1c-0.1,0.1-0.2,0.2-0.4,0.1c-0.1,0-0.1-0.1,0-0.3s0-0.3-0.4-0.3c-0.3-0.1-0.8-0.3-1.2-0.5
				c-0.3-0.2-0.6-0.3-0.5-0.2c0.2,0.3-0.1,0.1-0.7-0.4c-0.6-0.6-0.9-0.7-1-0.4c0,0.1-0.2,0-0.4-0.2s-0.4-0.3-0.4-0.3
				c0,0.1-0.2,0-0.4-0.1s-0.7-0.4-1-0.5s-0.7-0.4-0.9-0.6c-0.2-0.4-1.5-1-1.3-0.6c0.1,0.1,0,0.1-0.2,0s-0.3-0.2-0.2-0.3
				c0.1-0.2-0.7-0.4-1.5-0.5c-0.2,0-0.3-0.2-0.2-0.3c0-0.2-0.1-0.3-0.4-0.3c-0.3,0-0.5-0.1-0.6-0.2c-0.1-0.2-0.8-0.3-1.3-0.2
				c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.3,0-0.3-0.2-0.2c-0.2,0.2-0.3,0.2-0.5-0.1c-0.1-0.2-0.2-0.3-0.6-0.1c-0.3,0.1-0.4,0.1-0.4-0.1
				s0-0.2-0.2,0s-0.3,0.2-0.2-0.1c0.1-0.3-0.6-0.5-1.1-0.3c-0.3,0.1-0.3,0.1-0.2-0.1c0.1-0.1,0-0.2-0.2-0.3c-1-0.1-1.4-0.2-1.6-0.4
				c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.3-0.1-0.3-0.1c0-0.1-0.2-0.2-0.5-0.3c-0.6-0.1-1.3-0.6-1.2-1c0-0.2,0-0.2-0.2-0.1
				s-0.5,0-1.1-0.5c-0.5-0.3-0.9-0.7-0.9-0.8c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0.1-0.3,0.2s-0.2,0.2-0.4,0.1s-0.3-0.3-0.3-0.4
				c0-0.2,0-0.2-0.2-0.1c-0.1,0.1-0.6,0.1-1.1,0c-0.8-0.1-1.1-0.4-0.5-0.4c0.2,0,0-0.1-0.3-0.1c-0.3-0.1-0.6,0-0.5,0.1
				s-0.1,0.1-0.2-0.1c-0.2-0.2-0.4-0.2-0.4-0.2c0,0.1-0.1,0-0.2-0.1c-0.1-0.1-0.3-0.3-0.6-0.4s-0.5-0.3-0.6-0.4s-0.2-0.1-0.3,0
				c-0.2,0.2-0.7,0.1-1-0.2c-0.2-0.2-1-0.6-3.7-1.7c-0.2-0.1-0.4-0.1-0.4-0.1c0,0.1,0.1,0.2,0.3,0.4c0.3,0.2,0.2,0.2-0.2-0.1
				c-0.7-0.5-0.9-0.5-0.7-0.3c0.2,0.3-0.4,0.1-0.9-0.3c-0.3-0.3-0.5-0.3-1-0.2c-0.5,0.1-0.7,0.1-0.8-0.1c-0.1-0.1-0.4-0.3-0.7-0.4
				s-0.6-0.3-0.6-0.4c-0.1-0.1-0.4-0.2-0.8-0.2c-0.5,0-0.6-0.1-0.3-0.1c0.2-0.1,0.3-0.2,0.3-0.2c-0.2-0.2-0.8,0-0.9,0.3
				c-0.1,0.3-0.2,0.3-1.5-0.1c-0.7-0.2-0.9-0.4-0.8-0.5c0.2-0.2,0-0.3-0.6-0.2c-0.4,0.1-0.5,0-0.5-0.4c-0.1-0.4-0.1-0.5-0.4-0.3
				c-0.2,0.1-0.5,0.1-0.6,0c-0.2-0.1-0.5-0.2-0.7-0.2s-0.6-0.1-0.8-0.2s-0.5-0.2-0.6-0.3c-0.1,0-0.2-0.1-0.2-0.1s-0.3-0.1-0.5-0.2
				c-2.4-0.7-2.7-0.9-2.4-1.2c0.2-0.3,0-0.4-0.9-0.3c-0.4,0-0.7,0.2-0.7,0.4c-0.1,0.3-0.1,0.3-0.2,0.1c-0.1-0.2-0.1-0.4,0-0.6
				c0.2-0.2,0.1-0.4-0.1-0.4c-0.6-0.1-0.8,0.2-0.4,0.6c0.2,0.2,0.3,0.5,0.2,0.6c0,0.2-0.1,0.2-0.1,0c0-0.1-0.3-0.3-0.7-0.4
				c-0.4-0.1-0.5,0-0.5,0.1c0.1,0.1,0,0.2-0.1,0c-0.2-0.1-0.4-0.1-0.5,0c-0.2,0.1-0.2,0.1-0.2-0.1c0-0.2,0-0.2-0.2-0.1
				c-0.2,0.2-0.3,0.1-0.1-0.2c0.2-0.6-0.7-0.9-1.2-0.4c-0.4,0.5-0.9,0.4-1.4-0.3c-0.3-0.5-0.4-0.5-0.8-0.4c-0.5,0.2-1.8,0-1.8-0.2
				c0-0.1,0.2-0.1,0.3-0.1c0.2,0,0.2,0,0-0.2s-0.4-0.2-0.8,0c-0.4,0.2-0.7,0.2-0.9,0c-0.3-0.2-0.5-0.2-0.7-0.1
				c-0.2,0.1-0.4,0-0.8-0.2c-0.4-0.3-0.6-0.3-0.9-0.2c-0.3,0.1-0.4,0.1-0.6-0.2c-0.1-0.2-0.1-0.3,0-0.4c0.1,0,0.1-0.1-0.1-0.1
				c-0.2,0-0.3,0.1-0.4,0.4l-0.1,0.4v-0.5v-0.5l-0.6,0.3c-0.6,0.2-0.7,0.2-1.1-0.2c-0.3-0.3-0.4-0.3-0.5-0.2c0,0.1-0.2,0.1-0.6,0
				c-0.3-0.1-0.5-0.1-0.5-0.1c0.1,0.3-0.6,0.2-1.2-0.1c-0.4-0.2-0.6-0.2-0.6-0.1c0,0.1-0.1,0.1-0.3,0c-0.4-0.3-1.3-0.5-1.4-0.4
				c0,0.1-0.4,0-0.8-0.1c-0.4-0.2-0.7-0.2-0.6-0.1c0.1,0.1-0.1,0.1-0.4,0.1c-1.1-0.2-2-0.3-2.3-0.2c-0.2,0-0.7,0-1.1-0.1
				c-0.4-0.1-0.8-0.1-1,0c-0.1,0.1-0.1,0,0-0.3c0.2-0.3,0.2-0.4-0.1-0.5c-0.2,0-0.4,0-0.4,0.1s-0.2,0.1-0.3,0.1
				c-0.6-0.2-0.9-0.2-0.8,0.1c0.1,0.2,0,0.3-0.3,0.1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2,0-0.4-0.2-0.4-0.4c-0.1-0.5-0.5-0.6-0.6-0.1
				c-0.1,0.3-0.2,0.3-0.6,0.1c-0.4-0.2-0.5-0.2-0.5,0.1c0.1,0.2-0.1,0.2-1.4-0.1c-0.8-0.2-1.5-0.3-1.6-0.2c0,0.1-0.5,0-1.1-0.1
				s-1-0.2-1-0.1c0,0.1-0.6,0.2-1.4,0.3c-0.8,0.1-1.4,0.1-1.4,0s-0.1,0-0.4,0.1c-0.2,0.1-0.5,0.2-0.6,0.1c-0.2-0.2-1.2-0.1-1.6,0.1
				c-0.1,0.1-0.2,0-0.1-0.2c0.1-0.4-0.3-0.5-0.6-0.2c-0.1,0.2-0.3,0.2-0.6,0s-0.5-0.2-1-0.1c-0.3,0.1-0.7,0.2-0.9,0.1
				c-0.1,0-0.1-0.1,0.1-0.1c0.7-0.2,0.1-0.3-0.7-0.2c-0.5,0.1-1.2,0-1.8-0.1c-0.8-0.2-1-0.2-0.9,0s-0.1,0.3-0.6,0.2
				s-0.6-0.1-0.3,0.1c0.2,0.1,0.3,0.2,0,0.3C135.7,9.4,135,9,135,9.1c-0.3,0.1-0.6,0.3-1,0.4c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0.1
				s0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.3-0.1c0.3,0.1,0.5,0.2,0.7,0.3c-0.9,0.1-1.4,0.1-2.3-0.1c0.3-0.2,0.6-0.3,0.9-0.5
				c-1.1-0.5-2.3-1-3.3-1.4c0-0.1-0.1-0.1-0.2-0.1l0,0c-0.9-0.3-1.6-0.6-2.2-0.7c-1.9-0.4-3.7-0.9-5.3-1.3c0.1-0.2,0.2-0.5,0.3-0.7
				c0-0.1-0.2-0.3-0.3-0.1c-0.1,0.2-0.2,0.5-0.3,0.7c-3-0.9-5.1-1.7-5.1-2c0-0.2-0.2-0.4-0.4-0.4c0,0,0-0.1-0.1-0.2
				c-1-0.9-2.6-1.1-2.8-1.5c-0.1-0.1-0.4-0.1-0.2,0.1c0.2,0.2,0.3,0.3,0.4,0.5c-0.7,0.2-1.3,1-2.1,0.9c0.3-0.3-0.1-0.6-0.9-0.3
				c-0.5-0.2-2.1-1.3-3.4-1.9c-0.1-0.3-0.7-0.6-0.6-0.3c-0.3-0.1-0.6-0.3-0.8-0.4c0.4-0.3-0.3-0.5-0.4-0.2C106,0.1,105.7,0,105.5,0
				c0.1-0.1,0.3-0.1,0.8,0s-0.4-0.7-0.7-0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.1-0.5,0.2-0.4,0.6c-0.2,0.3-0.6,0.6,0,0.7
				c0.4,0.1,0.6,0,0.7-0.1c0.3,0.1,0.8,0.1,1.2,0.2c-0.3,0.5,0.2,1-0.8,0.4c-0.6-0.4-1.4-0.2-0.3,0.3c0.4,0.2,0.5,0.5,0.7,0.8
				c-1.1,0-1.8-1-4.2-3.4c0.3,1.4,0.5,2.3,0.3,3c0-0.1-0.2-0.2-0.4-0.2C101.8,2,101,1.6,100.9,2c-0.2-0.6-0.5-1.9-1.4-2.1
				c-0.4-0.1-0.7,0.1-0.4,0.5c0,0.1,0,0.1,0,0.2c-0.2,0-0.4,0.2-0.4,0.4c-0.2,0.1-0.5,0.2-0.8,0.2c-0.8,0.1-1.8-0.3-2-0.8
				c-0.3-0.5-0.7-0.7-1.1-0.4c-0.2,0.1-0.2,0.4-0.1,0.6c-0.2,0-0.3,0.6-0.1,1.1C94,1.3,92.8,1,92.1,0.9c0.1-0.7-0.9-1.1-0.6-0.1
				c-2.3-0.3,2.9,2.4,3.3,1.3c0.5,0.6,0.3-0.7,0.1-1.3c0.1,0.1,0.3,0.3,0.5,0.4c1,0.5,1.1,1.2,0.7,1.6c-0.2-0.1-0.4-0.1-0.3,0.1
				c-0.1,0-0.1,0-0.2-0.1s-0.4,0-0.2,0.1C95.5,3,95.6,3,95.7,3.1c-0.1,0.1-0.3,0.1-0.5,0.2c-0.2-0.1-0.5-0.1-0.8,0
				c0.2-0.1,0.3-0.3,0.2-0.5c-0.2-0.4-1.2,0.4-0.8,0.7c-1.2-0.1-3.3-1-4.2,0.1c-0.5-0.3-0.7,0-0.3,0.2c-0.1,0.1,0,0.2,0.1,0.3
				c0,0.1,0,0.2,0,0.4c-0.2-0.1-0.5-0.1-0.6,0c-0.2-0.3-0.6-0.4-0.8,0.1c-0.1,0.2-0.2,0.2-0.3,0.2C86.5,5,86,4.6,85.8,4.3
				C85.9,4.2,86,4,86,3.8c0.1,0,0.2,0,0.4,0c0.3,0-0.1-0.3-0.4-0.3c0-0.3,0.2-0.4,1,0c0.5,0.2-0.1-0.8-0.5-0.5
				c-0.6,0.3-1.1,0.7-1.6,1c-0.1,0.1,0.1,0.4,0.7,0.5c-0.2-0.1-0.5,0-0.9,0.2c-1.6,0.5-1.8,0.6-0.2,1c0.4,0.1,0.8,0,1-0.2
				c0,0.1,0.1,0.1,0.3,0.1c0.2,0.5,0,1,0.7,1.3c0.4,0.2,0.7-0.1,1-0.4c0.1,0.1,0.2,0.2,0.4,0.3c-0.8,0.4-1.1,0-1.8,0.7
				c-0.1,0-0.1,0.1-0.2,0.1c-0.7,0.2-1.1-0.4-0.4-0.6c0.2,0,0.5,0,0.6-0.1c0.1-0.4-1.6-1.1-1.7-0.1c0,0.2-0.1,0.4-0.2,0.6v0.1
				c-0.6,0.1-0.5,0.6,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c-0.2,0.2-0.4,0.4-0.4,0.5c-0.8-0.2-0.6,0.5,0.9,0.7c-0.1,0.2-0.3,0.4-0.5,0.6
				c-1.2-0.2-2.1,0.4-0.9,1c-0.2,0.3-0.5,0.7-0.7,1c-0.2-0.5-0.3-0.8-0.2-1.3c0.3-0.3,0.3-0.7,0-0.6c-0.3-0.5-1.3-0.5-2.2-0.1
				c-0.3,0.1-1.1,0.2-1.2-0.1s1.2-0.9,0.7-1.2s-1.3,0.8-2.1,0.6c-0.2-0.1-1,0.3-0.9,0.7c0.1,0.3,1.8,1.2,1.2,0.5
				c-0.1-0.2-0.4-0.3-0.3-0.5c0.2-0.3,2.1,0.5,2.1,0.9c0,0.1-0.9,0.3-1.1,0.4c-0.7,0.4-0.4,0.4,0.2,0.3c0.1,0,0.1,0,0.2,0
				c-0.1,0.3,0.4,0.6,0.7,0.4c0,0.2,0.3,0.2,0.3,0c0-0.3,0-1.1,0.4-1.1c0.8,0,1.1,1.9,1.5,2.5c-0.3,1-0.2,1.8,0.3,2
				c0.3,0.1,0.4,0.3,0.3,0.5c-0.1,0-0.1,0-0.2,0c-1.1-0.6-1-0.1-0.3,0.6C82.2,17,82,17,81.6,17.1c0.1-0.1-0.1-0.2-0.4-0.2
				c0.3-0.1,0.3-0.3,0.2-0.4c0-0.1,0.1-0.2,0-0.4c0.1-0.2-0.1-0.3-0.3-0.3L81,15.7c1.4-1.1-0.4-0.9-0.2-0.1
				c-0.7-0.3-1.1,0.1-0.4,0.9c-0.6,0.5-0.2,0.8,0.5,0.5c0,0.1,0.2,0.1,0.3,0.2c-0.5,0.1-1.1,0.1-1.8,0.1l-0.1-0.1
				c-0.1-0.2-0.3-0.4-0.4-0.5c-0.1-0.2,0-0.5,0.2-0.6c0.7-0.3,0.7-1,0-1c-0.6,0-0.7,0.6-1.3,0.8c-0.1-0.1-0.3-0.3-0.5-0.5
				c1.9-0.1,2.4-1.6,1-1.2c-0.5,0.1-0.6-0.1-0.6-0.5c0-0.2-0.2-0.6-0.5-0.6c-0.2,0-0.1,0.3-0.1,0.5c0,0.1,0,0.3-0.1,0.4
				c-0.3,0.1-0.7,0.4-0.8,0.6c-0.1,0.2,0,0.3,0.2,0.2v0.1c-0.6,0.1-0.4,1,0.6,1.2c0-0.2-0.1-0.1,0,0c-0.2-0.2-0.8-0.6-0.8-0.9v-0.1
				c0-0.2-0.5-0.2-0.8-0.3c-0.7-0.3-0.6-0.8,0.2-0.6c0.4,0.1,0.6,0.4,0.7,0.1c0.1-0.4-0.6-1.3-1.5-0.8c-0.2,0.1-0.5,0.2-0.7,0.3
				c-0.3,0.1-0.4,0.5-0.4,0.9c-0.4,0-0.3,0.2,0,0.4c0.1,0.6,0.6,0.6,1.1,1.3c-0.6-0.2-1.1-0.4-1.3-0.6c0,0,0-0.1-0.1-0.1
				c0.1-0.1,0.1-0.2-0.2-0.4c0,0-0.1-0.1-0.2-0.2c-0.3-0.6-0.7-0.6-0.7-0.3c-0.6-0.2-1.3-0.3-1.9-0.3c-0.1-0.2-0.2-0.3-0.1-0.5
				c0.3-0.5-0.2-1.2-0.7-1c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1v-0.1c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.5-0.3-0.9-0.9-0.3
				c-0.2,0-0.4,0-0.5-0.1c-0.8-0.9-1.1-0.7-1-0.4c-0.3-0.1-0.8-0.1-1.1,0c-0.6-0.4-1.2,0.4-1.1,1c-0.2,0-0.3,0-0.5,0
				c-1.2-0.7,1.3-0.9,0.1-1c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0-1-0.1-1,0.1c0,0.2,0.7,0.2,1.2,0.6c0.1,0.1,0.3,0.2,0.4,0.3
				c-0.3,0.3,0.4,0.5,0.9,0.4c0.1,0.1,0.2,0.2,0.4,0.3C65.1,14,65,14,65.2,14.4c-0.4,0-0.9,0-1.3-0.1c-0.1-0.1-0.2-0.1-0.3-0.1
				c-0.6-0.1-1.1-0.4-1.3-0.7c-0.6-1-2.3-0.4-1.9,0.7c0.1,0.5,0,0.6-0.4,0.5c0.8-0.4,0-1.1-0.6-0.2c-0.8-0.5-1-0.3-0.8,0.5
				c-0.1-0.3-1.1,0.4-0.8,0.6c0,0,0,0.1,0.1,0.1c-0.6-0.3-1.5,0-2.4,0.8c0-0.4-0.5-0.8-0.8-0.6c-0.5,0.3,0.2,1.1-0.5,1.4
				c-0.1,0-0.2,0.2-0.2,0.3c-0.6,0.3-1.2,0.5-1.6,0.4c0.3-0.2,0.6-0.4,0.4-0.6c-0.2-0.2-0.5,0.1-0.8,0.1c-0.7,0.1-1-0.5-0.3-0.6
				c0.2,0,0.5,0.1,0.6-0.1c0.2-0.4-1.4-1.2-1.7-0.3c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.4,0.6,1.4,1.2,1.7c0,0.1-0.1,0.2-0.2,0.4
				c-2.4-1.3-1.7-0.1-0.1,0.2c-0.1,0.2-0.2,0.4-0.4,0.6c-0.4,0-1.8,0.1-1.8,0.5s0.6,0.7,1.2,0.8c-0.4,0.6-0.9,1.3-1.5,2.1
				c-0.3-0.3-0.6-1-0.4-1c0.7-0.1,0.3-0.5,0.3-0.8c0-0.4,0-0.5,0.1-1.1c0.3-0.9-1.1-1-2.2-0.7c-0.3,0.1-1.1,0.1-1.2-0.2
				c-0.1-0.3,1.4-0.8,0.9-1.1c-0.5-0.3-1.4,0.7-2.2,0.4c-0.2-0.1-1,0.2-1,0.6c0,0.3,1.7,1.3,1.2,0.6c-0.1-0.2-0.4-0.4-0.3-0.5
				c0.3-0.3,2.1,0.7,2,1.1c0,0.1-0.9,0.2-1.2,0.3c-0.8,0.3-0.5,0.4,0.1,0.3c0.5,0,0.9-0.1,0.9,0.4c0,0.2,0.3,0.3,0.4,0
				c0.1-0.3,0.1-1.1,0.6-1c0.7,0.1,0.8,1.4,1,2c0.1,0.5,0.3,0.9,0.6,1.2c-1.1,1.5-2.4,3.1-3.8,4.6c-2.1,2.5-1.4,3.9-3.2,7
				c0.2,0.2,0.4,0.4,0.5,0.7c-0.1,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.1-0.1c0.4-0.3-0.2-0.2-0.7,0s-0.5,0.3,0.1,0.3
				c0.5,0,0.5,0,0.2,0.2c-0.2,0.1-0.6,0.1-0.9,0c-0.4-0.1-0.5,0-0.5,0.2s-0.1,0.3-0.3,0.2c-0.2,0-0.4,0.1-0.5,0.3
				c-0.3,0.9-0.4,1-0.5,1s-0.2,0.1-0.3,0.3c-0.2,0.5-0.8,0.5-0.7,0c0.1-0.2-0.1-0.4-0.4-0.4c-0.3-0.1-0.5-0.2-0.4-0.3
				c0.1-0.3-0.4-0.3-0.8,0c-0.3,0.3-0.3,0.3,0.2,0.3c0.3,0,0.6,0.2,0.8,0.4s0.2,0.4,0.1,0.3c-0.2,0-0.3,0.1-0.2,0.4
				c0,0.4-0.1,0.5-0.3,0.5c-0.3,0-0.4,0.2-0.3,0.4c0,0.3-0.2,0.5-0.8,0.7c-0.7,0.2-0.8,0.3-0.6,0.5c0.3,0.2,0.3,0.2-0.3,0.2
				c-0.2,0-0.3,0.1-0.4,0.3c-0.1,0.4-0.5,0.7-0.8,0.5c-0.1-0.1-0.1,0-0.1,0.1c0.1,0.1,0,0.2-0.2,0.3c-0.4,0.1-0.5,0.5-0.2,0.7
				c0.1,0.1,0,0.1-0.2,0.1c-0.4-0.1-0.4,0-0.4,0.5c0,0.3,0,0.5-0.1,0.4c-0.2-0.4-0.7-0.4-0.8-0.1c0,0.2-0.3,0.5-0.5,0.7
				c-0.5,0.5-0.6,0.6-0.2,0.5c0.1,0,0.2,0,0.2,0.1s-0.1,0.1-0.3,0.1c-0.3-0.1-1.2,0.8-1,1c0,0.1-0.1,0.1-0.3,0.1
				c-0.2,0-0.4,0.1-0.4,0.2c0,0.2-0.2,0.3-0.5,0.3s-0.4-0.1-0.3-0.2c0.1-0.1,0.1-0.2-0.1-0.2c-0.4-0.1-0.4,0.3,0,0.5
				c0.2,0.1,0.3,0.2,0.2,0.3c-0.1,0.1-0.4,0-0.7-0.2c-0.4-0.2-0.7-0.3-1.2-0.2c-0.8,0.2-0.8,0.3,0,1l0.7,0.6l-0.6,0.3
				c-0.5,0.2-0.6,0.3-0.4,0.7c0.2,0.3,0.2,0.5-0.1,0.9c-0.2,0.3-0.3,0.6-0.2,0.7c0.1,0.1,0,0.1-0.2,0S24.9,51.8,25,52
				c0,0.2-0.1,0.4-0.4,0.5S24,52.9,24,53.1c0,0.2-0.3,0.5-0.5,0.6c-0.3,0.1-0.4,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.4s-0.2,0.2-0.6,0
				c-0.5-0.2-0.5-0.2-0.5,0.2c0,0.3-0.2,0.4-0.8,0.5c-0.7,0.1-0.9,0.2-1,0.6c-0.1,0.5-0.2,0.5-0.6,0.4c-0.6-0.2-0.7,0.1-0.2,0.6
				c0.4,0.5,0.3,1.3-0.2,1.2c-0.2,0-0.3,0-0.2,0.1c0.1,0.1-0.1,0.3-0.4,0.4c-0.4,0.2-0.5,0.5-0.6,0.9c0,0.3-0.2,0.7-0.3,0.8
				c-0.2,0.1-0.3,0.2-0.2,0.2c0.1,0-0.1,0.3-0.5,0.6c-0.3,0.3-0.6,0.7-0.5,0.9s-0.2,0.4-0.7,0.6c-0.4,0.1-0.6,0.2-0.4,0.2
				c0.2,0,0.3,0,0.3,0.1s-0.2,0.2-0.4,0.2c-0.3,0-0.4,0.1-0.2,0.3s0.2,0.4-0.1,0.6c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0,0.4-0.2,0.2
				c-0.2-0.2-0.2-0.1-0.1,0.2c0.1,0.3,0,0.4-0.5,0.7c-0.7,0.3-0.9,0.7-0.5,0.8c0.1,0,0.2,0.1,0.2,0.2c-0.2,0.3-0.1,0.6,0.2,0.5
				c0.3,0,0.3,0,0,0.1c-0.2,0.1-0.4,0.4-0.5,0.7c-0.1,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.3,0.4s0.3,0,0.3-0.1s0.3-0.2,0.6-0.2
				c0.5-0.1,0.5-0.1,0.2,0.1c-0.2,0.1-0.3,0.2-0.2,0.2s0,0.1-0.1,0.2c-0.2,0.1-0.2,0.2-0.2,0.3c0.1,0.1,0,0.1-0.1,0.1
				s-0.3,0.1-0.3,0.2c0,0.2,0.1,0.3,0.3,0.2c0.3,0,0.3,0,0.1,0.2c-0.2,0.1-0.3,0.3-0.2,0.4S13,71,12.8,71.1s-0.4,0.3-0.4,0.4
				s-0.1,0.2-0.3,0.2c-0.5-0.1-1.2,0.3-1.1,0.5c0.1,0.1,0.1,0.4,0,0.5c-0.1,0.2,0,0.3,0.1,0.3c0.3,0.1,0.2,0.6-0.2,0.8
				c-0.1,0.1-0.2,0.3-0.2,0.6c0,0.2,0,0.3-0.1,0.2c-0.3-0.3-1,0.6-0.9,1.2c0.1,0.4,0,0.5-0.3,0.6C9,76.5,9,76.6,9.3,76.9
				c0.3,0.3,0.2,0.4-0.1,0.1c-0.2-0.2-0.4-0.3-0.5-0.2c-0.3,0.2-0.1,0.7,0.2,0.7c0.1,0,0.3,0.1,0.3,0.2c0,0.2,0,0.2-0.1,0.1
				c-0.4-0.3-0.8-0.1-0.5,0.2c0.1,0.2,0.1,0.3-0.1,0.3c-0.3,0.1-0.5,0.5-0.1,0.6c0.1,0,0.3-0.1,0.3-0.2s0.2-0.2,0.3-0.2
				c0.4,0.1,0.2,0.3-0.4,0.7c-0.7,0.4-0.7,0.5-0.1,0.6c0.5,0.1,0.6,0.4,0.2,0.5c-0.3,0.1-0.4,0.7-0.2,0.8c0.1,0,0.2,0.2,0.2,0.3
				c0,0.2,0,0.2-0.1,0.2c-0.1-0.1-0.3-0.1-0.5-0.1C7.8,81.6,7.8,81.7,8,82c0.1,0.2,0.1,0.5,0,0.7s-0.1,0.4,0,0.7
				c0.1,0.3,0,0.5-0.4,0.9c-0.5,0.5-0.5,0.5-0.2,0.7s0.3,0.2-0.1,0.3c-0.3,0.1-0.4,0.2-0.2,0.3c0.2,0.2-0.1,0.9-0.5,1.1
				c-0.2,0.1-0.1,0.3,0.2,0.7c0.5,0.6,0.6,1,0.2,0.9c-0.1,0-0.2-0.1-0.2-0.2s-0.1-0.2-0.2-0.2c-0.4-0.1-0.3,0.4,0.1,0.7
				c0.5,0.4,0.5,0.8-0.1,0.6c-0.4-0.2-0.4-0.2,0,0.1c0.4,0.3,0.3,1.2-0.1,1.3c-0.1,0-0.3,0.2-0.4,0.4C6,91.2,6,91.3,6.2,91.3
				s0.3,0.1,0.3,0.1c0,0.1-0.1,0.1-0.3,0.1c-0.1,0-0.2,0-0.2,0.2c0,0.1-0.1,0.4-0.4,0.7c-0.6,0-0.6,0.1-0.3,0.1
				c0.3,0.1,0.3,0.2,0.1,0.6c-0.1,0.3-0.3,0.6-0.4,0.6s0,0.3,0.2,0.6C5.5,94.8,5.5,95,5.4,95c-0.2,0-0.2,0.1-0.2,0.2s0,0.3-0.2,0.3
				c-0.1,0-0.1,0.3,0,0.5c0.3,0.5,0.3,0.5-0.1,0.3c-0.3-0.1-0.4-0.1-0.3,0s0,0.2-0.1,0.2c-0.2-0.1-1.3,0.3-1.3,0.5
				c0,0,0.1,0.1,0.3,0.2c0.2,0,0.4,0,0.4-0.1s0.2-0.2,0.4-0.2c0.3,0,0.4,0.1,0.2,0.4c-0.1,0.2-0.1,0.4,0,0.4
				c0.3,0.1,0.4,0.7,0.1,0.8c-0.1,0-0.2,0.2-0.2,0.3c0.1,0.2,0,0.2-0.3,0.1c-0.2-0.1-0.4-0.3-0.4-0.6c0-0.4-0.1-0.4-0.3-0.2
				c-0.3,0.3-0.3,0.5,0,0.5c0.1,0,0.2,0.1,0.2,0.2s0.1,0.3,0.3,0.4c0.3,0.2,0.3,0.2,0,0.4c-0.3,0.2-0.3,0.3,0.1,0.6
				c0.3,0.2,0.4,0.4,0.2,0.4c-0.1,0-0.3,0.1-0.3,0.2c0,0.2-0.1,0.2-0.4,0c-0.2-0.2-0.5-0.2-0.8-0.2c-0.6,0.1-0.6,0.3,0,0.4
				c0.6,0,0.9,0.4,0.8,0.9c0,0.2-0.2,0.3-0.8,0.1c-0.9-0.3-1.5,0.1-1.2,0.6s1.2,0.7,1.4,0.4c0.2-0.4,0.4-0.4,1.2,0.1
				c0.9,0.5,1.3,1.4,0.6,1.4c-0.5,0-0.6,0.5-0.1,0.5c0.5,0.1,0.5,0.5,0.1,0.9c-0.4,0.3-0.4,0.3-0.5,0c-0.1-0.2-0.3-0.3-0.5-0.4
				c-0.3,0-0.4-0.1-0.2-0.3c0.2-0.3,0-0.3-0.7,0.1c-0.8,0.3-0.9,0.4-0.6,0.6c0.2,0.1,0.2,0.4,0.2,0.5c-0.2,0.4,0.3,0.7,0.5,0.3
				c0.2-0.3,0.2-0.3,0.5,0c0.2,0.3,0.3,0.3,0.5,0c0.3-0.4,0.7-0.4,0.4,0.1c-0.1,0.2-0.1,0.4,0.2,0.5c0.2,0.1,0.5,0.3,0.6,0.6
				c0.2,0.3,0.1,0.4-0.4,0.5c-0.4,0.1-0.6,0.1-0.7-0.1c-0.1-0.1-0.2-0.2-0.4-0.1c-0.1,0.1-0.1,0.2,0.1,0.2c0.6,0.3,1.2,0.9,1,1.1
				c-0.1,0.1-0.4-0.1-0.8-0.5c-0.8-0.9-1.3-0.8-1,0.2c0.2,0.5,0.4,0.7,0.8,0.9c0.4,0.2,0.6,0.1,0.5,0c0-0.1,0.1-0.2,0.2-0.2
				c0.5,0.1,0.5,0.6,0,1.2c-0.3,0.3-0.4,0.6-0.3,0.7s0.2,0.1,0.3,0c0.1-0.1,0.1,0,0,0.1s0,0.3,0.2,0.4s0.2,0.4,0.1,0.5
				s0,0.6,0.3,0.9c0.4,0.5,0.4,0.8,0.2,1.1c-0.3,0.5-0.3,0.7,0.3,0.6c0.5,0,0.3,0.5-0.2,0.6c-0.4,0.1-0.4,0.1,0,0.4
				c0.5,0.4,0.8,0.5,0.7,0.2c-0.1-0.1,0-0.2,0.1-0.1c0.4,0.1,0.5,0.4,0.2,0.5c-0.4,0.1-0.4,0.5,0,0.5c0.3,0,0.4,0.1,0.2,0.3
				C6.4,118,6.2,118,5.9,118c-0.3-0.1-0.4-0.1-0.2,0.3c0.1,0.2,0.3,0.4,0.5,0.3c0.1,0,0.2,0.1,0,0.2c-0.1,0.1-0.1,0.3,0,0.3
				s0.2,0.2,0.1,0.5c0,0.2-0.2,0.4-0.3,0.4c-0.5-0.1-0.4,0.6,0.1,0.8c0.4,0.2,1.1,1.3,1,1.7c0,0.1-0.4,0.2-0.8,0.3
				c-0.4,0.1-0.8,0.2-0.8,0.3c0,0.2,0.6,0.3,0.8,0.1c0.1-0.1,0.2-0.1,0.4,0.1c0.1,0.2,0.1,0.3-0.1,0.4s-0.1,0.2,0.3,0.3
				c0.5,0.1,0.6,0.2,0.4,0.4c-0.2,0.3,0.3,1.4,0.6,1.3c0.1,0,0.2,0,0.2,0.1c-0.2,0.4-0.1,0.7,0.1,0.7c0.1,0,0.2,0,0.1,0.2
				c-0.1,0.1-0.1,0.4,0,0.6c0.1,0.3,0.2,0.3,0.2,0.1c0.1-0.2,0.1-0.2,0.2,0c0.1,0.2,0.1,0.3,0,0.4c-0.4,0.3-0.4,0.7,0,0.9
				s0.8,1.4,0.5,1.6c-0.1,0,0,0.2,0,0.4c0.1,0.2,0.1,0.4-0.2,0.4c-0.2,0-0.3,0.1-0.1,0.2c0.1,0.1,0.2,0.2,0.1,0.4
				c-0.1,0.1-0.1,0.2,0.1,0.3c0.1,0,0.2,0.1,0.1,0.3c-0.1,0.1,0,0.3,0.2,0.5c0.3,0.3,0.4,0.4,0.1,0.6c-0.4,0.4-0.6,0.4-0.8,0
				c-0.2-0.3-0.6-0.2-0.7,0.2c-0.1,0.4,0.6,0.6,1.1,0.4c0.2-0.1,0.2,0,0.1,0.3c-0.2,0.4-0.2,0.4,0.3,0.2c0.6-0.2,0.9-0.1,0.8,0.3
				c0,0.5,0.2,1,0.4,1c0.1,0,0.3,0.2,0.4,0.4c0.2,0.4,0.1,0.4-0.1,0.2c-0.1-0.2-0.4-0.3-0.6-0.3c-0.3,0-0.3,0-0.1,0.1
				s0.2,0.2-0.1,0.3c-0.3,0.2-0.3,0.2,0,0.5c0.2,0.2,0.5,0.4,0.7,0.4s0.3,0.1,0.3,0.2c0,0.1,0.1,0.2,0.3,0.2c0.3,0,0.3,0,0.1,0.2
				s-0.2,0.4,0.1,0.7c0.2,0.2,0.3,0.4,0.2,0.4s0.1,0.1,0.4,0.4c0.5,0.3,0.5,0.4,0.3,0.7c-0.3,0.2-0.3,0.3,0,0.2c0.2,0,0.2,0,0.1,0.2
				c-0.2,0.2-0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.5,0.5,0.6c0.1,0.2,0.2,0.3,0.3,0.3s0.3,0.1,0.4,0.3c0.2,0.4,0,0.4-0.7,0
				c-0.3-0.2-0.4-0.2-0.6-0.1c-0.3,0.3,0,0.5,0.4,0.3c0.3-0.1,0.4-0.1,0.1,0.4c-0.2,0.4-0.2,0.5,0.2,0.8c0.4,0.2,0.5,0.2,1,0
				c0.7-0.4,0.9-0.2,0.3,0.2c-0.3,0.2-0.4,0.5-0.4,0.7c0.1,0.5,0.8,0.8,1.4,0.6c0.2-0.1,0.4-0.1,0.5,0c0.1,0.1,0,0.1-0.1,0.1
				c-0.5-0.1-0.4,0.2,0.2,0.6c0.3,0.2,0.5,0.4,0.5,0.5s-0.2,0-0.4-0.2c-0.2-0.2-0.6-0.4-0.9-0.5c-0.4-0.1-0.5-0.1-0.3,0.3
				c0.1,0.4,0.3,0.5,0.8,0.5c0.4,0,0.7,0.2,0.9,0.4c0.1,0.2,0.1,0.3,0,0.2s-0.3-0.1-0.3,0.1c0,0.1,0.1,0.2,0.4,0.3
				c0.3,0,0.4,0.1,0.2,0.4c-0.1,0.2-0.4,0.3-0.5,0.3s-0.3,0-0.4,0s0,0.1,0.3,0.2c0.3,0.1,0.5,0.3,0.5,0.6s0,0.5-0.2,0.5
				c-0.6,0,0.4,1.5,1.1,1.7c0.4,0.1,0.9,1.2,0.7,1.4c0,0.1,0.2,0.3,0.4,0.5c0.3,0.2,0.5,0.5,0.5,0.7s0.1,0.4,0.3,0.5
				c0.2,0.1,0.3,0.3,0.3,0.5c-0.1,0.4,0.2,0.6,0.5,0.5c0.2-0.2,0.6-0.1,0.6,0.1c0,0.1-0.1,0.1-0.3,0c-0.1-0.1-0.2-0.1-0.2,0
				s0.1,0.2,0.2,0.3s0.1,0.3,0,0.4c-0.2,0.2-0.1,0.3,0.4,0.4c0.4,0.1,0.6,0.2,0.5,0.4c0,0.2,0.1,0.3,0.4,0.3s0.4,0.1,0.3,0.4
				c-0.1,0.3,0,0.4,0.4,0.5s0.4,0.1,0.2,0.2c-0.3,0.1-0.2,0.1,0.1,0.3c0.5,0.2,0.5,0.9,0,0.8c-0.2,0-0.4,0-0.4,0.2
				c-0.1,0.3,0.5,0.5,0.8,0.3s0.4-0.1,1,0.4c0.5,0.4,0.5,0.4,0,0.7c-0.4,0.2-0.5,0.2-0.1,0.4c0.2,0.1,0.5,0.1,0.7,0.1
				c0.3-0.1,0.4-0.1,0.3,0.1c0,0.1,0,0.3,0,0.4c0,0.1,0,0.4,0,0.6c-0.1,0.3,0,0.4,0.6,0.4s0.7,0.1,1,0.7c0.2,0.3,0.4,0.7,0.5,0.8
				s0.2,0.2,0.2,0.3c-0.1,0.4,0.2,1,0.5,1c0.2,0,0.3,0.2,0.2,0.5c-0.1,0.4,0,0.4,0.4,0.4c0.3,0,0.5,0.1,0.4,0.2
				c0,0.1-0.2,0.2-0.3,0.2c-0.7-0.1-1,0.1-0.9,0.6c0.1,0.6,0.5,0.8,1.5,0.6c0.3-0.1,0.5-0.1,0.4,0s-0.1,0.2,0,0.2s0.3,0,0.3-0.1
				s0.3,0.1,0.6,0.4c0.3,0.3,0.6,0.5,0.8,0.5c0.1,0,0.3,0.2,0.4,0.4c0.1,0.3,0.2,0.5,0.4,0.5c0.1,0,0.5,0.4,0.9,0.8
				c0.4,0.4,0.8,0.8,1.1,0.9c0.2,0.1,0.4,0.4,0.5,0.6c0.1,0.2,0.3,0.5,0.5,0.6s0.3,0.2,0.2,0.3c-0.3,0.1,0.2,0.8,0.5,0.8
				s0.3,0.1,0.2,0.5c-0.2,0.5-0.1,0.5,0.6,0.7c0.4,0.1,1,0.4,1.2,0.6c0.2,0.2,0.4,0.3,0.3,0.2c-0.1-0.1,0-0.3,0.1-0.3s0.2,0,0.2,0.1
				c0,0.2,2.5,3,2.8,3c0.1,0,0.2,0.2,0.2,0.3c-0.1,0.4,0.3,0.8,0.8,0.9c0.2,0,0.7,0.5,1,0.9c0.3,0.5,0.8,0.9,1,0.9
				c0.5,0.1,1.5,1.2,1.3,1.3c-0.1,0.1,0,0.1,0.3,0.1s0.6,0,0.7,0.1c0.1,0.1,0.4,0.3,0.7,0.4c0.3,0.1,0.6,0.3,0.6,0.4
				c0,0.1,0,0.1-0.1,0c-0.1-0.3-0.9-0.4-1.2-0.2c-0.2,0.2,0.2,0.5,0.6,0.5c0.2,0,0.4,0.2,0.5,0.6c0.2,0.5,0.8,1,1.4,1
				c0.5,0,1.4,0.7,1.4,1c-0.1,0.4,0.3,0.6,0.9,0.4c0.5-0.2,0.6-0.2,0.7,0.1c0.1,0.2,0.2,0.3,0.1,0.4c0,0,0.4,0.4,0.9,0.7
				c0.6,0.4,1,0.8,1.1,1.2s0.3,0.6,0.7,0.6s0.5,0.1,0.5,0.3c0,0.1,0.1,0.3,0.4,0.4c0.2,0.1,0.5,0.2,0.6,0.4c0.1,0.1,0.2,0.2,0.3,0.2
				c0.5,0,0.8,0.2,0.6,0.4c-0.1,0.1,0,0.2,0.4,0.3c0.3,0,0.7,0.2,0.8,0.4s0.3,0.3,0.5,0.3s0.4,0.2,0.6,0.5c0.2,0.2,0.4,0.4,0.6,0.4
				c0.1,0,0.2,0,0.2,0.1c-0.1,0.3,0.6,1,0.9,1c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0.3,0.2,0.6,0.3c0.4,0.1,0.8,0.3,0.9,0.4
				c0.1,0.2,0.4,0.3,0.6,0.3s0.2,0.1,0.1,0.3s-0.1,0.4,0.4,0.5c0.7,0.3,1.2,0.2,1-0.1c-0.1-0.1,0-0.1,0.2-0.1
				c0.2,0.1,0.3,0.3,0.2,0.4c-0.1,0.1,0,0.3,0.1,0.3s0.2,0,0.3-0.2c0-0.1,0.1,0,0.2,0.2s0.3,0.4,0.4,0.4c0.4,0,1.3,0.5,1.3,0.7
				c0,0.1-0.2,0.1-0.4-0.1c-0.3-0.3-0.3-0.3-0.1,0c0.2,0.3,0.8,0.5,1.7,0.7c0.2,0.1,0.3,0.2,0.2,0.2c-0.1,0.1-0.3,0.1-0.4,0
				c-0.1,0-0.5-0.1-0.8-0.2c-0.5-0.1-0.5,0-0.4,0.3s0,0.5-0.3,0.8c-0.4,0.3-0.4,0.4-0.2,0.7c0.1,0.2,0.4,0.3,0.7,0.3
				c0.3,0,0.4-0.1,0.2-0.5c-0.1-0.2-0.1-0.5,0-0.5s0.2,0,0.3,0.1c0.1,0.4,0.5,0.4,0.4,0s0.3-0.4,0.6,0c0.2,0.2,0.3,0.3,0.6,0.2
				c0.3-0.1,0.3,0,0.3,0.3c-0.1,0.4,0,0.3,0.4-0.3c0.1-0.2,0.2-0.2,0.3,0.1c0.1,0.2,0.1,0.3,0,0.4c-0.1,0.1,0,0.1,0.2,0.2
				c0.7,0.1,0.9,0.2,0.8,0.4c-0.1,0.1-0.1,0.2,0,0.3c0.1,0,0.2,0,0.3-0.1c0.1-0.2,0.8,0,1.1,0.3c0.2,0.2,0.7,0.4,1.1,0.5
				s0.7,0.3,0.7,0.4c0,0.1,0.2,0.3,0.5,0.3c0.2,0,0.3,0.1,0.4,0.1c-0.1,0.3-0.2,0.6-0.3,0.8c-0.6,1.7-1.2,3.6-2.1,5.2
				c-0.1,0.2-0.2,0.4-0.3,0.5c-2.9,11.5-4,15.9-3.3,16.8c5.7,7.2,6.8-9.5,9.2-16c0.1,0,0.1,0,0.2,0c0.2,0.3-0.2,0.9-0.1,1.2
				c0,0.1,0.3,0.3,0.4,0.1c0.3-0.9,0.8-2.1,0.1-2.5c0.9-1.6,1.8-1.7,3.3-0.9c1.6,0.9,0.7,2.9,0.7,4.8c-0.3-0.1-0.7,0.5-0.5,0.6
				c0.1,0.1,0.4-0.1,0.5-0.3c0.1,3.6,2.9,4.8,5.5,2.1c0.2,0,0.4-0.2,0.3-0.4c0.4-0.4,0.8-0.8,1.2-1.2c0.3,0.1,0.6,0,0.3-0.3
				c0.4-0.4,0.9-0.7,1.3-0.9l0.1,0.1c0.1,0,0.3,0,0.3-0.1s0-0.1-0.1-0.2c0.5-0.3,0.9-0.4,1.3-0.5c-0.6,0.4,1,0.8,1,0.4
				c0.5,0.4,2.1,0.9,5.3,1.5c0.1,0.2,0.5,0.3,0.4,0.1c0.1,0,0.2,0,0.4,0.1c0,0.2,0.3,0.3,1.8,0.8c0.4,0.1,0.8,0.3,0.9,0.4
				c0.1,0.1,0.5,0.2,0.8,0.3c0.9,0.2,2.1,0.7,2.2,0.9c0.1,0.1,0.3,0.1,0.6,0.1c0.4-0.1,0.5,0,0.6,0.2c0,0.2,0.2,0.3,0.4,0.4
				c0.3,0.1,0.3,0,0.1-0.3c-0.2-0.3-0.2-0.3,0.3,0.1c0.3,0.2,0.7,0.4,0.8,0.4c0.1,0,0.3,0,0.3,0.2c0.1,0.1,0.2,0.2,0.4,0.1
				c0.5-0.1,2.1,0.1,2.2,0.3c0.1,0.1,0,0.1-0.2,0s-0.2-0.1-0.2,0c0.1,0.1,0.3,0.3,0.6,0.3c0.3,0.1,0.4,0,0.4-0.2
				c-0.1-0.3-0.1-0.3,0.2,0c0.2,0.3,0.3,0.3,0.6,0.2c0.3-0.1,0.4-0.1,0.5,0.2s0.2,0.3,0.4,0.2c0.1-0.1,0.5-0.1,0.7-0.1
				c0.4,0.1,0.4,0.2,0.3,0.3s-0.1,0.3,0,0.4c0.1,0.1,0.2,0,0.3-0.1c0-0.2,0.3-0.2,0.6-0.1c0.3,0.1,0.8,0.1,1.2,0.2
				c0.5,0,0.6,0.1,0.5,0.2c-0.2,0.1,0.2,0.4,0.8,0.6c0.2,0,0.3,0,0.2-0.2c-0.1-0.2,0.2-0.2,1.5,0.1c0.9,0.2,1.5,0.4,1.3,0.4
				c-0.3,0.1-0.3,0.4-0.1,0.6c0.1,0.1,0.2,0.1,0.2-0.1c0-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.3-0.1,0.6,0.1c0.2,0.2,0.4,0.3,0.6,0.2
				c0.1,0,0.2,0.1,0.2,0.2c-0.1,0.3,0,0.3,0.4,0.1s0.4-0.2,0.2,0c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.1-0.2,0.1-0.4,0.1
				c-0.2,0-0.4,0-0.4,0.1s0.1,0.2,0.4,0.2c0.2,0,0.5,0.1,0.5,0.2c0.2,0.4,1.4,0.3,1.5,0c0.1-0.4,0.5-0.5,0.7-0.2
				c0.1,0.1,0.4,0.3,0.7,0.3c0.3,0.1,1.2,0.3,1.9,0.5c1.3,0.3,1.3,0.3,1.1,0.7s-0.2,0.4,0.3,0.2c0.3-0.1,0.7-0.1,0.9,0
				s0.4,0.1,0.5-0.1c0-0.2,0.1-0.1,0.3,0.1c0.2,0.1,0.6,0.3,1,0.4c0.4,0.1,0.6,0.2,0.6,0.3c-0.1,0.1,0.1,0.1,0.5,0.1
				c0.8,0,1.3,0.1,1.3,0.3c0,0.1-0.1,0.1-0.3,0c-0.4-0.3-0.3,0.1,0.1,0.6c0.5,0.6,0.7,0.6,1.7,0.1c0.7-0.3,0.9-0.4,1.1-0.2
				c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.2,0.4,0.5c0.3,0.7,0.5,0.9,1,0.9c0.2,0,0.5,0.1,0.6,0.3l0,0c0.3,0.4,0.9,0.5,1.2,0.1
				c0.1-0.2,0.4-0.3,0.7-0.3s0.5,0,0.6-0.1s0.3-0.1,0.5,0s0.6,0,0.7-0.1c0.2-0.1,0.3-0.1,0.3,0c-0.1,0.3,0.3,0.3,0.8,0.1
				c0.2-0.1,0.8-0.1,1.2-0.1c0.5,0.1,0.8,0,0.7-0.1c0-0.1,0.1-0.2,0.3,0c0.3,0.2,0.3,0.1,0.1-0.1c-0.2-0.2-0.1-0.3,0.2-0.3
				c0.2,0,0.3,0.1,0.3,0.1c-0.1,0.1,0,0.2,0.2,0.3s0.3,0,0.4-0.2c0-0.2,0.2-0.3,0.3-0.2s0.3,0.1,0.4-0.1c0.1-0.1,0.5-0.3,0.9-0.3
				c0.6-0.1,0.8,0,1.1,0.2c0.2,0.2,0.6,0.3,0.9,0.3c0.5,0,0.2-0.2-0.5-0.3c-0.2,0-0.3-0.1-0.3-0.2c0-0.2,0.4-0.2,0.8,0
				c0.1,0,0.4,0,0.6-0.1c0.3-0.1,0.5,0,0.3,0.1s0.5,0.7,0.8,0.7s0.4-0.2,0.3-0.5c0-0.5,0-0.5,0.5-0.3c0.4,0.2,0.6,0.2,0.6,0.1
				c0-0.1,0.2-0.1,0.5,0s0.6,0.1,0.7,0c0.2-0.2,0.4-0.1,0.9,0.1c0.8,0.4,0.8,0.4,0.7,0.1c-0.1-0.2,0-0.3,0.3,0
				c0.3,0.2,0.6,0.2,0.8,0.2c0.5-0.1,0.7,0,0.7,0.2c-0.1,0.3,0.4,0.4,0.5,0.1c0-0.2,0.1-0.2,0.2-0.1c0,0.1,0.3,0.3,0.5,0.3
				c0.3,0.1,0.4,0,0.4-0.2c-0.1-0.2,0-0.2,0.3-0.2c0.2,0.1,0.5,0.1,0.7,0.1s0.4,0.1,0.6,0.2c0.3,0.3,0.9,0.4,0.8,0.1
				c-0.1-0.1,0.1-0.1,0.4,0c0.4,0.1,0.5,0.1,0.6-0.1c0-0.2,0.1-0.2,0.4,0.2c0.2,0.3,0.4,0.5,0.6,0.4c0.1,0,0.1-0.1-0.1-0.1
				c-0.2,0-0.3-0.1-0.2-0.2s0.1-0.1,0.3,0c0.2,0.2,1.6-0.2,1.4-0.3c0-0.1,0.2,0,0.6,0.1c0.4,0.1,0.6,0.2,0.5,0.3s-0.1,0.1,0,0.1
				s0.3,0.1,0.5,0.2c0.3,0.2,0.4,0.1,0.4-0.3c0-0.4,0-0.4,0.5-0.3c0.3,0.1,0.6,0.3,0.6,0.4c0,0.1,0.2,0.3,0.3,0.3s0.2-0.1,0.2-0.2
				c-0.1-0.4,0.6-0.3,1.3,0.1c0.7,0.4,0.7,0.4,0.6,0c0-0.3,0-0.4,0.5-0.2c0.3,0.1,0.7,0.2,0.9,0.1c0.2,0,0.4,0,0.5,0.2
				s0.2,0.2,0.2,0c0.1-0.3,1.2-0.3,1.5-0.1c0.1,0.1,0.2,0.1,0.1-0.1c-0.1-0.2,0-0.2,0.3-0.1c0.2,0.1,0.7,0.1,1,0.1
				c0.5-0.1,0.6,0,0.7,0.3c0.1,0.2,0.2,0.3,0.3,0.3c0.1-0.1,0.1-0.2,0-0.3c-0.4-0.3-0.2-0.5,0.3-0.3c0.3,0.1,0.5,0.1,0.6-0.1
				c0.1-0.2,0.4-0.3,0.8-0.2c0.3,0,0.6,0,0.6-0.2c0-0.1,0.1-0.2,0.2-0.1c0.1,0.1,0.7,0.1,1.3,0s1.2-0.1,1.3,0
				c0.2,0.1,0.4,0.1,0.5-0.1c0.2-0.3,0.6-0.2,0.5,0.1c-0.1,0.4,0.4,0.5,0.5,0.1c0.1-0.3,0.2-0.3,0.3-0.1s0.3,0.2,0.7,0
				c0.6-0.3,1.5-0.1,1.1,0.3c-0.1,0.1,0.1,0,0.5-0.2s1-0.3,1.3-0.3s1,0,1.6-0.1c0.5-0.1,1.1-0.2,1.3-0.1c0.2,0.1,0.5,0.1,0.7-0.1
				c0.3-0.2,0.4-0.2,0.6,0c0.2,0.3,0.6,0.1,0.4-0.3c-0.1-0.2,0.2-0.3,0.7-0.2c1.2,0.1,1.8,0.3,1.9,0.5c0,0.2,0.2,0.2,0.7,0.2
				c0.6-0.1,0.7,0,0.6,0.2c-0.1,0.4,0.4,0.6,0.8,0.4c0.4-0.3,0.3-0.5-0.3-0.7c-0.3-0.1-0.4-0.2-0.3-0.3c0.1-0.1,0.3-0.1,0.5-0.1
				c0.2,0.1,0.4,0,0.5-0.2c0.2-0.3,0.2-0.3,0.4,0c0.1,0.2,0.3,0.3,0.4,0.3c0.1,0,0.2-0.1,0.1-0.2c-0.2-0.3,0.6-0.5,0.9-0.3
				c0.3,0.2,0.7,0.2,0.7,0c0-0.1-0.2-0.2-0.4-0.2c-0.3-0.1-0.3-0.1,0-0.2c0.2,0,0.5,0,0.7,0.1s0.5,0,0.7-0.1
				c0.5-0.3,0.9-0.3,0.8,0.1c0,0.1,0.1,0.3,0.2,0.3s0.3-0.1,0.3-0.3s0.3-0.4,0.6-0.5c0.5-0.2,0.6-0.1,0.5,0.2c0,0.1,0.2,0.1,0.6,0
				c0.6-0.1,0.6-0.1,0.9,0.4c0.4,0.7,1.2,0.8,1.6,0.2c0.2-0.3,0.2-0.4,0-0.5c-0.1,0-0.1-0.1,0.1-0.2c0.2-0.1,0.5-0.2,0.7-0.1
				c0.2,0,0.4-0.1,0.5-0.2c0.1-0.2,0.3-0.2,0.5-0.2c0.1,0.1,0.4,0,0.7-0.1c0.2-0.1,0.7-0.1,1-0.1c1.2,0.2,1.7,0.1,1.8-0.2
				c0.1-0.4,0.3-0.5,0.8-0.2c0.4,0.2,0.4,0.2,0.3,0c-0.1-0.2,0-0.3,0.3-0.2c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0.1,0.2,0.3,0.2
				s0.4,0,0.4-0.1s-0.1-0.2-0.2-0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.3-0.1,0.5-0.1c0.3,0.1,0.5,0,0.4-0.2c0-0.2,0-0.3,0.2-0.2
				c0.1,0,0.2,0,0.1-0.1c-0.1-0.1,0-0.2,0.1-0.1c0.1,0,0.2,0.2,0.2,0.3s0.1,0.3,0.3,0.4c0.2,0.1,0.3,0.1,0.3-0.1
				c0-0.4,0.7-0.9,1-0.9c0.4,0.1,1.9-0.4,1.7-0.6c-0.1-0.1-0.3-0.1-0.5,0s-0.4,0.1-0.4,0s0.4-0.2,0.8-0.2c1,0,1,0,0.9,0.3
				c-0.1,0.4,0.5,0.8,0.9,0.5c0.1-0.1,0.2-0.3,0.2-0.5c0-0.4,0.3-0.8,0.5-0.6c0.3,0.2,1.2,0,1.3-0.3c0-0.1,0.2-0.2,0.4-0.2
				s0.4,0,0.4-0.2c0-0.1,0.2-0.2,0.3-0.1c0.1,0.1,0.2,0,0.3-0.1c0-0.1,0.1-0.1,0.2-0.2c-0.1,0.2-0.1,0.3-0.2,0.5
				c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.1s0-0.2-0.1-0.3c0.3-0.1,0.5-0.2,0.7-0.3
				c0.3,0.2,0.4,0.2,0.5-0.2l0,0c0.1,0,0.3-0.1,0.4-0.1h0.1c0.4,0.2,0.5,0.2,0.4-0.2c0,0,0,0,0-0.1c0.4-0.1,0.7-0.3,1.1-0.4
				c0.2,0.1,0.4,0.1,0.7-0.2s0.6-0.3,0.9-0.2c0.3,0.1,0.5,0.1,0.6-0.1c0-0.2,0.2-0.2,0.4-0.1c0.3,0.2,1-0.1,0.8-0.3
				c-0.1-0.1,0.1-0.2,0.4-0.2s0.6-0.2,0.6-0.3s0.2-0.2,0.3-0.1c0.1,0.1,0.4,0.1,0.6-0.1c0,0,0.1,0,0.1-0.1c0.3,0.6,0.6,1.7,0.7,3.1
				c1.4,9.8,11.2,8.4,10.2-1.5c-0.2-2.2,1.2-5.1,3.1-7.4c0,0,0.1,0,0.1,0.1c0.4,0.3,1.1,0.5,1.1,0.3c0-0.1-0.2-0.3-0.5-0.6l-0.3-0.2
				c0.2-0.2,0.3-0.4,0.5-0.6c0.4-0.1,0.7-0.3,0.7-0.4c0.1-0.2,1.3-0.8,1.5-0.6c0.1,0.1,0.3,0.1,0.4,0c0.2-0.2,0.3-0.2,0.4,0.1
				c0.2,0.3,0.7,0.3,0.6-0.1c0-0.1,0.1-0.3,0.2-0.3c0.2,0,0.3,0,0.2,0.2c0,0.1,0.1,0.1,0.3,0c0.3-0.2,0.5-0.2,0.7,0
				c0.3,0.3,0.7,0.3,1.2-0.2c0.6-0.6,0.9-1.1,0.6-1c-0.5,0.1-0.3-0.2,0.4-0.5c0.3-0.1,0.7-0.4,0.8-0.7c0.2-0.3,0.5-0.4,1.2-0.4
				s0.9,0,0.6-0.2c-0.5-0.3-0.4-0.5,0.3-0.6c0.3,0,0.7-0.2,0.8-0.4c0.3-0.5,1.2-1,1.4-0.8c0.1,0.1,0.2,0,0.2-0.1s0.3-0.4,0.6-0.5
				c0.3-0.2,0.6-0.3,0.6-0.4c0-0.1,0.2-0.1,0.3-0.1c0.8,0.2,2.3-0.9,1.6-1c0,0,0,0-0.1,0c0.1-0.1,0.2-0.1,0.3-0.2l0,0
				c0.2-0.1,0.4-0.2,0.3-0.3s0-0.2,0.2-0.2s0.2,0.2,0.1,0.5c-0.1,0.6-0.1,0.6,0.6,0.6c0.5,0,0.8,0.1,1,0.2c0.4,0.3,0.8,0.3,0.9-0.2
				c0.1-0.3,0-0.3-0.5-0.4c-0.4,0-0.6-0.1-0.7-0.3c0-0.2-0.2-0.3-0.4-0.3c-0.2,0-0.3-0.1-0.3-0.3s0.2-0.3,0.4-0.1
				c0.2,0.1,0.4,0.1,0.4-0.1c0-0.2-0.1-0.3-0.4-0.3s-0.4-0.1-0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0.1,0.4-0.1,0.8-0.4
				c0.4-0.3,0.9-0.5,1.1-0.5s0.3,0,0.2-0.1c-0.1-0.3,0.6-0.8,0.9-0.5c0.1,0.1,0.1,0.1,0-0.1c-0.1-0.1,0-0.3,0.2-0.4
				c0.2-0.1,0.3-0.3,0.2-0.4c-0.1-0.1,0-0.2,0.1-0.2s0.3-0.1,0.3-0.2c0-0.1,0.2-0.2,0.4-0.2c0.3,0.1,0.4,0,0.4-0.4
				c0-0.3,0.1-0.6,0.4-0.6c0.3-0.1,0.3-0.1,0.3,0.1c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.2-0.1,0.1-0.3c-0.1-0.3-0.1-0.4,0.2-0.6
				c0.2-0.1,0.5-0.1,0.7-0.1s0.3,0,0.3-0.1s-0.1-0.2-0.2-0.1c-0.1,0-0.2,0-0.1-0.1s0.4-0.2,0.7-0.2c0.3,0,0.6-0.2,0.6-0.3
				c0-0.2,0.1-0.3,0.3-0.2c0.2,0.1,0.3,0,0.3-0.2c-0.1-0.2,0-0.2,0.2-0.1c0.1,0.1,0.3,0.1,0.3,0c0.1-0.5,0.6-0.9,0.9-0.8
				c0.4,0.3,0.7,0,0.4-0.3c-0.2-0.2-0.2-0.4-0.1-0.5s0.2,0,0.3,0.2c0.1,0.4,0.1,0.4,0.5-0.7c0.2-0.6,0.4-0.9,0.7-0.9
				c0.2,0,0.3-0.1,0.3-0.2c-0.1-0.1,0.1-0.3,0.3-0.5c0.4-0.3,0.7-0.7,0.9-1.3c0-0.1,0.2-0.2,0.4-0.1c0.2,0,0.3-0.1,0.4-0.3
				c0-0.3,0.2-0.3,0.5-0.2c0.4,0.1,0.6-0.1,0.3-0.3c-0.1-0.1-0.2-0.2-0.2-0.3s0.1-0.1,0.1,0c0.1,0.4,0.4,0.3,0.5,0
				c0-0.2,0-0.3-0.2-0.3c-0.1,0-0.1,0,0-0.1s0.4-0.1,0.6-0.1s0.3,0,0.3-0.1c-0.1-0.1,0.1-0.2,0.3-0.3c0.2-0.1,0.4-0.3,0.5-0.5
				c0.1-0.6,0.4-0.8,0.9-0.5c0.4,0.3,0.9,0.3,0.6,0c-0.1-0.1-0.3-0.3-0.3-0.5c-0.1-0.3,0-0.3,0.2-0.2s0.4,0.1,0.4,0
				s-0.1-0.2-0.2-0.2c-0.4-0.1,0-0.6,0.5-0.8c0.3-0.1,0.4-0.2,0.3-0.2s0-0.1,0.1-0.2c0.2-0.1,0.4-0.4,0.5-0.6
				c0.1-0.2,0.3-0.5,0.4-0.5s0.1-0.2,0-0.4s-0.1-0.4,0-0.4c0.4-0.1,1-1.1,1-1.5c0-0.2,0.1-0.4,0.3-0.4c0.1,0,0.1-0.1,0-0.3
				c-0.2-0.3-0.2-0.3,0.1-0.2c0.2,0.1,0.7,0.1,1.1,0.1c0.4-0.1,0.6-0.1,0.4-0.2c-0.2,0-0.3-0.2-0.2-0.3c0-0.1-0.2-0.3-0.4-0.3
				c-0.6-0.1-0.5-0.4,0.2-0.5c0.5-0.1,0.6-0.2,0.4-0.3c-0.3-0.4,0.4-1,1-1c0.3,0,0.4-0.1,0.4-0.2c-0.1-0.1,0.1-0.4,0.2-0.6
				c0.2-0.2,0.5-0.8,0.7-1.3c0.4-1.2,0.6-1.5,0.9-1.4c0.1,0.1,0.3,0,0.3-0.1s0-0.2-0.1-0.2s-0.1-0.2-0.1-0.4s0.2-0.4,0.3-0.4
				c0.1,0,0.2-0.1,0.2-0.3s0.2-0.3,0.3-0.3c0.1,0,0.3-0.1,0.3-0.3s0.2-0.3,0.3-0.2c0.1,0.1,0.2,0.1,0.3-0.1c0-0.1,0.2-0.4,0.3-0.6
				c0.1-0.2,0.2-0.5,0.3-0.6c0-0.1,0.2-0.2,0.4-0.2c0.2,0,0.3-0.2,0.3-0.5s0.1-0.5,0.3-0.5c0.1,0,0.2-0.1,0.1-0.3
				c0-0.2,0.1-0.5,0.2-0.6s0.3-0.4,0.4-0.7c0.3-0.8,0.3-0.8,0.5-0.7c0.1,0.1,0.3,0.1,0.4,0s0.1-0.2,0-0.2c-0.4-0.1-0.3-0.7,0.1-0.9
				s0.6-0.8,0.9-2.3c0.1-0.4,0.2-0.7,0.3-0.7c0.2,0.1,0.4-1.2,0.3-1.5c-0.1-0.1,0-0.4,0.2-0.5s0.4-0.5,0.4-0.8
				c0.1-0.3,0.2-0.5,0.4-0.5c0.2,0,0.3-0.1,0.1-0.3c-0.2-0.2-0.1-0.3,0.2-0.6c0.3-0.2,0.4-0.4,0.3-0.5c-0.3-0.2,0-1.7,0.4-1.7
				c0.1,0,0.1-0.1-0.1-0.2c-0.3-0.2-0.3-0.2,0-0.2c0.2,0,0.3,0,0.2-0.1c-0.1-0.1,0-0.2,0.1-0.3c0.2-0.1,0.2-0.2,0-0.4
				c-0.3-0.3-0.3-0.3,0.1-0.5c0.3-0.1,0.4-0.2,0.2-0.3c-0.1,0,0-0.1,0.2-0.1s0.3-0.1,0.1-0.1c-0.2,0-0.1-0.3,0.1-0.8
				c0.2-0.4,0.3-0.9,0.3-1.2s0-0.6,0.1-0.8s0-0.5-0.1-0.6c-0.1-0.3-0.1-0.5,0.3-0.8c0.3-0.3,0.5-0.7,0.5-1s0-0.6,0.1-0.8
				c0.3-0.9,0.9-3.6,0.7-3.6c-0.1,0-0.1-0.2,0-0.4s0.1-0.5,0-0.6c-0.2-0.4,0.3-0.7,0.6-0.5c0.4,0.3,1,0,1.1-0.5
				c0.1-0.4-0.4-0.9-1-0.8c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.1-0.3s0.2-0.1,0.3-0.1c0.2,0,0.4,0,0.6,0s0.5,0,0.8,0.2l0.5,0.2
				l-0.5-0.5C307.4,142.3,307.1,142.2,307.2,142.6"/>
            <path className="st0" d="M137.4,229.1c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3-0.1
				C137.6,229.2,137.5,229.1,137.4,229.1"/>
            <path className="st0" d="M130.8,226.2c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1
				C130.9,226.3,130.8,226.3,130.8,226.2"/>
            <path className="st0"
                  d="M126.5,206.1c-0.1-0.1-0.2-0.1-0.2,0s0.1,0.2,0.2,0.2s0.2,0,0.2,0S126.6,206.2,126.5,206.1"/>
            <path className="st0"
                  d="M164.9,214.1c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1S165,214.2,164.9,214.1"/>
            <path className="st0"
                  d="M113.2,201.4c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C113.5,201.5,113.4,201.4,113.2,201.4"/>
            <path className="st0"
                  d="M170.6,213.3c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2S170.6,213.2,170.6,213.3"/>
            <path className="st0"
                  d="M169.9,212.8c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1S169.9,212.8,169.9,212.8"/>
            <path className="st0" d="M101.3,197.3c-0.1,0-0.2,0-0.1,0.1s0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1
				C101.6,197.4,101.5,197.3,101.3,197.3"/>
            <path className="st0"
                  d="M178.9,213.5c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C179.1,213.4,178.9,213.5,178.9,213.5"/>
            <path className="st0"
                  d="M179.4,213.1c-0.2,0-0.1,0.1,0,0.3c0.4,0.3,0.4,0.3,0.3,0C179.7,213.2,179.6,213.1,179.4,213.1"/>
            <path className="st0"
                  d="M97.9,195.2c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C98.1,195.3,98,195.2,97.9,195.2"/>
            <path className="st0"
                  d="M187.3,213.5c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C187.4,213.3,187.3,213.4,187.3,213.5"/>
            <path className="st0"
                  d="M86.5,190.1c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1S86.6,190.1,86.5,190.1"/>
            <path className="st0" d="M83.9,187.8c-0.2,0-0.3,0-0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.3-0.1
				C84.2,188,84.1,187.9,83.9,187.8"/>
            <path className="st0"
                  d="M77.5,183.9c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.2,0,0.3-0.1C77.7,184,77.7,183.9,77.5,183.9"/>
            <path className="st0" d="M202.4,210.6c-0.1,0-0.3,0.1-0.3,0.2c0,0.1,0.1,0.2,0.2,0.2s0.3-0.1,0.3-0.2
				C202.6,210.7,202.5,210.6,202.4,210.6"/>
            <path className="st0"
                  d="M71.8,180.7c-0.1,0-0.2,0-0.1,0.1s0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1S71.9,180.8,71.8,180.7"/>
            <path className="st0"
                  d="M210.5,209.5c0,0.1,0,0.2,0.1,0.3s0.2,0.1,0.3,0.1s0-0.2-0.1-0.3C210.7,209.4,210.5,209.4,210.5,209.5"/>
            <path className="st0" d="M71.1,179c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1C71.2,179.1,71.2,179,71.1,179
				"/>
            <path className="st0"
                  d="M67.2,177.1c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2S67.2,177.1,67.2,177.1"/>
            <path className="st0"
                  d="M63.8,174.9c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.2,0C64,175.1,63.9,175,63.8,174.9"
            />
            <path className="st0" d="M217.1,207.1c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1
				C217.2,207.2,217.1,207.2,217.1,207.1"/>
            <path className="st0" d="M62.4,172.3c-0.1,0-0.2,0-0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1
				C62.6,172.4,62.5,172.3,62.4,172.3"/>
            <path className="st0"
                  d="M221.9,205.8c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C222.1,205.9,222,205.8,221.9,205.8"/>
            <path className="st0"
                  d="M54.6,166.7c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1C54.7,166.8,54.7,166.7,54.6,166.7"
            />
            <path className="st0"
                  d="M53.6,165.6c0.1,0.2,0.2,0.3,0.2,0.3c0.1,0,0-0.2-0.1-0.3C53.6,165.4,53.6,165.4,53.6,165.6"/>
            <path className="st0"
                  d="M54.6,165.1c0-0.1,0-0.2-0.1-0.2s-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2C54.5,165.3,54.6,165.2,54.6,165.1"/>
            <path className="st0"
                  d="M233.2,201.5c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.2,0,0.3-0.1C233.4,201.6,233.3,201.5,233.2,201.5"/>
            <path className="st0" d="M48.7,158.5c-0.1,0-0.2,0-0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1
				C48.9,158.6,48.8,158.5,48.7,158.5"/>
            <path className="st0"
                  d="M241,199.2c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C241.2,199.1,241.1,199.1,241,199.2"/>
            <path className="st0"
                  d="M45.2,154.8c-0.2,0-0.3,0-0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0,0.3-0.1C45.5,155,45.4,154.8,45.2,154.8"/>
            <path className="st0" d="M242.7,197.1c-0.1,0-0.2,0.1-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.1c0.1,0,0.2-0.1,0.1-0.2
				C243,197.1,242.8,197.1,242.7,197.1"/>
            <path className="st0" d="M43.1,152.5c-0.1,0-0.2,0-0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1
				C43.3,152.6,43.3,152.5,43.1,152.5"/>
            <path className="st0" d="M44.5,152.8c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.3,0
				C44.7,153,44.6,152.9,44.5,152.8"/>
            <path className="st0"
                  d="M243.7,195.8c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C244,196,243.9,195.9,243.7,195.8"/>
            <path className="st0"
                  d="M43,151.7c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.4,0.1s0,0.2,0.2,0.2C42.8,151.9,43,151.8,43,151.7"/>
            <path className="st0"
                  d="M245.4,194.8c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C245.7,194.9,245.6,194.9,245.4,194.8"/>
            <path className="st0"
                  d="M40.2,147.3c-0.2,0-0.3,0-0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0,0.3-0.1C40.5,147.5,40.4,147.4,40.2,147.3"
            />
            <path className="st0"
                  d="M40.5,146.7c0-0.1,0-0.2-0.1-0.2s-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2C40.3,146.8,40.4,146.8,40.5,146.7"/>
            <path className="st0"
                  d="M256,188.8c-0.2,0-0.4,0-0.4,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.4-0.1C256.3,189,256.2,188.9,256,188.8"/>
            <path className="st0" d="M35.3,139.7c-0.1,0-0.2,0.1-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.1c0.1,0,0.2-0.1,0.1-0.2
				C35.6,139.7,35.5,139.6,35.3,139.7"/>
            <path className="st0"
                  d="M34.8,139c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.2,0C35,139.2,34.9,139.1,34.8,139"/>
            <path className="st0" d="M257.5,186.8c-0.1-0.1-0.2,0-0.3,0.1c0,0.1,0,0.3,0.2,0.3c0.1,0,0.2,0,0.3-0.1
				C257.7,187.1,257.6,186.9,257.5,186.8"/>
            <path className="st0"
                  d="M260,183.6c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2s0.2,0,0.3,0S260.1,183.6,260,183.6"/>
            <path className="st0"
                  d="M260.6,183.8c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C260.8,183.7,260.7,183.7,260.6,183.8"/>
            <path className="st0" d="M262.5,181.6c-0.2,0-0.3,0-0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.3-0.1
				C262.8,181.8,262.7,181.7,262.5,181.6"/>
            <path className="st0" d="M262.8,180.8c-0.1,0-0.2,0-0.1,0.1s0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1
				C263,180.9,262.9,180.8,262.8,180.8"/>
            <path className="st0" d="M268.1,176.3L268.1,176.3c-0.2,0-0.2,0.1,0.1,0.1c0.2,0.1,0.4,0.1,0.3,0
				C268.5,176.4,268.3,176.3,268.1,176.3"/>
            <path className="st0"
                  d="M30.3,123.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1S30.3,123.1,30.3,123.1"/>
            <path className="st0"
                  d="M29,117.2c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C29.2,117.3,29.1,117.2,29,117.2"/>
            <path className="st0"
                  d="M27.9,116.6c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1S28,116.6,27.9,116.6"/>
            <path className="st0"
                  d="M274.3,167.6c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1S274.4,167.6,274.3,167.6"/>
            <path className="st0" d="M27.3,113.3c-0.2,0-0.3,0-0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.3-0.1
				C27.6,113.5,27.5,113.4,27.3,113.3"/>
            <path className="st0"
                  d="M28.4,113.3c-0.1,0-0.3,0.1-0.3,0.2s0.1,0.2,0.2,0.2s0.3-0.1,0.3-0.2S28.5,113.3,28.4,113.3"/>
            <path className="st0" d="M273.4,165.4c-0.1,0.1-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.4-0.1s0.1-0.2-0.1-0.2
				C273.7,165.2,273.5,165.3,273.4,165.4"/>
            <path className="st0"
                  d="M271.4,164c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.2,0,0.3-0.1C271.6,164.2,271.5,164.1,271.4,164"/>
            <path className="st0"
                  d="M27,108.3c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.2,0,0.3-0.1C27.2,108.4,27.2,108.3,27,108.3"/>
            <path className="st0"
                  d="M27.9,105.5c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2s-0.2,0-0.3,0.1C27.7,105.4,27.7,105.5,27.9,105.5"/>
            <path className="st0"
                  d="M275.9,156.8c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2s0.2,0,0.3,0C276.1,157,276,156.9,275.9,156.8"/>
            <path className="st0"
                  d="M276.3,156.4c0,0.2,0,0.3,0.1,0.2c0.1,0,0.1-0.2,0.1-0.3C276.4,156.2,276.3,156.2,276.3,156.4"/>
            <path className="st0"
                  d="M279.3,156c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2s0.2,0,0.3,0C279.5,156.2,279.4,156.1,279.3,156"/>
            <path className="st0"
                  d="M28.2,101.6c0.1,0,0.2,0,0.3,0c0,0-0.1-0.1-0.2-0.2s-0.2-0.1-0.3,0C28,101.5,28.1,101.6,28.2,101.6"/>
            <path className="st0"
                  d="M28.6,97.6c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1S28.8,97.6,28.6,97.6"/>
            <path className="st0"
                  d="M26.6,95.8c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C26.8,95.9,26.7,95.8,26.6,95.8"/>
            <path className="st0"
                  d="M29.3,93.3c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C29.6,93.4,29.5,93.3,29.3,93.3"/>
            <path className="st0"
                  d="M28.9,90.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.2-0.2-0.2s-0.2,0-0.1,0.1S28.9,90.2,28.9,90.2"/>
            <path className="st0"
                  d="M284,140.1c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2S284,140,284,140.1"/>
            <path className="st0"
                  d="M32.9,81.5c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.2,0C33.1,81.7,33,81.6,32.9,81.5"/>
            <path className="st0"
                  d="M283.4,132.9c-0.1-0.1-0.2,0-0.3,0.1s0,0.3,0.2,0.3c0.1,0,0.2,0,0.3-0.1C283.6,133.2,283.5,133,283.4,132.9"
            />
            <path className="st0"
                  d="M33.5,77.8c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3-0.1S33.6,77.8,33.5,77.8"/>
            <path className="st0"
                  d="M33.6,77.2c0,0-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.2,0s0.1,0.2,0.2,0.2C33.5,77.3,33.6,77.2,33.6,77.2"/>
            <path className="st0" d="M34.4,75.9C34.3,75.9,34.2,75.9,34.4,75.9c0,0.2,0.1,0.3,0.1,0.3c0.1,0,0.1-0.1,0.1-0.1
				C34.6,76,34.5,75.9,34.4,75.9"/>
            <path className="st0"
                  d="M33.7,75.2c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1C33.8,75.3,33.7,75.2,33.7,75.2"/>
            <path className="st0"
                  d="M34.9,75.7c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2C34.8,75.8,34.9,75.8,34.9,75.7"/>
            <path className="st0"
                  d="M284.8,128.7c-0.1,0-0.3,0-0.3,0.1s0,0.2,0.1,0.2s0.2,0,0.3-0.1C285,128.8,284.9,128.7,284.8,128.7"/>
            <path className="st0"
                  d="M35,74.5c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1,0,0.2,0.2,0.2s0.4,0,0.4-0.1S35.1,74.5,35,74.5"/>
            <path className="st0"
                  d="M283.4,124.8c-0.1,0-0.3,0-0.4,0.1s0,0.2,0.2,0.2s0.4,0,0.4-0.1C283.7,124.9,283.6,124.8,283.4,124.8"/>
            <path className="st0" d="M36.8,69.9c-0.1,0.1-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.2-0.1-0.2
				C37.1,69.8,36.9,69.8,36.8,69.9"/>
            <path className="st0"
                  d="M39.4,68.2c-0.2,0-0.3,0-0.3,0.1c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.2,0,0.3-0.1C39.7,68.3,39.6,68.2,39.4,68.2"
            />
            <path className="st0"
                  d="M38.5,67.4c-0.1,0-0.3,0.1-0.3,0.2s0.1,0.2,0.2,0.2c0.1,0,0.3-0.1,0.3-0.2C38.7,67.4,38.6,67.4,38.5,67.4"/>
            <path className="st0"
                  d="M282.6,118.5c-0.1-0.1-0.2-0.1-0.2,0s0.1,0.2,0.2,0.2s0.2,0,0.2,0C282.8,118.6,282.7,118.5,282.6,118.5"/>
            <path className="st0"
                  d="M42.6,65.3c-0.1,0-0.3,0-0.3,0.1s0,0.2,0.1,0.2s0.2,0,0.3-0.1C42.8,65.4,42.8,65.3,42.6,65.3"/>
            <path className="st0"
                  d="M280.1,112.1c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C280.3,112.3,280.2,112.2,280.1,112.1"/>
            <path className="st0"
                  d="M280.6,111.7c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.2,0,0.3-0.1C280.9,111.9,280.8,111.8,280.6,111.7"/>
            <path className="st0" d="M44.6,59.7C44.5,59.7,44.4,59.7,44.6,59.7c0,0.2,0.1,0.3,0.1,0.3c0.1,0,0.1-0.1,0.1-0.1
				C44.8,59.8,44.7,59.7,44.6,59.7"/>
            <path className="st0"
                  d="M47.1,59.5c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3-0.1C47.3,59.6,47.2,59.5,47.1,59.5"/>
            <path className="st0" d="M278.3,107.6c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1c0.1-0.1,0.1-0.2-0.1-0.2
				C278.5,107.5,278.4,107.5,278.3,107.6"/>
            <path className="st0" d="M48.3,57.3c-0.1,0-0.3,0.1-0.3,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3-0.1,0.3-0.2
				C48.5,57.3,48.4,57.2,48.3,57.3"/>
            <path className="st0"
                  d="M277,103.7c-0.1,0-0.3,0.1-0.3,0.2s0.1,0.2,0.2,0.2s0.3-0.1,0.3-0.2C277.2,103.8,277.1,103.7,277,103.7"/>
            <path className="st0" d="M53,53c-0.1,0-0.2,0-0.3,0.1s0,0.2,0.2,0.2C53.2,53.3,53.3,53.1,53,53"/>
            <path className="st0" d="M275.4,99.4c-0.2,0-0.3,0-0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.3-0.1
				C275.7,99.5,275.6,99.4,275.4,99.4"/>
            <path className="st0" d="M273.7,96.8c-0.1-0.1-0.2-0.2-0.3-0.2s-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.3,0.2
				C273.7,96.9,273.8,96.9,273.7,96.8"/>
            <path className="st0"
                  d="M273.5,95.9c-0.2,0-0.2,0.1,0.1,0.1c0.2,0.1,0.4,0.1,0.3,0C273.9,96,273.7,95.9,273.5,95.9"/>
            <path className="st0"
                  d="M60.8,47.5c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3-0.1C61,47.6,60.9,47.5,60.8,47.5"/>
            <path className="st0"
                  d="M266,92c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1c0.1-0.1,0.1-0.2-0.1-0.2C266.2,91.9,266.1,91.9,266,92"/>
            <path className="st0"
                  d="M270.2,91c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C270.4,91.1,270.3,91.1,270.2,91"/>
            <path className="st0"
                  d="M65.7,45.1c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C65.9,45.2,65.8,45.1,65.7,45.1"/>
            <path className="st0" d="M268.2,88.2c-0.1-0.1-0.2-0.2-0.3-0.1c-0.1,0-0.2,0.1-0.1,0.2s0.2,0.2,0.3,0.1
				C268.2,88.4,268.3,88.3,268.2,88.2"/>
            <path className="st0"
                  d="M65,43.5c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1C65.1,43.6,65,43.6,65,43.5"/>
            <path className="st0"
                  d="M267.5,86.6c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C267.7,86.8,267.6,86.7,267.5,86.6"/>
            <path className="st0"
                  d="M255.1,71.9c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1C255.2,72,255.2,71.9,255.1,71.9"/>
            <path className="st0"
                  d="M87.6,34.9c-0.1-0.1-0.2,0-0.3,0.1c0,0.1,0,0.3,0.2,0.3c0.1,0,0.2,0,0.3-0.1C87.8,35.1,87.7,35,87.6,34.9"/>
            <path className="st0" d="M252.1,68.1c-0.1,0-0.2,0-0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1
				C252.3,68.3,252.2,68.2,252.1,68.1"/>
            <path className="st0"
                  d="M244.7,64.6c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1S244.7,64.7,244.7,64.6"/>
            <path className="st0"
                  d="M242.6,61.3c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.3-0.1C242.8,61.4,242.7,61.3,242.6,61.3"/>
            <path className="st0"
                  d="M239.1,58.3c-0.4-0.1-0.4-0.1-0.1,0.2c0.4,0.3,0.4,0.3,0.5,0.1C239.5,58.4,239.3,58.3,239.1,58.3"/>
            <path className="st0"
                  d="M226.2,54.3c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C226.3,54.1,226.2,54.2,226.2,54.3"/>
            <path className="st0"
                  d="M236.9,56.2c-0.2,0-0.4,0-0.4,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.4-0.1C237.2,56.4,237.1,56.3,236.9,56.2"/>
            <path className="st0"
                  d="M112,28.9c0-0.1-0.1-0.2-0.2-0.2s-0.3,0-0.4,0.1s0,0.2,0.2,0.2C111.8,29,112,29,112,28.9"/>
            <path className="st0"
                  d="M230.4,52.9c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C230.5,52.8,230.4,52.8,230.4,52.9"/>
            <path className="st0"
                  d="M117.2,27.4c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1C117.3,27.5,117.2,27.4,117.2,27.4"
            />
            <path className="st0"
                  d="M123.8,26.9c-0.2,0-0.3,0.1-0.2,0.1c0.1,0,0.4,0,0.6,0s0.3-0.1,0.2-0.1C124.3,26.9,124,26.9,123.8,26.9"/>
            <path className="st0"
                  d="M126.9,26.9c-0.2,0-0.4,0-0.4,0.1s0.1,0.2,0.2,0.2s0.3,0,0.4-0.1C127.1,27.1,127.1,27,126.9,26.9"/>
            <path className="st0"
                  d="M133.5,27c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1S133.5,27,133.5,27"/>
            <path className="st0"
                  d="M212.1,43.8c-0.2,0-0.3,0-0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0,0.3-0.1C212.4,44,212.3,43.8,212.1,43.8"/>
            <path className="st0" d="M210.6,43.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0.1-0.3,0.2c0,0.1,0.1,0.2,0.2,0.2
				C210.5,43.5,210.6,43.4,210.6,43.2"/>
            <path className="st0"
                  d="M138.6,26.5c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.3-0.1C138.8,26.7,138.7,26.6,138.6,26.5"/>
            <path className="st0"
                  d="M207.8,41.5c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.2,0,0.3-0.1C208,41.6,208,41.5,207.8,41.5"/>
            <path className="st0" d="M207,40.8c-0.1,0-0.2,0-0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1
				C207.2,40.9,207.1,40.8,207,40.8"/>
            <path className="st0"
                  d="M209.6,41.3c0,0.1,0,0.2,0.1,0.3s0.2,0.1,0.3,0.1s0-0.2-0.1-0.3C209.7,41.2,209.6,41.2,209.6,41.3"/>
            <path className="st0"
                  d="M144.6,27.1c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3-0.1C144.8,27.3,144.8,27.2,144.6,27.1"/>
            <path className="st0"
                  d="M206.4,39.5c-0.4-0.1-0.4-0.1-0.1,0.1c0.4,0.3,0.4,0.3,0.5,0.1C206.8,39.6,206.6,39.5,206.4,39.5"/>
            <path className="st0"
                  d="M156,28c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3-0.1C156.2,28.2,156.1,28.1,156,28"/>
            <path className="st0" d="M157.2,28.1c-0.1,0-0.3,0.1-0.3,0.2s0.1,0.2,0.2,0.2c0.1,0,0.3-0.1,0.3-0.2
				C157.4,28.3,157.3,28.2,157.2,28.1"/>
            <path className="st0"
                  d="M160.6,28.4c-0.1,0-0.3,0.1-0.3,0.2s0.1,0.2,0.2,0.2c0.1,0,0.3-0.1,0.3-0.2S160.7,28.4,160.6,28.4"/>
            <path className="st0"
                  d="M157.7,27.9c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C157.9,27.8,157.7,27.8,157.7,27.9"/>
            <path className="st0"
                  d="M153.8,26.7c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.3,0.2s0.3,0,0.3-0.1C154.1,26.8,153.9,26.7,153.8,26.7"/>
            <path className="st0"
                  d="M165.8,29.3c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.3-0.1C166,29.4,165.9,29.3,165.8,29.3"/>
            <path className="st0"
                  d="M167.9,29.9c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C168,29.8,167.9,29.8,167.9,29.9"/>
            <path className="st0"
                  d="M187.6,34.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2S187.5,34.3,187.6,34.2"/>
            <path className="st0"
                  d="M172.4,30.6c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C172.5,30.4,172.4,30.5,172.4,30.6"/>
            <path className="st0"
                  d="M157.5,27.1c-0.2,0-0.4,0-0.4,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.4-0.1C157.8,27.2,157.7,27.1,157.5,27.1"/>
            <path className="st0" d="M120.7,222.5c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1
				C120.9,222.6,120.8,222.5,120.7,222.5"/>
            <path className="st0"
                  d="M97.7,217.5c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2S97.7,217.4,97.7,217.5"/>
            <path className="st0"
                  d="M113,220.3c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.2,0,0.3-0.1C113.2,220.5,113.1,220.4,113,220.3"/>
            <path className="st0" d="M112.1,220.1c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2s0.2,0,0.2,0
				C112.3,220.2,112.2,220.1,112.1,220.1"/>
            <path className="st0"
                  d="M127.7,223.6c-0.2,0-0.2,0.1,0.1,0.1c0.2,0.1,0.4,0.1,0.3,0C128.1,223.6,127.9,223.6,127.7,223.6"/>
            <path className="st0" d="M107.3,218.5c0,0.1,0,0.2,0.1,0.3s0.2,0.1,0.3,0.1c0.1,0,0-0.2-0.1-0.3C107.4,218.4,107.3,218.4,107.3,218.5
				"/>
            <path className="st0" d="M150.3,227.9c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1C150.4,228,150.4,227.9,150.3,227.9
				"/>
            <path className="st0"
                  d="M106.8,217.2c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2s0.2,0,0.3,0C107,217.4,106.9,217.3,106.8,217.2"
            />
            <path className="st0"
                  d="M162.8,228.9c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C162.9,228.7,162.8,228.8,162.8,228.9"/>
            <path className="st0"
                  d="M166.8,228.2c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C167.1,228.3,167,228.2,166.8,228.2"/>
            <path className="st0" d="M171.7,229.2c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1
				C171.8,229.3,171.7,229.2,171.7,229.2"/>
            <path className="st0" d="M180,229.7c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.2,0
				C180.2,229.9,180.1,229.8,180,229.7"/>
            <path className="st0"
                  d="M180.3,229.4c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C180.5,229.2,180.4,229.3,180.3,229.4"/>
            <path className="st0"
                  d="M74.3,202.8c0-0.1-0.1-0.2-0.2-0.2s-0.3,0-0.4,0.1s0,0.2,0.2,0.2C74.1,203,74.3,202.9,74.3,202.8"/>
            <path className="st0"
                  d="M70.4,201.3c-0.2,0-0.4,0-0.4,0.1s0.1,0.2,0.2,0.2s0.3,0,0.4-0.1S70.6,201.3,70.4,201.3"/>
            <path className="st0"
                  d="M63.1,197c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3-0.1C63.3,197.1,63.2,197,63.1,197"/>
            <path className="st0"
                  d="M60.9,195.6c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3-0.1C61.2,195.7,61.1,195.7,60.9,195.6"/>
            <path className="st0"
                  d="M51,190.5c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C51.1,190.4,51,190.5,51,190.5"/>
            <path className="st0"
                  d="M50.1,190.2c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C50.2,190,50.1,190.1,50.1,190.2"/>
            <path className="st0" d="M217.4,225.1c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3-0.1
				C217.6,225.3,217.5,225.2,217.4,225.1"/>
            <path className="st0"
                  d="M46.9,186.6c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C47.1,186.4,47,186.5,46.9,186.6"/>
            <path className="st0"
                  d="M222.1,223.5c-0.2,0-0.2,0.1,0.1,0.1c0.2,0.1,0.4,0.1,0.3,0C222.5,223.5,222.3,223.5,222.1,223.5"/>
            <path className="st0" d="M236,224.6c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.2,0
				C236.2,224.8,236.1,224.7,236,224.6"/>
            <path className="st0" d="M42.9,181.9c-0.2,0-0.3,0-0.3,0.1c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.2,0,0.3-0.1
				C43.2,182,43.1,181.9,42.9,181.9"/>
            <path className="st0" d="M233.4,222.2c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1
				C233.5,222.3,233.5,222.2,233.4,222.2"/>
            <path className="st0"
                  d="M41.9,180c-0.2,0-0.4,0-0.4,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.4-0.1C42.2,180.1,42.1,180,41.9,180"/>
            <path className="st0"
                  d="M40.5,178.3c-0.2,0-0.4,0-0.4,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.4-0.1C40.8,178.4,40.7,178.3,40.5,178.3"/>
            <path className="st0"
                  d="M39,177.9c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C39.2,177.8,39,177.9,39,177.9"/>
            <path className="st0"
                  d="M32.4,175.7c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2C32.5,175.5,32.4,175.6,32.4,175.7"/>
            <path className="st0"
                  d="M39.4,176.8c-0.2,0-0.3,0-0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0,0.3-0.1C39.7,177,39.6,176.9,39.4,176.8"/>
            <path className="st0"
                  d="M31.8,175c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C32,175.1,31.9,175,31.8,175"/>
            <path className="st0"
                  d="M35.4,173c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3-0.1C35.6,173.2,35.5,173.1,35.4,173"
            />
            <path className="st0"
                  d="M30.7,169.8c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1c0.1-0.1,0.1-0.2-0.1-0.2C30.9,169.6,30.7,169.7,30.7,169.8"
            />
            <path className="st0"
                  d="M248.8,215.5c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2S248.8,215.5,248.8,215.5"/>
            <path className="st0" d="M250.5,215.1c-0.2,0-0.4,0-0.4,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.4-0.1
				C250.8,215.2,250.7,215.1,250.5,215.1"/>
            <path className="st0"
                  d="M22,163.6c-0.1,0-0.2,0-0.1,0.1s0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1C22.2,163.7,22.1,163.6,22,163.6"/>
            <path className="st0"
                  d="M24.2,161.6c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C24.4,161.8,24.3,161.7,24.2,161.6"/>
            <path className="st0"
                  d="M23.4,160.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2,0.1,0.2s0.3,0,0.3-0.1S23.5,160.1,23.4,160.1"/>
            <path className="st0" d="M24.3,160.3c-0.1-0.1-0.2-0.2-0.3-0.1c-0.1,0-0.2,0.1-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.1
				C24.3,160.5,24.4,160.4,24.3,160.3"/>
            <path className="st0" d="M22.5,157.3c-0.3,0.1-0.5,0.1-0.6,0.2c-0.1,0,0.1,0.1,0.3,0.1c0.2,0,0.5,0,0.6-0.2
				C22.9,157.2,22.9,157.2,22.5,157.3"/>
            <path className="st0"
                  d="M260.7,208.1c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C260.9,208.3,260.8,208.2,260.7,208.1"/>
            <path className="st0"
                  d="M262.5,208.3c-0.2,0-0.4,0-0.4,0.1c0,0,0.1,0.1,0.3,0.1c0.2,0,0.4,0,0.4-0.1S262.7,208.3,262.5,208.3"/>
            <path className="st0"
                  d="M19.6,154.6c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C19.8,154.7,19.7,154.6,19.6,154.6"/>
            <path className="st0"
                  d="M16.8,151.4c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C17,151.5,16.9,151.4,16.8,151.4"/>
            <path className="st0"
                  d="M276,198.8c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2s0.2,0,0.3,0C276.2,199,276.1,198.9,276,198.8"/>
            <path className="st0"
                  d="M11.9,140.2c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3-0.1C12.1,140.3,12,140.2,11.9,140.2"/>
            <path className="st0"
                  d="M10.6,138.4c-0.1-0.1-0.2-0.1-0.2,0s0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.3,0C10.8,138.6,10.7,138.5,10.6,138.4"/>
            <path className="st0"
                  d="M280.5,195c-0.1,0-0.2,0-0.1,0.1s0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.1-0.1C280.8,195.1,280.6,195,280.5,195"/>
            <path className="st0" d="M9.5,135.7c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1s0.1-0.2-0.1-0.2S9.5,135.6,9.5,135.7"/>
            <path className="st0"
                  d="M7,134.5c-0.1,0-0.2,0-0.1,0.1s0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1C7.2,134.6,7.1,134.5,7,134.5"/>
            <path className="st0" d="M8.3,134.1c-0.1,0-0.2,0-0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1
				C8.6,134.2,8.5,134.1,8.3,134.1"/>
            <path className="st0"
                  d="M7.4,131.6c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.3,0.2c0.2,0,0.3,0,0.3-0.1C7.7,131.8,7.5,131.6,7.4,131.6"
            />
            <path className="st0"
                  d="M6.5,129.5c0,0.2,0.1,0.3,0.2,0.2c0.2-0.1,0.2-0.2,0-0.4C6.5,129.2,6.5,129.2,6.5,129.5"/>
            <path className="st0"
                  d="M5.8,125.4c0-0.1-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2C5.7,125.6,5.8,125.5,5.8,125.4"/>
            <path className="st0" d="M4.4,121.3c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2-0.1-0.2c-0.1,0-0.3,0-0.3,0.1
				C4.3,121.2,4.3,121.3,4.4,121.3"/>
            <path className="st0"
                  d="M291.1,181.6c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.2,0,0.3-0.1C291.3,181.7,291.2,181.6,291.1,181.6"/>
            <path className="st0" d="M291.3,180.9c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1c0.1-0.1,0.1-0.2-0.1-0.2
				C291.4,180.8,291.3,180.8,291.3,180.9"/>
            <path className="st0"
                  d="M4.6,117.4c0,0.1,0.1,0.2,0.2,0.2s0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2S4.6,117.3,4.6,117.4"/>
            <path className="st0" d="M292.7,179.2c-0.2,0.1-0.4,0.1-0.4,0.2c0,0,0.2,0.1,0.3,0.1c0.2,0,0.4,0,0.4-0.2
				C293.1,179.1,293.1,179.1,292.7,179.2"/>
            <path className="st0"
                  d="M4.6,116.5C4.2,116.4,4.2,116.4,4.6,116.5C4.9,117,5,117,5,116.8C5,116.7,4.9,116.6,4.6,116.5"/>
            <path className="st0"
                  d="M293,178.4c-0.1,0-0.2,0-0.1,0.1s0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1C293.3,178.5,293.2,178.4,293,178.4"
            />
            <path className="st0"
                  d="M294.7,176c-0.2,0-0.4,0-0.4,0.1s0.1,0.2,0.2,0.2s0.3,0,0.4-0.1C295,176.1,294.9,176,294.7,176"/>
            <path className="st0"
                  d="M0.3,111.5c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2s0.2,0,0.3,0C0.5,111.7,0.4,111.6,0.3,111.5"/>
            <path className="st0"
                  d="M0.9,111.8c0,0.1,0,0.2,0.1,0.2s0.2,0,0.3-0.1c0.1-0.1,0.1-0.2-0.1-0.2C1.1,111.6,1,111.7,0.9,111.8"/>
            <path className="st0"
                  d="M297.9,174.3c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2s0.2,0,0.3,0C298.1,174.5,298,174.4,297.9,174.3"
            />
            <path className="st0"
                  d="M1.8,110c-0.1-0.1-0.2,0-0.3,0.1c0,0.1,0,0.3,0.2,0.3s0.2,0,0.3-0.1C2,110.2,1.9,110.1,1.8,110"/>
            <path className="st0"
                  d="M1.8,109c0-0.1-0.1-0.2-0.2-0.2s-0.2,0-0.1,0.1s0.2,0.2,0.2,0.2C1.7,109.2,1.8,109.1,1.8,109"/>
            <path className="st0"
                  d="M0.4,106.4c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2s0.2,0,0.3,0C0.6,106.6,0.5,106.5,0.4,106.4"/>
            <path className="st0"
                  d="M299.7,169c-0.1,0-0.2,0-0.1,0.1s0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.1-0.1C300,169.1,299.9,169,299.7,169"/>
            <path className="st0"
                  d="M300.2,167c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C300.4,167.1,300.3,167,300.2,167"/>
            <path className="st0" d="M301.3,166.4c-0.1,0-0.2,0-0.3,0.1c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1
				C301.5,166.5,301.5,166.4,301.3,166.4"/>
            <path className="st0"
                  d="M302.4,164.9c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1C302.6,165,302.5,164.9,302.4,164.9"/>
            <path className="st0"
                  d="M2.4,98.6c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.3,0.1C2.1,98.5,2.2,98.6,2.4,98.6"/>
            <path className="st0"
                  d="M303.9,161.6c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1S304,161.6,303.9,161.6"/>
            <path className="st0"
                  d="M304.5,158.8c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1S304.6,158.8,304.5,158.8"/>
            <path className="st0"
                  d="M305,157.4c-0.1-0.1-0.2-0.1-0.3,0c0,0.1,0.1,0.2,0.2,0.2s0.2,0,0.3,0C305.2,157.6,305.1,157.5,305,157.4"/>
            <path className="st0" d="M305.5,156.4c-0.1,0-0.2,0-0.1,0.1s0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1
				C305.7,156.5,305.6,156.4,305.5,156.4"/>
            <path className="st0"
                  d="M4.9,85.2C5,85.2,5,85.1,4.9,85.2c0.1-0.2,0-0.3-0.1-0.3s-0.2,0-0.1,0.1S4.9,85.2,4.9,85.2"/>
            <path className="st0"
                  d="M307,148.3c-0.2,0-0.2,0.1,0.1,0.1c0.2,0.1,0.4,0.1,0.3,0C307.3,148.4,307.1,148.3,307,148.3"/>
            <path className="st0"
                  d="M6.3,82.8c0.1,0,0.2,0,0.3-0.1s-0.1-0.2-0.3-0.2c-0.2,0-0.3,0-0.3,0.1C6.1,82.6,6.2,82.7,6.3,82.8"/>
            <path className="st0"
                  d="M306.4,146.6c-0.1,0-0.3,0-0.3,0.1s0.1,0.2,0.2,0.2s0.3,0,0.3-0.1S306.5,146.6,306.4,146.6"/>
            <path className="st0"
                  d="M6.3,81.6c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2s-0.2,0-0.3,0.1C6.1,81.4,6.2,81.5,6.3,81.6"/>
            <path className="st0"
                  d="M7.8,77.3c0.1,0,0.2-0.1,0.1-0.2S7.7,76.9,7.6,77c-0.1,0-0.2,0.1-0.1,0.2C7.5,77.3,7.7,77.3,7.8,77.3"/>
            <path className="st0" d="M308.2,139.5c-0.1,0-0.2,0.1-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.1c0.1,0,0.2-0.1,0.1-0.2
				C308.5,139.5,308.3,139.5,308.2,139.5"/>
            <path className="st0"
                  d="M307.4,135.6c0.2,0.1,0.4,0.1,0.3,0s-0.3-0.1-0.4-0.1l0,0C307.1,135.5,307.2,135.6,307.4,135.6"/>
            <path className="st0"
                  d="M306.1,134.3c-0.2,0-0.4,0-0.4,0.1s0.1,0.2,0.2,0.2s0.3,0,0.4-0.1C306.3,134.4,306.3,134.3,306.1,134.3"/>
            <path className="st0"
                  d="M307.5,133.6c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2C307.7,133.4,307.6,133.5,307.5,133.6"/>
            <path className="st0" d="M308.1,133.1c0,0-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.2,0s0.1,0.2,0.2,0.2
				C308,133.2,308.1,133.2,308.1,133.1"/>
            <path className="st0"
                  d="M13.3,67.3c0-0.1,0-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.1,0.2C13.2,67.6,13.3,67.6,13.3,67.3"/>
            <path className="st0"
                  d="M307.8,130c0,0.1,0.1,0.2,0.2,0.2s0.3,0,0.4-0.1s0-0.2-0.2-0.2C308,129.9,307.9,129.9,307.8,130"/>
            <path className="st0" d="M306.3,127.3c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2-0.1-0.2c-0.1,0-0.3,0-0.3,0.1
				C306.2,127.2,306.3,127.3,306.3,127.3"/>
            <path className="st0"
                  d="M14.7,62.3c0.1,0,0.2,0,0.2,0s-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.2,0C14.5,62.2,14.6,62.3,14.7,62.3"/>
            <path className="st0"
                  d="M306.5,123.2c0.2,0.1,0.4,0.1,0.3,0s-0.3-0.1-0.4-0.1C306.2,123.1,306.2,123.1,306.5,123.2"/>
            <path className="st0"
                  d="M18.8,56.8c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2-0.1-0.2s-0.3,0-0.3,0.1C18.7,56.7,18.7,56.8,18.8,56.8"/>
            <path className="st0"
                  d="M305,115.5c0,0,0.2,0,0.3-0.1c0.1-0.1,0-0.2-0.2-0.2s-0.3,0-0.3,0.1C304.9,115.3,305,115.4,305,115.5"/>
            <path className="st0"
                  d="M305.8,114.8c0.1,0,0.2,0,0.2,0s-0.1-0.1-0.2-0.2s-0.2-0.1-0.2,0C305.6,114.6,305.7,114.7,305.8,114.8"/>
            <path className="st0" d="M303.9,113.3c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1
				C303.7,113.2,303.8,113.3,303.9,113.3"/>
            <path className="st0"
                  d="M303,107.4c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1S302.8,107.4,303,107.4"/>
            <path className="st0"
                  d="M302.1,106.5c-0.1,0.1-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1s0-0.2-0.1-0.2S302.2,106.5,302.1,106.5"/>
            <path className="st0" d="M301.9,106c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.3-0.2
				C302,105.8,301.9,105.9,301.9,106"/>
            <path className="st0"
                  d="M302.1,105c-0.1-0.1-0.3,0-0.6,0.1c-0.5,0.2-0.4,0.2,0.1,0.1C302,105.2,302.2,105.1,302.1,105"/>
            <path className="st0"
                  d="M303.1,105.7c0.1,0,0.2,0,0.3,0s-0.1-0.1-0.2-0.2s-0.2-0.1-0.3,0C302.9,105.6,303,105.7,303.1,105.7"/>
            <path className="st0"
                  d="M301.7,104.2c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2s-0.2,0-0.3,0.1C301.5,104.1,301.6,104.2,301.7,104.2"/>
            <path className="st0"
                  d="M30.8,43.6c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.2-0.2-0.2s-0.2,0-0.1,0.1C30.6,43.5,30.7,43.6,30.8,43.6"/>
            <path className="st0" d="M300.4,100c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.3-0.2c-0.2,0-0.3,0-0.3,0.1C300.2,99.8,300.3,100,300.4,100
				"/>
            <path className="st0" d="M32.9,40.7C33,40.7,33,40.6,32.9,40.7c0.1-0.2,0-0.3-0.1-0.3c-0.1,0-0.2,0-0.1,0.1
				C32.7,40.6,32.8,40.7,32.9,40.7"/>
            <path className="st0"
                  d="M33.5,40.5c0.1,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0.1-0.3,0.2S33.4,40.5,33.5,40.5"/>
            <path className="st0" d="M299.1,96.1c0-0.1,0-0.2-0.1-0.2s-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2,0.1,0.2C298.9,96.2,299.1,96.2,299.1,96.1
				"/>
            <path className="st0"
                  d="M293.7,89.6c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2-0.1-0.2c-0.1,0-0.3,0-0.3,0.1S293.7,89.5,293.7,89.6"/>
            <path className="st0"
                  d="M295,89.8c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1C294.8,89.7,294.9,89.8,295,89.8"/>
            <path className="st0" d="M292,87.5c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.2-0.2-0.2
				C292,87.3,291.9,87.4,292,87.5"/>
            <path className="st0"
                  d="M292.4,86.9c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.3-0.1S292.4,86.9,292.4,86.9"/>
            <path className="st0" d="M292,86.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.3-0.2
				C292.1,86.1,291.9,86.1,292,86.3"/>
            <path className="st0"
                  d="M292,85.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.2-0.2-0.2s-0.2,0-0.1,0.1C291.9,84.9,292,85,292,85.1"/>
            <path className="st0"
                  d="M284.9,74.4c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2s-0.2,0-0.3,0.1C284.7,74.3,284.8,74.3,284.9,74.4"/>
            <path className="st0"
                  d="M284,73.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.2-0.2-0.2s-0.2,0-0.1,0.1C283.9,73.2,284,73.3,284,73.3"/>
            <path className="st0"
                  d="M278.4,66.9c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1C278.2,66.8,278.3,66.9,278.4,66.9"/>
            <path className="st0"
                  d="M275.6,64.3c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1C275.4,64.1,275.5,64.2,275.6,64.3"/>
            <path className="st0"
                  d="M275,63.6c0.1,0,0.3,0,0.4-0.1s0-0.2-0.2-0.2s-0.4,0-0.4,0.1C274.8,63.5,274.9,63.6,275,63.6"/>
            <path className="st0"
                  d="M272.9,61.4c0.2,0,0.4,0,0.4-0.1s-0.1-0.2-0.2-0.2s-0.3,0-0.4,0.1C272.6,61.3,272.7,61.4,272.9,61.4"/>
            <path className="st0"
                  d="M262.4,52.8c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1C262.2,52.7,262.2,52.7,262.4,52.8"/>
            <path className="st0"
                  d="M257,48.3c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1C256.7,48.2,256.8,48.3,257,48.3"/>
            <path className="st0"
                  d="M252.4,44.8c0.1,0,0.2,0,0.3-0.1s0.1-0.2-0.1-0.2c-0.1,0-0.3,0-0.3,0.1C252.4,44.7,252.4,44.8,252.4,44.8"/>
            <path className="st0"
                  d="M246.9,41.7c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.3,0.1C246.7,41.5,246.7,41.6,246.9,41.7"
            />
            <path className="st0"
                  d="M244.8,39.8c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.3,0.1C244.6,39.7,244.7,39.8,244.8,39.8"
            />
            <path className="st0"
                  d="M239,37c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1C238.8,36.9,238.9,37,239,37"/>
            <path className="st0"
                  d="M240.2,37.3c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1C240,37.2,240.1,37.3,240.2,37.3"/>
            <path className="st0"
                  d="M233,33.3c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1C232.8,33.2,232.8,33.3,233,33.3"/>
            <path className="st0"
                  d="M227.6,30.7c0.1,0,0.3-0.1,0.3-0.2s-0.1-0.2-0.2-0.2s-0.3,0.1-0.3,0.2C227.4,30.6,227.5,30.7,227.6,30.7"/>
            <path className="st0"
                  d="M133.8,9.4c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.3,0.1C133.5,9.3,133.6,9.4,133.8,9.4"
            />
            <path className="st0"
                  d="M215.7,25.7c-0.1-0.1-0.2,0-0.3,0.1c-0.2,0.2-0.2,0.2,0.1,0.1C215.6,25.9,215.7,25.8,215.7,25.7"/>
            <path className="st0"
                  d="M142.9,9.5c-0.1-0.1-0.4-0.2-0.6-0.1c-0.4,0.1-0.4,0.1,0.2,0.2C142.7,9.6,143,9.6,142.9,9.5"/>
            <path className="st0"
                  d="M214.6,24.5c0.1,0,0.2,0,0.3-0.1s0.1-0.2-0.1-0.2c-0.1,0-0.3,0-0.3,0.1S214.6,24.5,214.6,24.5"/>
            <path className="st0"
                  d="M148,9.6c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.3,0.1C147.8,9.5,147.9,9.6,148,9.6"/>
            <path className="st0"
                  d="M151.6,9.5c0.1,0,0.2,0,0.3-0.1s0.1-0.2-0.1-0.2c-0.1,0-0.3,0-0.3,0.1C151.5,9.4,151.5,9.5,151.6,9.5"/>
            <path className="st0"
                  d="M207.3,21.6c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.3,0.1C207.1,21.4,207.2,21.5,207.3,21.6"
            />
            <path className="st0"
                  d="M206.6,21.2c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1C206.4,21.1,206.5,21.2,206.6,21.2"/>
            <path className="st0"
                  d="M197.2,19c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.1,0.1C197,18.9,197.1,19,197.2,19"/>
            <path className="st0"
                  d="M202,19.7c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2s-0.2,0-0.3,0.1C201.8,19.6,201.9,19.7,202,19.7"/>
            <path className="st0"
                  d="M157.9,10c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.1,0.1C157.7,9.9,157.8,10,157.9,10"
            />
            <path className="st0"
                  d="M191.2,17.2c0.1,0,0.2,0,0.3-0.1s0.1-0.2-0.1-0.2c-0.1,0-0.3,0-0.3,0.1C191.1,17.1,191.1,17.2,191.2,17.2"/>
            <path className="st0"
                  d="M171.4,12.4c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1S171.3,12.4,171.4,12.4"/>
            <path className="st0"
                  d="M192.4,16.5c-0.1-0.1-0.2,0-0.3,0.1c-0.2,0.2-0.2,0.2,0.1,0.1C192.3,16.7,192.4,16.6,192.4,16.5"/>
            <path className="st0"
                  d="M169.8,11.7c0.2,0,0.3,0,0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.2s-0.2,0-0.3,0.1S169.6,11.7,169.8,11.7"/>
            <path className="st0"
                  d="M189.5,16c0.1,0,0.2,0,0.3-0.1s0.1-0.2-0.1-0.2c-0.1,0-0.3,0-0.3,0.1C189.4,15.9,189.4,16,189.5,16"/>
            <path className="st0"
                  d="M179.3,13.6c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1C179.1,13.5,179.2,13.6,179.3,13.6"/>
            <path className="st0"
                  d="M188.9,15.7c0.1,0,0.2,0,0.3,0s-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3,0C188.7,15.6,188.8,15.7,188.9,15.7"
            />
            <path className="st0"
                  d="M182.6,14.1c0.1,0,0.3,0,0.3-0.1s-0.1-0.2-0.2-0.2s-0.3,0-0.3,0.1C182.4,14,182.5,14.1,182.6,14.1"/>
            <path className="st0" d="M54.7,13.8c1.1,0.2,1.4,0.1,0.9-0.4s-1-0.7-1.7-0.4C53.4,13.3,53.6,13.5,54.7,13.8"/>
            <path className="st0" d="M53.4,15.6c-0.3,0.4-0.5,0.2-0.8-0.2c-0.1-0.2-0.6-0.5-0.8-0.3c-0.2,0.1,0.2,0.4,0.4,0.5
				c0.5,0.5-0.1,1.2,0.7,1.6s1.5-0.8,1.4-1.5C54.3,15.4,53.8,15,53.4,15.6"/>
            <path className="st0" d="M51.7,14.6c0.5,0.3,1,0.3,1.1-0.2c0.6-0.1,0.4-0.4,0.1-0.4c0.1-0.3,0.3-0.4,1,0c0.4,0.3,0-0.8-0.5-0.6
				s-1.1,0.5-1.6,0.8c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0-0.4,0.1-0.5,0.3C51,14.5,51.4,14.8,51.7,14.6"/>
            <path className="st0" d="M49.4,16.5c-0.7-0.3-0.6-0.5-0.4-0.7c0.1,0.4,0.8,0.5,0.8,0.1c0-0.2-0.1-0.3-0.4-0.4c0.3-0.3-0.7-0.1-1.1,0
				c-0.3,0.1-1-0.1-1,0.1c0,0.2,0.7,0.2,1.2,0.6C49.6,17,49.8,16.7,49.4,16.5"/>
            <path className="st0" d="M82.7,6.4c0.3,0.1,0.5-0.1,0.1-0.2c-0.9-0.3-0.6-0.6-0.1-0.9c0.2-0.1,0.2-0.3-0.2-0.2
				c-0.3,0-0.6,0.2-0.8,0.2c-0.3,0.1-1,0-1,0.2c0.1,0.2,0.7,0.1,1.2,0.5C82.3,6.2,82.5,6.3,82.7,6.4"/>
            <path className="st0" d="M82.3,8.2c0,0.2,0.2,0.3,0.3,0.3c0,0.1,0,0.1,0,0.2c-0.1,0.5,0.2,0.7,0.5,0.5C83.5,9,83.5,8.4,83,8l0,0
				c-0.1-0.1-0.2-0.3-0.4-0.3c-0.4-0.3-0.8-0.3-1.2,0.1c-0.2-0.6-0.7,0-0.3,0.3C80.8,8.4,81.7,8,82.3,8.2"/>
            <path className="st0" d="M128.6,7.4c-0.2-0.3-1-0.5-1.7-0.4C125.2,7.1,129.1,7.9,128.6,7.4"/>
            <path className="st0" d="M112.8,2.6c-0.4-0.4-2.2-0.2-1.9,0.2C111.2,3.1,113.3,3.2,112.8,2.6"/>
            <path className="st0" d="M67.1,11.5c1.8,0.9,0.4-1.7,2.2-0.5c0.4,0.3,0.1-0.7-0.4-0.6c-0.7,0.2-1.3,0.5-1.9,0.8
				C66.8,11.2,66.8,11.4,67.1,11.5"/>
            <path className="st0" d="M71.8,14.1c0.4,0.2,0.8,0,0.8-0.3c0.1,0.1,0.1,0.1,0.2,0.2c-0.5,0.1-0.2,0.5,0.3,0.3
				c0.1,0.1,0.1,0.1,0.2,0.1c0.2,0,0.4-0.2,0.5-0.6c0.1-0.4-0.3-0.7-0.8-0.8s-0.7,0.1-0.5,0.5C72,13.3,71.3,13.8,71.8,14.1"/>
            <path className="st0" d="M80,13.1c0.2,0.4,0.6-0.5,0-0.6c-0.8-0.1-1.6-0.1-2.3-0.2c-0.1,0-0.2,0.1-0.1,0.3C78.6,14,79.2,11.4,80,13.1
				"/>
            <path className="st0" d="M73.5,12.4c-1.2,0.6,0,0.9,0.3,0.5c0.2,0.1,0.3,0.1,0.1-0.2c0.1-0.2,0-0.4-0.1-0.3c0,0,0-0.1,0.1-0.1
				c0.4,0.4,0.7,0.6,1.4,0.1c0.8-0.3,0.7-1.1-0.2-0.5c-0.4-0.2-1.1-0.4-1.3-0.1C71.8,10.9,73.1,11.5,73.5,12.4"/>
            <path className="st0" d="M83.6,39.2c0.1,0.2,0.2,0.6,0.5,0.6s0.1-0.8,0-0.9c-0.4-0.3-0.5-0.6-0.4-0.8c0.5,0.2,0.7-0.3,0.3-0.5
				c0.1-0.2,0.2-0.3,0.1-0.4c0.9,0.4,0.1-0.8-0.4-0.8c-0.4,0-0.2,0.4,0.2,0.7c-0.3-0.1-1-0.5-1.2-0.3s1.2,0.4,0.8,0.8
				C82.9,38.1,83.2,38.7,83.6,39.2"/>
            <path className="st0" d="M91.3,34.6c-1.2-0.2-3.1-0.7-2.8,0.2c0.4,1.2,0.3-1,1.2,0.5c0.6,0.8,0.9,0.7,0.6,0.3
				c-0.2-0.2-0.7-0.7-0.5-0.8c0.2-0.2,1.1,0,1.5,0C91.4,34.8,91.6,34.6,91.3,34.6"/>
            <path className="st0" d="M87.8,29c0.5,0.4,0.9-0.1,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0.3-0.9-1.3-0.5-1.4-0.2s0,0.7,0.1,1
				c-0.8,0.1-1.5,0.9-0.6,1.1c0.5,0.1,1-0.1,1-0.5c0,0,0,0,0-0.1C87.5,29.7,87.7,29.2,87.8,29"/>
            <path className="st0" d="M111.7,31.3c-0.4,1.1,0.9-0.7,0.9,0.8c0,0.9,0.4,0.9,0.4,0.4c0-0.3-0.2-0.8,0-0.8c0.3-0.1,1.1,0.4,1.5,0.6
				C115.6,32.7,112.1,30.2,111.7,31.3"/>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default Bubble;