import {combineReducers, configureStore} from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';

import theme from './reducers/theme';
import pages from "./reducers/pages";
import page from "./reducers/page";
import updated from "./reducers/page-updates";
import menus from './reducers/menus';
import configurator from './reducers/configurator';
import filter from './reducers/filter';
import programmes from './reducers/programmes';
import ambassadors from './reducers/ambassadors';

// import { EXPIRE_SECONDS } from "../util/constants";

import { persistReducer } from "redux-persist";
import {
  FLUSH,
  PERSIST,
  PAUSE,
  REHYDRATE,
  PURGE,
  REGISTER
} from "redux-persist/es/constants";

// const expireReducer = require( 'redux-persist-expire' );

const reducers = combineReducers( {
  theme: theme,
  pages: pages,
  page: page,
  updated: updated,
  menus: menus,
  configurator: configurator,
  filter: filter,
  programmes: programmes,
  ambassadors: ambassadors,
} );

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['page'],
}

const persistedReducer = persistReducer( persistConfig, reducers );

const store = configureStore( {
  reducer: persistedReducer,
  middleware: ( getDefaultMiddleware => getDefaultMiddleware( {
    serializableCheck: {
      ignoreActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ]
    },
  } ) )
} );
export default store;
