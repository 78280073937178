import React from "react";
import classNames from "classnames/bind";

import styles from './Navigation.module.scss';
import { useSelector } from "react-redux";
const classes = classNames.bind( styles );

const Navigation = ( props ) => {
  const menus = useSelector( state => state.menus.menus );

  const nav = typeof menus.mainmenu !== 'undefined' ? (
    <nav className={styles.navigation}>
      <ul className={styles.navigationList}>
        { Object.entries( menus.mainmenu.items).map( ( [key, value] ) => {
          return <li key={key}>
            <a className={classes( {
              'navigationItem': true,
              'active': value.url === window.location.href
            } )} href={value.url} target={value.target}>{value.title}</a>
          </li>;
        } ) }
      </ul>
    </nav>
  ) : '';

  return (
    <div id="mainmenu" className={classes( {
      'navigationContainer': true,
      'isOpen': props.isOpen
    } )}>
      { nav }
    </div>
  );
};
export default Navigation;