import React from "react";

// Importing 3rd-party-libs
import {useSelector} from "react-redux";

// Importing Styling
import styles from './ComingSoon.module.scss';

// Importing SVGs
import { ReactComponent as WishDoSVG } from '../../../assets/svgs/wish_do.svg';
import { ReactComponent as FITLogo } from '../../../assets/svgs/fit_logo_blau.svg';
import { ReactComponent as InstagramLogo } from '../../../assets/svgs/instagram.svg';
import { ReactComponent as FacebookLogo } from '../../../assets/svgs/facebook.svg';

// Importing custom Components
import Row from "../../Layout/Row";
import Column from "../../Layout/Column";
import Container from "../../Layout/Container";
import SignupForm from "./SignupForm";

const ComingSoon = ( props ) => {
  const comingSoon = useSelector( state => state.theme.options.comingsoon);
  const socialOptions = useSelector( state => state.theme.options.social );
  const themeOptions = useSelector( state => state.theme.options );
  const contactOptions = useSelector( state => state.theme.options.contact );

  return (
    <div className={styles.comingSoonContainer}>
      <Container large className={styles.inner}>
        <header className={styles.header}>
          <div className={styles.imageLeft}>
            <FITLogo />
          </div>
          <div className={styles.imageRight}>
            <WishDoSVG />
          </div>
        </header>
        <main>
          <div className={styles.text} dangerouslySetInnerHTML={ { __html: comingSoon.text } } />
          <SignupForm />
        </main>

        <footer className={styles.footer}>
          <Row align="bottom">
            <Column className={styles.footerLeft} width={3} breakpoints={{m: 12}}>
              { typeof socialOptions.instagram !== 'undefined' && socialOptions.instagram.length > 0 ? (
                <a className={styles.socialIcons} href={socialOptions.instagram} target="_blank" rel="noreferrer">
                  <span className="hidden">Instagram</span>
                  <i aria-hidden="true"><InstagramLogo /></i>
                </a>
              ) : ''}

              { typeof socialOptions.facebook !== 'undefined' && socialOptions.facebook.length > 0 ? (
                <a className={styles.socialIcons} href={socialOptions.facebook} target="_blank" rel="noreferrer">
                  <span className="hidden">Facebook</span>
                  <i aria-hidden="true"><FacebookLogo aria-hidden="true" /></i>
                </a>
              ) : ''}
              <div className={styles.hashtag}>#{themeOptions.hashtag}</div>
            </Column>
            <Column className={styles.footerRight} width={9} breakpoints={{m: 12}}>
              <address dangerouslySetInnerHTML={{__html: contactOptions.contact}} />

              <address>
                <a href={`tel:${socialOptions.phone}`}>Tel.: {socialOptions.phone}</a>
                <a href={`mailto:${socialOptions.mail}`}>E-Mail: {socialOptions.mail}</a>
              </address>
            </Column>
          </Row>
        </footer>
      </Container>
    </div>
  );
};
export default ComingSoon;
