import React, {useState} from "react";

import styles from './Form.module.scss';
import classNames from "classnames/bind";
import Column from "../Layout/Column";
import Text from "../Blocks/Text/Text";


import {Form, Formik} from "formik";
import * as Yup from 'yup';
import TextInput from "./Fields/TextInput";
import Checkbox from "./Fields/Checkbox";
import Button from "./Fields/Button";

import {REST_BASE, REST_FORM} from "../../util/constants";

import { ReactComponent as ThumbsUp } from './../../assets/svgs/thumbsup.svg';
import TextArea from "./Fields/Textarea";
import { useLocation } from "react-router-dom";
import { fromLocalStorage, removeFromLocalStorage } from "../../util/helpers";
const classes = classNames.bind(styles);


const ContactForm = ( props ) => {

  const [loading, setLoading] = useState(false);
  const [isValid, setValid] = useState( {valid: false, message: ''} );

  const title = props.attrs.data['block_form_title'];
  const text = props.attrs.data['block_form_text'];


  return (
    <div className={styles.formHolder}>

      <Column width={10} breakpoints={({
        'l': 12
      })}>
        <div className={styles.formTitle}>{title}</div>
        <div className={styles.formText}>
          <Text text={text}/>
        </div>

        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            phone: "",
            mail: "",
            message: "",
            acceptedTerms: false
          }}
          validationSchema={Yup.object({
            mail: Yup.string().email("Ungültige E-Mail Adresse").required("Pflichtfeld"),
            acceptedTerms: Yup.boolean().required("Pflichtfeld").oneOf([true], "Du musst die Datenschutzbestimmungen noch akzeptieren")
          })}
          onSubmit={async (values, { setSubmitting, setFieldError, resetForm } ) => {

            setLoading(true);
            let formData = new FormData();

            formData.append( 'formID', 'CONTACTFORM' );

            const previousPage = fromLocalStorage( 'form-previous-program' );

            if( typeof previousPage !== 'undefined' && previousPage !== null ) {
              formData.append( 'location', previousPage );
            }

            for( let value in values ) {
              formData.append( value, values[value] );
            }

            const response = await fetch( REST_BASE + REST_FORM, {
              method: 'POST',
              body: formData
            } );

            if( !response.ok ) throw new Error( 'Failed to process form data.' );

            const data = await response.json();

            if( !data.success ) {
              const fields = data.fields;

              Object.entries(fields).forEach( ( [key, field] ) => {
                setFieldError(key, field.message);
              } );
              setValid({valid: false, message: ''})
            } else {
              setValid({valid: true, message: data.message});
              removeFromLocalStorage( 'form-previous-program' );
              resetForm();
            }

            setSubmitting(false);
            setLoading(false);
          }}>
          <Form className={classes({
            'form': true,
            'isLoading': loading
          })}>
            <TextInput
              id="contact-first-name"
              name="first_name"
              type="text"
              label="Vorname"
              placeholder="" />
            <TextInput
              id="contact-last-name"
              name="last_name"
              type="text"
              label="Nachname"
              placeholder="" />
            <TextInput
              id="contact-phone"
              name="phone"
              type="tel"
              label="Telefon"
              placeholder="" />
            <TextInput
              id="contact-mail"
              name="mail"
              type="mail"
              label="E-Mail*"
              placeholder="" />
            <TextArea
              id="contact-message"
              name="message"
              label="Nachricht"
              placeholder="" />
            <Checkbox name="acceptedTerms" style={{
              flexShrink: 0,
              flexGrow: 0,
              flexBasis: 'auto',
              width: '100%'
            }}>
              Ich erkläre mich mit der Verarbeitung der eingegebenen Daten sowie der Datenschutzerklärung einverstanden.*
            </Checkbox>
            <div className={styles.requiredInfo}>
              * Pflichtfelder
            </div>
            <div className={styles.buttonHolder}>
              <Button type="submit"> Absenden</Button>
            </div>
            {isValid.valid && <p className={styles.messageContainer}>{isValid.message}<ThumbsUp /></p>}
          </Form>
        </Formik>
      </Column>

    </div>
  );
};
export default ContactForm;
