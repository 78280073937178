import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  attributes: [],
  selection: [],
  search: ''
};

const filterSlice = createSlice( {
  name: 'filter',
  initialState: initialState,
  reducers: {
    fill( state, action ) {
      state.attributes = action.payload;
    },
    setSelection( state, action ) {
      state.selection = action.payload;
    },
    addSelection( state, action ) {
      if( action.payload.term === false ) {
        if( typeof state.selection[action.payload.taxonomy] !== 'undefined' ) {
          delete state.selection[action.payload.taxonomy];
        }
        return;
      }
      if( state.selection.length === 0 ) {
        let selection = {};
        selection[action.payload.taxonomy] = [action.payload.term];
        state.selection = selection;
      } else {
        if( typeof state.selection[action.payload.taxonomy] === 'undefined' ) {
          state.selection[action.payload.taxonomy] = [];
        }

        if( !state.selection[action.payload.taxonomy].includes( action.payload.term ) ) {
          if( action.payload.source === 'configurator' ) {
            state.selection[action.payload.taxonomy] = [ action.payload.term ];
          } else {
            state.selection[action.payload.taxonomy].push( action.payload.term );
          }
        }
      }
    },
    removeSelection( state, action ) {
      const found = state.selection[action.payload.taxonomy].indexOf( action.payload.term );
      if( found !== -1 ) {
        state.selection[action.payload.taxonomy].splice( found, 1 );
      }

      if( state.selection[action.payload.taxonomy].length === 0 ) {
        delete state.selection[action.payload.taxonomy];
      }
    },
    filterBySearch( state, action ) {
      state.search = action.payload;
    },
    reset( state, action ) {
      state.selection = [];
      state.search = '';
    }
  }

} );

export const filterActions = filterSlice.actions;
export default filterSlice.reducer;
