export const REST_BASE = '/wp-json/sunlime/v1/';

export const REST_INIT = 'init/';
export const REST_MENU = 'menus/';
export const REST_PAGES = 'pages/';
export const REST_POSTS = 'posts/';
export const REST_FORM = 'form/';
export const REST_CONFIGURATOR = 'configurator/';
export const REST_LAST_MODIFIED = 'last-modified/';

// REDUX EXPIRE
export const EXPIRE_SECONDS = 60 * 60 * 24
