import React, {useState} from "react";
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import TextInput from "./TextInput";
import Checkbox from "./Checkbox";
import Button from "./Button";

import styles from './SignupForm.module.scss';
import {useSelector} from "react-redux";
import {REST_BASE, REST_FORM} from "../../../util/constants";

import { ReactComponent as ThumbsUp } from './../../../assets/svgs/thumbsup.svg';

const SignupForm = () => {
  const [isValid, setValid] = useState( {valid: false, message: ''} );
  const comingSoonOptions = useSelector( state => state.theme.options.comingsoon );

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          acceptedTerms: false
        }}
        validationSchema={Yup.object({
          email: Yup.string().email("Ungültige E-Mail Adresse").required("Pflichtfeld"),
          acceptedTerms: Yup.boolean().required("Pflichtfeld").oneOf([true], "Du musst die Datenschutzbestimmungen noch akzeptieren")
        })}
        onSubmit={async (values, { setSubmitting, setFieldError, resetForm } ) => {

          let formData = new FormData();

          formData.append( 'formID', 'SIGNUPCOMINGSOON' );

          for( let value in values ) {
            formData.append( value, values[value] );
          }

          const response = await fetch( REST_BASE + REST_FORM, {
            method: 'POST',
            body: formData
          } );

          if( !response.ok ) throw new Error( 'Failed to process form data.' );

          const data = await response.json();

          if( !data.success ) {
            const fields = data.fields;

            Object.entries(fields).forEach( ( [key, field] ) => {
              setFieldError(key, field.message);
            } );
            setValid({valid: false, message: ''})
          } else {
            setValid({valid: true, message: data.message});
            resetForm();
          }

          setSubmitting(false);
        }}>
        <Form className={styles.signupForm}>
          <TextInput
            id="signup-form-mail-address"
            name="email"
            type="email"
            label="E-Mail Adresse"
            placeholder="E-Mail-Adresse hier eingeben" />
          <Checkbox name="acceptedTerms" style={{
            flexShrink: 0,
            flexGrow: 0,
            flexBasis: 'auto',
            width: '100%'
          }}>
            <div dangerouslySetInnerHTML={{__html: comingSoonOptions.privacy}} />
          </Checkbox>
          <Button type="submit">Benachrichtigung erhalten</Button>
          {isValid.valid && <p className={styles.messageContainer}>{isValid.message}<ThumbsUp /></p>}
        </Form>
      </Formik>
    </>
  );
};
export default SignupForm;
