import React from "react";
import {useField} from "formik";
import styles from './Checkbox.module.scss';

const Checkbox = ( { children, style, ...props } ) => {
  const [field, meta] = useField({...props, type: "checkbox"});
  return (
    <div className={styles.checkboxContainer} style={style}>
      <label className={styles.label}>
        <input className={styles.checkbox} {...field} {...props} type="checkbox" />
        <span className={styles.check}></span>
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null }
    </div>
  );
};
export default Checkbox;
