import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  entries: [],
};

const ambassadorsSlice = createSlice( {
  name: 'ambassadors',
  initialState: initialState,
  reducers: {
    fill( state, action ) {
      state.entries = action.payload;
    },
  }
} );

export const ambassadorsActions = ambassadorsSlice.actions;
export default ambassadorsSlice.reducer;
