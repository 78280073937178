import React, {useEffect, useState} from "react";
import styles from './CookieBanner.module.scss';

import {
  CookieConsentBanner,
  triggerCookieConsentBanner, useCookieConsent,
} from "@porscheofficial/cookie-consent-banner-react";
import classNames from "classnames/bind";
import {useSelector} from "react-redux";
import { changeCookieToSession } from "../../util/helpers";

const classes = classNames.bind(styles);

const CookieBanner = ( props ) => {

  const [isVisible, setIsVisible] = useState(false);
  const cookies = useSelector( state => state.theme.cookies );
  const options = useSelector( state => state.theme.options );
  const acceptedCategories = useCookieConsent();
  useEffect(() => {
    if(acceptedCategories.length === 0 && ( typeof cookies !== 'undefined' && cookies.length > 0 )) {
      setIsVisible(true);
    }
    else {
      setIsVisible(false);
    }
  }, [acceptedCategories,  cookies, setIsVisible])

  useEffect( () => {
    changeCookieToSession();
  }, [] );

  const availableCookies = cookies.map( (cookie) => {
    return {
      description: cookie.cookies_description,
      key: cookie.cookies_key,
      label: cookie.cookies_label,
      isMandatory: cookie.cookies_isMandatory,
    }
  });

  const showCookies = (e) => {
    e.preventDefault();
    triggerCookieConsentBanner({ showDetails: true });
  }

  const initConsent = ( consent ) => {
    changeCookieToSession();
    let acceptedCategories = consent?.acceptedCategories || [];
    if(acceptedCategories.length === 0 && ( typeof cookies !== 'undefined' && cookies.length > 0 )) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <CookieConsentBanner
      className={classes({
        'banner': true,
        'isVisible': isVisible
      })}
      btnLabelAcceptAndContinue="Akzeptieren"
      btnLabelSelectAllAndContinue="Alle akzeptieren"
      btnLabelOnlyEssentialAndContinue="Nur essenzielle Cookies akzeptieren"
      btnLabelPersistSelectionAndContinue="Auswahl speichern"
      contentSettingsDescription="Diese Webseite verwendet Cookies, wovon manche Cookies sind essenziellsind. Hier finden Sie eine Übersicht aller Cookies. Sie können Ihre Einwilligung zu einzelnen Kategorien geben oder alle Cookies akzeptieren."
      headline="Cookies"
      availableCategories={availableCookies}
      handlePreferencesUpdated={initConsent}
      handlePreferencesRestored={initConsent}
    >
      <p>
        Wir verwenden Cookies und ähnliche Technologien, um bestimmte Funktionalitäten auf unserer Webseite anzubieten.
        Abhängig vom Zweck der einzelnen Cookies können diese zu Analyse- oder Marketingzwecken verwendet werden.
        Mit einem Klick auf &quot;Akzeptieren&quot; erklären Sie sich einverstanden, alle Cookies zu akzeptieren.
      </p>

      <a href={'#'}
        onClick={(e) => { showCookies(e) }}
        onKeyPress={(e) => { showCookies(e) }}
      >
        Hier
      </a>&nbsp;
      können Sie individuelle Cookie-Einstellungen vornehmen. Für weitere Informationen, besuchen Sie bitte unsere <a href={options.privacy_page}>Datenschutzbestimmungen</a>.
    </CookieConsentBanner>
  );
};
export default CookieBanner;