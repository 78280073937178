import React from "react";

import styles from './Container.module.scss';

import classNames from "classnames/bind";
const classes = classNames.bind( styles );

const Container = ( props ) => {
  return <div
    id={props.anchor}
    className={classes(props.className, {
        'container': true,
        'xsmall': props.xsmall,
        'small':  props.small,
        'middle': props.middle,
        'large':  props.large,
        'full':   props.fullWidth
      },
      props.paddingTop,
      props.paddingBottom,
      props.paddingTopMobile,
      props.paddingBottomMobile,
    )} style={{background: props.bg_color}} >
    {props.children}
  </div>;
};
export default Container;
