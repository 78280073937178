import React, { useEffect } from "react";

import styles from './Search.module.scss';

import { ReactComponent as SearchIcon } from '../../../../assets/svgs/search.svg';

const Search = ( { id, defaultNext, setValid, setSelectionHandler, value } ) => {
  useEffect( () => {
    setValid( value !== false && value.trim().length > 0 )
  }, [value, setValid] );


  const onChangeHandler = ( e ) => {
    setValid( e.target.value.trim().length > 0 );

    if( e.target.value.trim().length > 0 ) {
      if( e.keyCode === 13 ) {
        setSelectionHandler( {
          id: id,
          type: 'search',
          selection: [ e.target.value ],
          next: e.target.dataset.nextStep,
        } );
      }
    }
  };

  return (
    <div className={styles.search}>
      <input onKeyUp={onChangeHandler} defaultValue={value !== false ? value : ''} className={styles.input} id={`step_${id}`} type="text" name={`step_${id}`} data-next-step={defaultNext ? defaultNext : false} />
      <label htmlFor={`step_${id}`} className={styles.label}>Suche</label>
      <SearchIcon className={styles.icon} />
    </div>
  );
};
export default Search;