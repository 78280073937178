import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pages: {},
  global_modified: ''
};

const updatedSlice = createSlice( {
  name: 'updated',
  initialState: initialState,
  reducers: {
    addPage(state, action) {
      state.pages[action.payload.slug] = {};
    },
    removePage(state, action) {
      delete state.pages[action.payload];
    },
    clearPages(state, action) {
      state.pages = {};
    },
    updateGlobal(state, action) {
      state.global_modified = action.payload;
    }
  }
} );

export const updatedActions = updatedSlice.actions;
export default updatedSlice.reducer;
