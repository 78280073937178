import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Fragment, useLayoutEffect, useState} from "react";
import ComingSoon from "./components/pages/ComingSoon/ComingSoon";
import { changeBaseFontSizeTo, fetchInit } from "./util/helpers";
import {useDispatch, useSelector} from "react-redux";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import parse from 'html-react-parser';
import Page from "./components/Page/Page";
import {pageActions} from "./stores/reducers/page";
import {updatedActions} from "./stores/reducers/page-updates";
import {themeActions} from "./stores/reducers/theme";
import { filterActions } from "./stores/reducers/filter";
import { programmesActions } from "./stores/reducers/programmes";
import { ambassadorsActions } from "./stores/reducers/ambassadors";
import { menusActions } from "./stores/reducers/menus";
import { configuratorActions } from "./stores/reducers/configurator";
import {Helmet} from "react-helmet";
import LoadingIndicator from "./components/UI/LoadingIndicator";
import GoogleTagManager from "./components/GoogleTagManager/GoogleTagManager";


function App() {
  const dispatch = useDispatch();
  const pages = useSelector( state => state.pages.pages );
  const updateNext = useSelector( state => state.updated.pages );
  const pathname = window.location.pathname;
  const hasPage = (pathname in pages) && !(window.location.toString()).includes('?page_id=');
  const [ head, setHead ] = useState( '' );
  const [ fetched, setFetched ] = useState( false );
  const [ isLoading, setLoading ] = useState( false );
  const theme = useSelector( state => state.theme.options );
  const hasProgram = pages && (window.location.toString()).includes('/program/');

  useLayoutEffect( () => {
    if( hasPage && !( pathname in updateNext ) ) {
      dispatch( pageActions.setCurrent( pages[pathname] ) );
      setHead( typeof pages[pathname].seo_head !== 'undefined'  ? pages[pathname].seo_head : '');
    }
    else {
      if( ( pathname in updateNext ) ) {
        dispatch( updatedActions.removePage( pathname ) );
      }

      if( !fetched ) {
        setLoading( true );
        setFetched( true );

        fetchInit().then( data => {
          if( typeof data.options !== 'undefined' ) {
            dispatch( themeActions.fillOptions( data.options ) );
          }

          if( typeof data.cookies !== 'undefined' && typeof data.cookies.cookies !== 'undefined' ) {
            dispatch( themeActions.fillCookies( data.cookies.cookies ) );
          }

          if( typeof data.cookies !== 'undefined' && typeof data.cookies.ids !== 'undefined' ) {
            dispatch( themeActions.fillCookieIDs( data.cookies.ids ) );
          }

          if( typeof data.page !== 'undefined' ) {
            dispatch( pageActions.setCurrent( data.page ) );

            if( data.page && data.page.post_status === 'publish' ) {
              dispatch( pageActions.addPage( {
                'slug': pathname,
                'page': data.page
              } ) );
            }
          }

          if( typeof data.menus !== 'undefined' ) {
            dispatch( menusActions.fill( data.menus ) );
          }

          if( typeof data.configurator !== 'undefined' ) {
            dispatch( configuratorActions.addFirstStep( data.configurator.first ) );
          }

          if( typeof data.filter !== 'undefined' ) {
            dispatch( filterActions.fill( data.filter ) );
          }

          if( !hasProgram && typeof data.programmes !== 'undefined' ) {
            dispatch( programmesActions.fill( data.programmes ) );
          }

          if( typeof data.attributes !== 'undefined' ) {
            dispatch( programmesActions.fillAttributes( data.attributes ) );
          }

          if( typeof data.ambassadors !== 'undefined' ) {
            dispatch( ambassadorsActions.fill( data.ambassadors ) );
          }

          if( typeof data.page.seo_head !== 'undefined' ) {
            setHead(  typeof data.page.seo_head !== "undefined" ? data.page.seo_head : '');
          }
        } ).catch( error => console.log( error ) ).finally(() => {
          setLoading( false );
        });
      }
    }
  }, [ dispatch, hasPage, pages, pathname, updateNext, fetched, hasProgram ] );

  if( typeof theme.comingsoon !== 'undefined' && theme.comingsoon.enabled && typeof theme.comingsoon.pages !== 'undefined' && !theme.comingsoon.pages.includes( pathname ) ) {
    changeBaseFontSizeTo( 1.5 );
  } else {
    changeBaseFontSizeTo( 1.1875 );
  }

  return (
    isLoading ? (
      <LoadingIndicator />
    ) : (
      typeof theme.comingsoon !== 'undefined' ? (
        <Fragment>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={
                theme.comingsoon.enabled && !theme.comingsoon.pages.includes( pathname ) ? (
                  <ComingSoon />
                ) : (
                  <Fragment>
                    <Helmet>{parse(head)}</Helmet>
                    <Header />
                    <Page />
                    <Footer />
                    <CookieBanner />
                    <GoogleTagManager />
                  </Fragment>
                )
              } />
            </Routes>
          </BrowserRouter>
        </Fragment>
      ) : null
    )
  );
}

export default App;
