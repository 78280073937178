import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: null,
};

const pageSlice = createSlice( {
  name: 'page',
  initialState: initialState,
  reducers: {
    setCurrent( state, action ) {
      state.currentPage = action.payload;
    },
  }
} );

export const pageActions = pageSlice.actions;
export default pageSlice.reducer;
