import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchLastModified} from "../../util/helpers";
import {updatedActions} from "../../stores/reducers/page-updates";
import {pagesActions} from "../../stores/reducers/pages";

const PageUpdater = ( props ) => {
    const dispatch = useDispatch();
    const pages = useSelector( state => state.pages.pages );
    const globalModified = useSelector( state => state.updated.global_modified );
    const updateNext = useSelector( state => state.updated.pages );

    useEffect( () => {
        if( Object.keys( pages ).length > 0 ) {
            let ids = Object.entries( pages ).map( ( [slug, page] ) => {
                return page.id;
            } );

            fetchLastModified( ids ).then( data => {
                let newModified;
                for( const [ id, value ] of Object.entries( data ) ) {
                    if( id === 'global_modified' ) {
                        newModified = value;
                    } else {
                        // eslint-disable-next-line
                        let page = Object.entries( pages ).filter( ( [slug, page] ) => page.id == id && !page.update_next );

                        if( page.length > 0 && !( page[0][0] in updateNext ) ) {
                            let current = new Date( page[0][1].last_updated );
                            let last = new Date( value.last_updated );
                            if( current < last ) {
                                dispatch( updatedActions.addPage( {
                                    'slug': page[0][0]
                                } ) );
                            }
                        }
                    }
                }

                if( newModified !== globalModified ) {
                    dispatch( updatedActions.clearPages() );
                    dispatch( pagesActions.clearPages() );
                }
                dispatch( updatedActions.updateGlobal( newModified ) );
            } ).catch( error => console.log( error ) );
        }
    } );
};
export default PageUpdater;