import React, { useEffect } from "react";

import styles from './Select.module.scss';

const Select = ( { id, options, setSelectionHandler, setValid, defaultNext, taxonomy, value } ) => {

  useEffect( () => {
    if( typeof value !== 'undefined' ) {
      setValid( true );
    }
  }, [value, setValid] );

  const onChangeHandler = ( e ) => {
    if( e.target.value === '*' ) {
      setValid( false );
      return;
    }

    setValid( true );

    let selection = {};
    selection[taxonomy] = parseInt( e.target.value );
    if( e.target.value.includes( 'plain_' ) ) {
      selection[taxonomy] = false;
    }

    setSelectionHandler( {
      id: id,
      type: 'select',
      selection: selection,
      next: e.target.options[e.target.selectedIndex].dataset.nextStep,
    } );
  };

  return (
    <div className={styles.selects}>
      <select className={styles.select} id={`step_${id}`} value={value ? value : '*'} name={`step_${id}`} onChange={onChangeHandler} onFocus={(e) => e.target.selectedIndex = -1}>
        <option value="*">Bitte wähle aus</option>
        { options.map( ( option, index ) => {
          return <option key={`${id}_${index}`}  value={option.id} data-next-step={option.nextStep ? option.nextStep : defaultNext} dangerouslySetInnerHTML={{__html: option.title}}></option>
        } ) }
      </select>
    </div>
  );
};
export default Select;